<template>
  <div v-if="isCIdialogVisible">
    <el-dialog
      :title="`PI${$t('order.to')}CI`"
      width="358px"
      :visible.sync="isCIdialogVisible"
      :before-close="onClose"
      v-move-outside
    >
      <div class="tipPi">
        <span>{{ $t('order.Current PI') }}</span>：{{ PI }}
      </div>
      <div class="ciListBox">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="top">
            <div class="left">
              <div class="index">{{ index+1 }}</div>
              <div class="title">{{ $t('order.AfterSplittingOrder') }}</div>
            </div>
            <div class="btn" @click="list.splice(index, 1)">
              <i class="iconfont icon-shanchu"></i>
            </div>
          </div>
          <div class="bottom">
            <el-input
              v-model="item.CI"
              size="small"
              :placeholder="`${$t('order.Please CI')}-${index + 1}`"
            />
            <el-upload
              action="#"
              drag
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :http-request="($event) => confirmUpload($event, index)"
              :class="!item.file ? 'ec-upload' : 'ec-uploader'"
            >
              <div class="el-upload__text" v-if="!item.file">
                {{ $t('order.uploadSkuFile') }}
              </div>
              <template v-else>
                <div class="ec-upload__left">
                  <i class="iconfont_file" :class="isFileTypeIcon(item.file.name.split('.')[1])"></i>
                  <div class="name">
                    <p>{{ item.file.name }}</p>
                  </div>
                </div>
                <i class="iconfont icon-shanchu" @click.stop="deleteFile(index)"></i>
              </template>
            </el-upload>
          </div>
        </div>
      </div>
      <el-button 
        class="addCI"
        size="small" 
        @click="
          list.push({
            CI: '',
            fileId: '',
            fileName: '',
            file: null,
            orderProgress: 'DELIVERY_LOCAL',
          })
        ">
        {{ $t('home.CIAdd') }}
      </el-button>
      <div slot="footer">
        <el-button 
          size="small" 
          @click="download"
          style="float: left;"
        >{{ $t('home.Download template') }}</el-button>
        <el-button size="small" class="cancel_button" @click="onClose">{{
          $t('home.Cancel')
        }}</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="loginLoading">
          {{ $t('home.Confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <moneyAffirm
       v-if="isMoneyAffirm"
      :isMoneyAffirm="isMoneyAffirm"
      :moneyAffirmList="moneyAffirmList"
      :moneyAffirmSource="moneyAffirmSource"
      :moneyAffirmFileData="moneyAffirmFileData"
      :orderId="orderId"
      @onClose="moneyAffirmClose"
    />
  </div>
</template>

<script>
import { isFileTypeIcon } from "@/utils/utils.js";
import moneyAffirm from './moneyAffirm.vue';
import { uploadFile } from '../../api/files';
import {
  businessFileDownload,
  getMessage,
} from '../../api/order';
export default {
  name: 'createCIorder',
  components: {
    moneyAffirm,
  },
  inject: ['reload'],
  data() {
    return {
      isFileTypeIcon: isFileTypeIcon,
      loginLoading: false,
      list: [
        {
          CI: '',
          fileList: '',
          fileName: '',
          file: null,
          orderProgress: 'DELIVERY_LOCAL',
        }
      ],
      moneyAffirmFileData: null,
      moneyAffirmSource: null,
      moneyAffirmList: [],
      isMoneyAffirm: false,
    };
  },
  props: ['isCIdialogVisible', 'PI', 'orderId'],
  emits: ['onClose'],
  watch: {},
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const type = file.name.indexOf('.csv') != -1;
      if (!isLt2M) {
        this.$message.error(this.$tc('order.File is too large to upload', 2));
      } else if (!type) {
        this.$message.error('上传文件格式不正确!');
      }
      return isLt2M && type;
    },
    confirmUpload({file}, index) {
      const { name } = file;
      if (!this.beforeAvatarUpload(file)) return;
      //只传一个文件的情况
      var data_up = new FormData();
      data_up.append('files', file);
      data_up.append('configCode', 'file_SKU');
      uploadFile(data_up)
        .then((data) => {
          this.list[index].fileId = data[0];
          this.list[index].fileName = name;
          this.list[index].file = file;
          this.$message.success(this.$t('home.Createdsucceeded'));
        })
        .catch((error) => {});
    },
    deleteFile(index) {
      this.list[index].fileId = "";
      this.list[index].fileName = '';
      this.list[index].file = null;
    },
    onClose() {
      this.$emit('onClose');
    },
    submit() {
      this.moneyAffirmList.splice(0, this.moneyAffirmList.length);
      let arr = [];
      let isTrue = true;
      this.list.forEach((v) => {
        if (v?.CI && v?.fileId) {
          arr.push(v);
        } else {
          if (!arr.length) {
            isTrue = false;
          }
        }
      });
      if (isTrue) {
        this.loginLoading = true;
        let formData = new FormData();
        formData.append("orderId", this.orderId);
        arr.forEach((v, i) => {
          const { CI, file, fileId } = v;
          formData.append(`ciList[${i}].ci`, CI);
          formData.append(`ciList[${i}].file`, file);
          formData.append(`ciList[${i}].fileId`, fileId);
        });
        getMessage(formData).then(data => {
          this.moneyAffirmList = data.ciList;
          this.moneyAffirmSource = data.source;
          this.moneyAffirmFileData = formData;
          this.isMoneyAffirm = true;
        }).finally(() => this.loginLoading = false)
      } else {
        this.$message.error(
          this.$t('order.Please enter the CI number and upload the file')
        );
      }
    },
    download() {
      const link = document.createElement('a');
      link.href = "https://echola-business.oss-cn-hangzhou.aliyuncs.com/file/template/sku%E6%A8%A1%E6%9D%BF.csv";
      link.setAttribute('download', 'CI订单导入模板.xls');
      document.body.appendChild(link);
      link.click();
    },
    moneyAffirmClose(is = false) {
      if (is) {
        this.$emit('onClose');
        console.log(this.PI)
        this.$router.push({
          path: '/order',
          query: { PI: this.PI },
        });
      } else {
        this.isMoneyAffirm = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0;
  text-align: center;
}
.tipPi {
  background: #E3E9F1;
  font-size: 14px;
  line-height: 20px;
  color: #122545;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 16px;
  text-align: left;
  span {
    color: #637381;
  }
}

.ciListBox {
  margin-top: 40px;
  .item {
    padding: 12px 16px;
    cursor: pointer;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .left {
        display: flex;
        align-items: center;
      }
      .index {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #2A3B58;
        color: #ffffff;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
      }
      .title {
        font-size: 14px;
        line-height: 20px;
        color: #637381;
      }
      .btn {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #757D8A;
        background: #ffffff;
        opacity: 0;
        transition: all .3s;
        i {
          font-size: 12px;
        }
      }
    }
    .bottom {
      display: flex;
      gap: 8px;
      .el-input {
        flex: 1;
      }
      .ec-upload {
        width: 96px;
        /deep/ .el-upload-dragger {
          height: 32px;
          .el-upload__text {
            font-size: 12px;
          }
        }
      }
      .ec-uploader {
        width: 96px;
        /deep/ .el-upload-dragger {
          height: 32px;
          .el-upload__text {
            font-size: 12px;
          }
        }
      }
    }
    &:hover {
      background: #F7F9FC;
      .top {
        .btn {
          opacity: 1;
        }
      }
      .ec-uploader {
        width: 96px;
        /deep/ .el-upload-dragger {
          background: #ffffff;
        }
      }
    }
  }
}
.addCI {
  width: 325px;
  margin: 12px auto;
  border: 1px solid #97C2B4;
  color: #076F49;
}
</style>
