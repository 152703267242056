<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <el-upload
      v-loading="uploadLoding"
      :disabled="readonly || disabled"
      :auto-upload="false"
      action="#"
      drag
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-change="handleChange"
      :class="!inputValue ? 'ec-upload' : 'ec-uploader'"
      :accept="fileType.filter(item => item.configCode == 'file_Order_Custom_Image')[0].supportFileTypes"
    >
      <template v-if="!inputValue">
        <i class="iconfont icon-shangchuan"></i>
        <div class="el-upload__text">
          {{ $t("Add Picture") }}
        </div>
      </template>
      <template v-else>
        <img v-if="inputValue" :src="inputValue" @click="readonly ? $replayImgShow($event) : null" class="avatar">
      </template>
    </el-upload>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { uploadFileUrl } from "@/api/files.js"

export default {
  name: "fileUpload",
  props: {
    value: {
      type: String | Number,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: ""
      })
    }
  },
  data() {
    return {
      inputValue: "",
      uploadLoding: ""
    }
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    }
  },
  computed: {
    ...mapGetters(["fileType"]),
    fileName() {
      let text = this.inputValue?.split("/")?.pop();
      let index_ = text.lastIndexOf("_");
      let indexdian = text.lastIndexOf(".");
      return text.substring(0, index_) + text.substring(indexdian, text.length);
    }
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    beforeUpload(file) {
      let fileTypeRow = this.fileType.filter(item => item.configCode == "file_Order_Custom_Image")[0];
      let type = '.'+file.name.split(".").pop();
      if(!fileTypeRow.supportFileTypes.split(",").includes(type.toLowerCase())) {
        this.$message.error(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < Number(fileTypeRow.maxUploadSize);
      if (!isLt10M) {
        this.$message.error(this.$tc("order.File is too large to upload", Number(fileTypeRow.maxUploadSize)));
      }
      return isLt10M;
    },
    handleRemove() {
      this.inputValue = "";
    },
    handleChange(file) {
      //文件数量改变
      if (!this.beforeUpload(file)) return;
      this.confirmUpload(file);
    },
    confirmUpload(file) {
      this.uploadLoding = true;
      var data_up = new FormData();
      data_up.append("files", file.raw);
      data_up.append("configCode", "file_Order_Custom_Image");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        this.inputValue = response[0].savePath;
      }).finally((error) => {
        this.uploadLoding = false;
      });
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .ec-uploader {
  .el-upload-dragger {
    // height: 32px;
    padding: 0;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    .avatar {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }
}
</style>