<template>
  <el-drawer
    :title="$t('todo.to-do')"
    :before-close="handleDrawerClose"
    :visible.sync="drawer"
    :orderInfo="orderInfo"
    direction="rtl"
    size="850px"
    ref="drawer"
    @open="open()"
    v-move-outside
  >
    <div class="demo-drawer__content" v-loading="loading_todo">
      <div class="containerLeft">
        <div class="scheduleTitle">
          <el-button
            icon="el-icon-plus"
            size="small"
            @click="addMessage"
            >{{ $t('todo.Subject') }}</el-button
          >
        </div>
        <ul class="toDoList" v-if="dataList.length">
          <li 
            v-for="(item, index) in dataList" 
            :key="index" 
            class="todoItem"
            @click="setTodoItemVo(item, index)"
            :class="todoActive == index ? 'active' : ''">
            <div class="todoTopic">
              <member
                :info="item.createPerson"
                :size="48"
                :placement="'left'"
              />
              <div class="todoContent">
                <div class="name">{{ item.title }}</div>
                <div class="time">{{ item.createTime || '2022-05-11 16:44:02' }}</div>
              </div>
            </div>
            <div class="todoItemTerm" v-if="item.todoItemList">
              <div class="item" v-for="(row, idx) in item.todoItemList.filter((frow, fidx) => fidx < 2)" :key="idx">
                <div class="title">{{ row.content }}</div>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="item more" v-if="item.todoItemList.length > 2">
                +{{ (item.todoItemList.length - 2) > 99 ? '99' : item.todoItemList.length - 2 }}
              </div>
            </div>
          </li>
        </ul>
        <el-empty
          v-else
          :description="$t('order.No message')"
          :image="$emptyImage"
          :image-size="90"
        ></el-empty>
      </div>
      <div class="containerRight">
        <div class="parentBox" v-if="todoItemForm">
          <div class="name">
            <span>{{ todoItemForm.title }}</span>
            <i 
              class="iconfont icon-Vector" 
              v-if="businessUserId === todoItemForm.createPerson.openId" 
              @click="editThisList(todoItemForm, todoActive)"
            ></i>
          </div>
          <i 
            class="iconfont icon-shanchu" 
            v-if="businessUserId === todoItemForm.createPerson.openId" 
            @click="deleteThisList(todoItemForm, todoActive)"
          ></i>
        </div>
        <div class="todoItemListBox" v-if="todoItemForm && todoItemForm.todoItemList.length">
          <div 
            class="item" 
            v-for="(item, index) in todoItemForm.todoItemList" 
            :key="index"
            @click="openInnerDrawer(item)">
            <div class="index">{{ index+1 }}</div>
            <div class="content">
              <div class="topContent">
                <div class="title">{{ item.content }}</div>
                <div class="createTime" v-if="item.createPerson">
                  {{ $tc('order.createTimeString', item.createPerson.openId === businessUserId ? '你' : item.createPerson.nickName) }}
                  {{ item.createTime }}
                </div>
              </div>
              <div class="assignUsers">
                <div class="users">
                  <member
                    v-for="(row, idx) in item.assignUsers.filter((r, ix) => ix < 4)"
                    :key="idx"
                    :info="row"
                    :isDone="onIsDone(row, index, idx)"
                    :size="24"
                    :placement="'bottom'"
                  />
                  <i class="iconfont icon-Ellipsis" v-if="item.assignUsers.length > 3"></i>
                </div>
                <div class="pending" :class="isTodoComputed(item).code === 3 ? 'active' : ''">
                  {{ isTodoComputed(item).name }}
                </div>
              </div>
            </div>
            <el-popover
              ref="todoPopover"
              placement="right-start"
              width="82"
              trigger="click"
              popper-class="orderPopover"
              @hide="item.popoverShow = false"
              @show="showTodoPopover(index)"
            >
              <ul class="user_popover">
                <li 
                  v-if="item.assignUsers.filter(row => row.todoStatus != '2').map(row => row.openId).includes(businessUserId)"
                  @click="finishTodo(item)">
                  <i class="iconfont icon-chenggong"></i>
                  <span>{{ $t('InformationCenter.Finished') }}</span>
                </li>
                <li
                  v-if="(item.createPerson && item.createPerson.openId) == businessUserId"
                  @click="deleteTodo(item, index)"
                >
                  <i class="iconfont icon-shanchu"></i>
                  <span>{{ $t("order.Delete") }}</span>
                </li>
              </ul>
              <div 
                class="moreBox" 
                :class="item.popoverShow ? 'active' : ''"
                slot="reference"
                v-if="item.assignUsers.filter(row => row.todoStatus != '2').map(row => row.openId).includes(businessUserId) || ((item.createPerson &&item.createPerson.openId) == businessUserId)"
                @click.stop>
                <i class="iconfont icon-Ellipsis"></i>
              </div>
            </el-popover>
          </div>
        </div>
        <el-empty
          v-else
          :description="$t('order.No message')"
          :image="$emptyImage"
          :image-size="90"
        ></el-empty>
        <div class="btns">
          <el-button
            size="small"
            type="primary"
            @click="addTodo()"
          >{{ $t('todo.addto-do') }}</el-button>
        </div>
      </div>
    </div>
    <todoInnerDrawer
      :innerDrawer.sync="innerDrawer"
      :todoItemId="todoItemId"
      @handleClose="handleClose"
      @afterUpdateTodo="getList"
    />
    <addTodoDrawer 
      :show.sync="addTodoDrawer" 
      :orderInfo="orderInfo"
      :businessToDoListId="todoItemForm && todoItemForm.todoListId"
      @updateData="getList"
    />
  </el-drawer>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import todoInnerDrawer from '@/components/todoDrawer/todoInnerDrawer';
import addTodoDrawer from "./addTodoDrawer.vue";

import {
  createTodoList,
  getTodoList,
  deleteTodoList,
  updateTodoList,
  deleteTodoItem
} from '@/api/todo';
import { receiveItem } from '@/api/news';

export default {
  name: 'todoDrawer',
  components: { 
    todoInnerDrawer,
    addTodoDrawer
  },
  data() {
    return {
      innerDrawer: false,
      addTodoDrawer: false,
      todoItemId: null,
      todoform: {},
      dataList: [],
      loading_todo: false,
      todoItemForm: null,
      todoActive: 0,
      todoActiveId: null
    };
  },
  props: ['orderInfo', 'drawer', 'businessUserId'],
  inject: ['reload'],
  computed: {
    ...mapGetters(['toDoList']),
    total_people_num() {
      return this.userList.length;
    },
    isTodoComputed() {
      return (item) => {
        let userComplete = item.assignUsers.filter(row => row.todoStatus == '2');
        if(!userComplete.length) {
          return {
            name: '未开始',
            code: 1
          }
        }
        if(userComplete.length === item.assignUsers.length) {
          return {
            name: '已结束',
            code: 2
          }
        }
        return {
          name: this.$t('order.pending')+`${userComplete.length}/${item.assignUsers.length}`,
          code: 3
        }
      }
    }
  },
  watch: {
    toDoList(val) {
      if(val.type=='delete'){
        this.getList();
      }else{
        this.getList(0);
      }
    },
  },
  methods: {
    showTodoPopover(index) {
      this.$refs.todoPopover.forEach((item, idx) => {
        if(index !== idx) {
          this.todoItemForm.todoItemList[idx].popoverShow = false;
          this.$refs.todoPopover[idx].doClose();
        } else {
          this.todoItemForm.todoItemList[idx].popoverShow = true;
        }
      })
    },
    setTodoItemVo(row, index) {
      this.todoActive = index;
      this.todoItemForm = row;
    },
    open() {
      this.loading_todo = true;
      this.getList();
    },
    addTodo() {
      this.addTodoDrawer = true;
    },
    getList(val) {
      //每次get时候重新初始化
      this.todoform = {};
      val != 0 ? (this.loading_todo = true) : '';
      //获取todo list
      getTodoList(this.orderInfo.orderId)
        .then((response) => {
          this.loading_todo = false;
          let listArray = response.map(item => ({
            ...item,
            todoItemList: item.todoItemList ? item.todoItemList.map(row => ({
              ...row,
              popoverShow: false
            })) : []
          }))
          this.dataList = listArray;
          this.setTodoItemVo(listArray[0], 0);
        })
        .catch((error) => {
          this.loading_todo = false;
        });
    },
    handleDrawerClose() {
      this.$emit('handleClose');
    },
    handleClose() {
      this.innerDrawer = false;
    },
    deleteTodo(row, index) {
      this.$confirm(
        this.$t('xiaoxie.Delete this todo'),
        this.$t('xiaoxie.Notification'),
        {
          confirmButtonText: this.$t('xiaoxie.confirm'),
          cancelButtonText: this.$t('xiaoxie.cancel'),
          type: 'warning',
        }
      ).then(() => {
        deleteTodoItem(row.todoItemId).then(() => {
          this.handleClose();
          this.getList();
          this.$message.success(this.$t("todo.Delete Successful"));
        });
      });
    },
    finishTodo(item) {
      this.buttonLoading = true;
      var ask_msg = '';
      if (item.isLight) {
        ask_msg = this.$t('home.Back to UNFINISHED?');
      } else if (!item.isLight) {
        ask_msg = this.$t('home.Finshed this todo?');
      }
      this.$confirm(ask_msg, this.$t('home.Todo status update'), {
        confirmButtonText: this.$t('home.Confirm'),
        cancelButtonText: this.$t('home.Cancel'),
        cancelButtonClass: 'cancel-btn',
      })
        .then(() => {
          receiveItem({
            todoItemId: item.todoItemId, 
            todoStatus: 2
          }).then(() => {
            this.handleClose();
            this.getList();
            this.$message.success(this.$t('order.State torsion succeeded'));
          }).finally(() => (this.buttonLoading = false));
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
    deleteThisList(items) {
      this.$confirm(
        '',
        this.$t('order.Confirm delete this subject'),
        // this.$t('order.Delete action'),
        {
          confirmButtonText: this.$t('Forwarder.Confirm'),
          cancelButtonText: this.$t('Forwarder.Cancel'),
        }
      )
        .then(() => {
          //删除todo list
          deleteTodoList(items.todoListId)
            .then(() => {
              this.getList();
              this.$message({
                type: 'info',
                message: this.$t('todo.Delete Successful'),
              });
            })
            .catch((error) => {});
        }).catch(() => {});
    },
    editThisList(items) {
      this.$prompt('', this.$t('order.renameSubject'), {
        confirmButtonText: this.$t('Forwarder.Confirm'),
        cancelButtonText: this.$t('Forwarder.Cancel'),
        inputPattern: /\S/,
        inputErrorMessage: this.$t('order.Please input'),
        inputPlaceholder: items.name ? items.name : this.$t('order.Please input'),
      })
        .then(({ value }) => {
          var data_todolist = {
            todoListId: items.todoListId,
            title: value,
            description: this.$t('order.New subject name is') + value,
          };
          //更新todo list
          updateTodoList(data_todolist)
            .then(() => {
              this.$message({
                type: 'success',
                message: this.$t('order.New subject name is') + value
              });
              this.getList();
            })
            .catch((error) => {});
        })
        .catch(() => {
          this.$message.info(this.$t('order.Cancel input'));
        });
    },
    addMessage() {
      this.$prompt('', this.$t('order.New Subject'), {
        confirmButtonText: this.$t('Forwarder.Confirm'),
        cancelButtonText: this.$t('Forwarder.Cancel'),
        inputPattern: /\S/,
        inputErrorMessage: this.$t('order.Please input'),
        inputPlaceholder: this.$t('todo.Please input the subject name'),
        customClass: 'new-subject',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = this.$t('order.orderloding');
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 200);
            }, 200);
          } else {
            done();
          }
        },
      })
        .then(({ value }) => {
          var data_todolist = {
            description: 'this subject name is ' + value,
            orderId: this.orderInfo.orderId,
            title: value,
          };
          //创建todo list
          createTodoList(data_todolist)
            .then(() => {
              this.$message({
                duration: 1500,
                dangerouslyUseHTMLString: true, //重点代码
                message: this.$t('order.Your subject name is') + value,
                type: 'success',
              });
              this.getList();
            })
            .catch((error) => {});
        }).catch(() => {});
    },
    openInnerDrawer(item) {
      this.todoItemId = item.todoItemId;
      this.innerDrawer = true;
    },
    onIsDone(data, index, idx) {
      const { assignUsers } = this.todoItemForm.todoItemList[index];
      let isTrue = false;
      if (this.todoItemForm.todoItemList[index].assignUsers.length) {
        const { openId } = data;
        assignUsers.forEach((res) => {
          if (res.openId == openId && res.todoStatus == '2') {
            isTrue = true;
          }
        });
      }
      return isTrue;
    },
  },
};
</script>
<style lang="less" scoped>
.toDoList {
  .todoItem {
    padding: 16px;
    border-bottom: 1px solid #E0E4EA;
    cursor: pointer;
    transition: all .3s;
    &.active {
      background: #F7F9FC;
      .todoItemTerm {
        .item {
          background: #ffffff;
        }
      }
    }
    &:hover {
      .active;
    }
    .todoTopic {
      display: flex;
      align-items: center;
      .todoContent {
        flex: 1;
        margin-left: 10px;
        overflow: hidden;
        .name {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #122545;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time {
          font-size: 12px;
          line-height: 17px;
          color: #A3B0C6;
          margin-top: 4px;
        }
      }
    }
    .todoItemTerm {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
      .item {
        padding: 1px 12px;
        background: #F7F9FC;
        color: #076F49;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 50px;
        width: 108px;
        transition: all .3s;
        .title {
          width: 70px;
          font-size: 12px;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        i {
          font-size: 12px;
        }
        &.more {
          width: 46px;
          font-size: 12px;
          line-height: 22px;
          justify-content: center;
        }
      }
    }
  }
}
.todoItemListBox {
  height: calc(100% - 40px);
  overflow: overlay;
  .item {
    border: 1px solid #E0E4EA;
    padding: 20px 20px 16px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    margin-top: 12px;
    cursor: pointer;
    transition: all .3s;
    position: relative;
    .index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #122545;
      color: #ffffff;
      margin-right: 8px;
      text-align: center;
      line-height: 24px;
    }
    .content {
      flex: 1;
      overflow: hidden;
      .topContent {
        .title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #122545;
          margin-bottom: 4px;
        }
        .createTime {
          color: #757D8A;
          font-size: 12px;
          line-height: 17px;
        }
      }
      .assignUsers {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .users {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon-Ellipsis {
            margin-left: 4px;
            font-size: 12px;
            color: #757D8A;
            margin-top: 5px;
          }
        }
        .pending {
          padding: 3px 12px;
          border-radius: 50px;
          background: #F7F9FC;
          font-size: 12px;
          line-height: 17px;
          color: #757D8A;
          font-weight: 600;
          transition: all .3s;
          &.active {
            color: #076F49;
          }
        }
      }
    }
    &:hover {
      background: #F7F9FC;
      .pending {
        background: #ffffff !important;
        // color: #076F49 !important;
      }
    }
    .moreBox {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}
.user_popover {
  li {
    padding: 1px 7px;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    color: #122545;
    display: flex;
    align-items: center;

    i {
      margin-right: 6px;
    }

    &:hover {
      background: #122545;
      color: #ffffff;
    }
  }

  .line {
    height: 1px;
    width: 88%;
    margin: 5px auto;
    background-color: #ccc;
  }
}
</style>
