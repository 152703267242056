<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <el-input 
      v-if="!readonly" 
      type="textarea" 
      v-model="inputValue" 
      size="small" 
      :disabled="disabled" 
      :placeholder="form.customTips || $t('order.Please input content')" 
      maxlength="300"
      show-word-limit
    />
    <div class="customValueText" v-else>
      {{ inputValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: "inputText",
  props: {
    value: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: ""
      })
    }
  },
  data() {
    return {
      inputValue: ""
    }
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    }
  },
  mounted() {
    this.inputValue = this.value;
  }
}
</script>

<style>

</style>