<template>
  <el-dialog
    :title="$t('Add component')"
    :visible.sync="show"
    width="668px"
    append-to-body
    :before-close="close"
    v-move-outside
  >
    <div class="Setps">
      <div class="item active">
        <div class="index">1</div>
        {{ $t('Select Components') }}
      </div>
      <div class="line"></div>
      <div class="item" :class="selectValue ? 'active' : ''">
        <div class="index">2</div>
        {{ $t('Set Content') }}
      </div>
    </div>
    <div class="selectListBox" v-if="!selectValue">
      <el-row :gutter="12">
        <el-col 
          v-for="(item, index) in componentsArray" 
          :key="index"
          :span="8">
          <div class="item">
            <div class="drawerForm">
              <component
                :key="index"
                :is="item.name"
                disabled
                :form="{
                  customName: item.title
                }"
              />
            </div>
            <div class="bottom">
              <div class="textTip line1">{{ item.tips }}</div>
              <div 
                class="btn" 
                @click="selectCurrent(item)"
              >
                {{ $t('Use') }}
                {{ item.name == 'fileUploadComp' || item.name == 'imageUploadComp' ? `(${types.filter(row => row == item.type).length}/${item.maxSize})` : '' }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <template v-for="item in configArray">
        <component
          v-if="item == selectValue"
          ref="customFormDom"
          :key="item"
          :is="item"
          @emitForm="emitForm"
        />
      </template>
    </div>
    <span v-if="selectValue" slot="footer" class="dialog-footer">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="close">
        {{ $t('home.Cancel') }}
      </el-button>
      <el-button 
        size="small" 
        @click="prev">
        {{ $t('order.to previous step') }}
      </el-button>
      <el-button 
        type="primary" 
        size="small" 
        @click="confirm">
        {{ $t('home.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import inputText from '../customFormConfig/config/inputText.vue';
import radioSelect from '../customFormConfig/config/radioSelect.vue';
import checkSelect from '../customFormConfig/config/checkSelect.vue';
import timeSelect from '../customFormConfig/config/timeSelect.vue';
import dateSelect from '../customFormConfig/config/dateSelect.vue';
import inputNumber from '../customFormConfig/config/inputNumber.vue';
import fileUpload from "../customFormConfig/config/fileUpload.vue";
import imageUpload from "../customFormConfig/config/imageUpload.vue";
import textareaText from "../customFormConfig/config/textareaText.vue";
import switchSelect from "../customFormConfig/config/switchSelect.vue";
import rateSelect from "../customFormConfig/config/rateSelect.vue";
import inputTextComp from '../customFormConfig/component/inputText.vue';
import radioSelectComp from '../customFormConfig/component/radioSelect.vue';
import checkSelectComp from '../customFormConfig/component/checkSelect.vue';
import timeSelectComp from '../customFormConfig/component/timeSelect.vue';
import dateSelectComp from '../customFormConfig/component/dateSelect.vue';
import inputNumberComp from '../customFormConfig/component/inputNumber.vue';
import fileUploadComp  from '../customFormConfig/component/fileUpload.vue';
import imageUploadComp from "../customFormConfig/component/imageUpload.vue";
import textareaTextComp from "../customFormConfig/component/textareaText.vue";
import switchSelectComp from "../customFormConfig/component/switchSelect.vue";
import rateSelectComp from "../customFormConfig/component/rateSelect.vue";
export default {
  name: 'addFormComponents',
  components: {
    // 表单配置
    inputText,
    radioSelect,
    checkSelect,
    timeSelect,
    dateSelect,
    inputNumber,
    fileUpload,
    imageUpload,
    textareaText,
    switchSelect,
    rateSelect,
    // 组件展示
    inputTextComp,
    radioSelectComp,
    checkSelectComp,
    timeSelectComp,
    dateSelectComp,
    inputNumberComp,
    fileUploadComp,
    imageUploadComp,
    textareaTextComp,
    switchSelectComp,
    rateSelectComp
  },
  data() {
    return {
      show: false,
      customForm: null,
      selectValue: "",
      types: [],
      customNames: [],
      configArray: [
        'inputText',
        'radioSelect',
        'checkSelect',
        'timeSelect',
        'dateSelect',
        'inputNumber',
        'fileUpload',
        'imageUpload',
        'textareaText',
        'switchSelect',
        'rateSelect'
      ],
      componentsArray: [{
        title: this.$t('customName.Input'),
        name: "inputTextComp",
        type: '0',
        tips: this.$t('customeTips.Text input box, suitable for configuring themes, etc')
      }, {
        title: this.$t('customName.RadioSelect'),
        name: "radioSelectComp",
        type: '1',
        tips: this.$t('customeTips.Dropdown radio box for configuration options')
      }, {
        title: this.$t('customName.CheckSelect'),
        name: "checkSelectComp",
        type: '2',
        tips: this.$t('customeTips.Dropdown multiple selection box, suitable for configuring multiple selection options')
      }, {
        title: this.$t('customName.TimeSelect'),
        name: "timeSelectComp",
        type: '3',
        tips: this.$t('customeTips.Time selector, can select a time point or time period')
      }, {
        title: this.$t('customName.DateSelect'),
        name: "dateSelectComp",
        type: '4',
        tips: this.$t('customeTips.Date selector, can select 1 or more days')
      }, {
        title: this.$t('customName.InputNumber'),
        name: "inputNumberComp",
        type: '5',
        tips: this.$t('customeTips.Counter, used for unit price, quantity of goods, etc')
      }, {
        title: this.$t('customName.FileUpload'),
        name: "fileUploadComp",
        type: '6',
        maxSize: 3,
        tips: this.$t('customeTips.Attachment upload, can upload various types of files')
      }, {
        title: this.$t('customName.ImageUpload'),
        name: "imageUploadComp",
        type: '7',
        maxSize: 5,
        tips: this.$t('customeTips.Image upload, display images can be uploaded')
      }, {
        title: this.$t('customName.TextArea'),
        name: "textareaTextComp",
        type: '8',
        tips: this.$t('customeTips.Text field, can be used as notes, etc')
      }, {
        title: this.$t('customName.Switch'),
        name: "switchSelectComp",
        type: '9',
        tips: this.$t('customeTips.Switch, can be used for permission configuration, etc')
      }, {
        title: this.$t('customName.Score'),
        name: "rateSelectComp",
        type: '10',
        tips: this.$t('customeTips.Switch, can be used for permission configuration, etc')
      }]
    }
  },
  methods: {
    open(data) {
      this.types = data.map(item => item.customType);
      this.customNames = data.map(item => item.customName);
      this.show = true;
    },
    close() {
      this.show = false;
      this.selectValue = "";
    },
    selectCurrent(item) {
      if(this.types.filter(row => row == item.type).length == item.maxSize) {
        this.$message.warning(this.$tc('The current component limit is 3 uploads', item.maxSize));
        return;
      }
      this.selectValue = (item.name.split('Comp')[0]);
    },
    prev() {
      this.selectValue = "";
    },
    emitForm(val) {
      this.customForm = val;
    },
    confirm() {
      this.$refs.customFormDom[0].$refs.ruleForm.validate((valid) => {
        if(!valid) return;
        if(this.customNames.includes(this.customForm.customName)) {
          this.$message.warning(this.$t('Duplicate component form title name'));
          return;
        }
        this.$emit("add", this.customForm);
        this.close();
      })
    }
  },
}
</script>

<style lang="less" scoped>
.Setps {
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    width: 40px;
    height: 1px;
    background: #E0E4EA;
    margin: 0 16px;
  }
  .item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #A3B0C6;
    font-size: 16px;
    .index {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      background: #A3B0C6;
    }
    &.active {
      color: #004C97;
      font-weight: 600;
      .index {
        background: #004C97;
      }
    }
  }
}
.selectListBox {
  margin-top: 32px;
  .item {
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #E0E4EA;
    overflow: hidden;
    margin-bottom: 12px;
    .drawerForm {
      padding: 16px;
    }
    .bottom {
      background: #ffffff;
      padding: 12px;
      .textTip {
        font-size: 12px;
        font-weight: 600;
        color: #122545;
      }
      .btn {
        width: 100%;
        height: 29px;
        background: #E3E9F1;
        color: #122545;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        margin-top: 12px;
        cursor: pointer;
        &:hover {
          background: #122545;
          color: #ffffff;
        }
      }
    }
    /deep/ .el-upload-dragger {
      height: 32px;
      .el-upload__text {
        font-size: 12px;
      }
      .icon-shangchuan {
        font-size: 16px;
      }
    }
    /deep/ .el-textarea {
      .el-textarea__inner {
        height: 32px;
      }
    }
  }
}
</style>