<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <div class="flexTime" v-if="!readonly">
      <el-time-select
        v-model="startTime"
        size="small"
        :disabled="disabled"
        :picker-options="{
          start: '00:00',
          step: form.customOptions && form.customOptions.interval,
          end: '23:59'
        }"
        :placeholder="$t('el.datepicker.startTime')" 
      />
      <div class="line" v-if="form.customOptions && form.customOptions.type == '1'"></div>
      <el-time-select
        v-if="form.customOptions && form.customOptions.type == '1'"
        v-model="endTime"
        size="small"
        :disabled="disabled"
        :picker-options="{
          start: '00:00',
          step: form.customOptions && form.customOptions.interval,
          end: '23:59',
          minTime: startTime
        }"
        :placeholder="$t('el.datepicker.endTime')"
      />
    </div>
    <div class="customValueText" v-else>
      {{ startTime }} {{ endTime ? '-' : '' }} {{ endTime }}
    </div>
  </div>
</template>

<script>
export default {
  name: "timeSelect",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: "",
        customOptions: {
          type: "0",
          interval: "00:30"
        }
      })
    }
  },
  data() {
    return {
      startTime: "",
      endTime: ""
    }
  },
  watch: {
    startTime(val) {
      this.$emit("input", [val, this.endTime]);
    },
    endTime(val) {
      this.$emit("input", [this.startTime, val]);
    }
  },
  mounted() {
    this.startTime = this.value[0];
    this.endTime = this.value[1];
  }
}
</script>

<style lang="less" scoped>
.flexTime {
  display: flex;
  align-items: center;
  gap: 8px;
  /deep/ .el-date-editor {
    flex: 1;
    min-width: 0;
    width: auto;
  }
  .line {
    width: 8px;
    height: 1px;
    background: #A3B0C6;
  }
}
</style>