<template>
  <el-dialog
    :title="$t('xiaoxie.Order return')"
    width="600px"
    :visible.sync="isCIFallback"
    :before-close="onClose"
    @open="open"
    v-move-outside
  >
    <div class="ci_fallback">
      <div class="title">{{ $isOrderPITitle() }}:{{ PI }}</div>
      <div class="tip">{{ $t("xiaoxie.CIFallbacktip") }}</div>
      <div style="height: 200px">
        <el-table
          :data="tableData"
          style="width: 100%; height: 200px"
          height="100%"
          v-loading="loading_table"
        >
          <el-table-column prop="index" label="No." />
          <el-table-column v-if="$store.state.user.orderAlias == 0" prop="ci" label="CI" />
          <el-table-column prop="currency" :label="$t('xiaoxie.currency')" />
          <el-table-column prop="amount" :label="$t('xiaoxie.amount')" />
          <el-table-column prop="orderProgress" :label="$t('xiaoxie.State')" />
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="Cancel" @click="onClose()">{{
        $t("home.Cancel")
      }}</el-button>
      <el-button
        type="primary"
        :loading="loginLoading"
        @click="onFallbackPIOrder()"
      >
        {{ $t("home.Confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getOrderList, fallbackPIOrder } from "@/api/order";
export default {
  name: "CIFallback",
  components: {},
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      loading_table: false,
      loginLoading: false,
    };
  },
  props: [
    "isCIFallback", 
    "PI",
    "orderId"
  ],
  emits: ["onClose", "onIsPower"],
  computed: {
    ...mapGetters(['orderProgress', 'allNodeList'])
  },
  methods: {
    open() {
      this.onCiOrderList();
    },
    onCiOrderList() {
      this.loading_table = true;
      getOrderList({ 
        pi: this.PI,
        currentPage: 1,
        pageSize: 10000
      }).then((data) => {
        this.tableData = data.list.map((v, i) => {
          return {
            ...v,
            index: i + 1,
            amount: Number(v.amount).toFixed(2),
            orderProgress: this.allNodeList.find(item => item.type == v.progress).name,
          };
        });
      }).finally(() => (this.loading_table = false));
    },
    onFallbackPIOrder() {
      this.loginLoading = true;
      fallbackPIOrder(this.orderId)
        .then((res) => {
          this.$router.go(-1);
          this.onClose();
          this.reload(["Customer", "Index", "Order"]);
        })
        .finally(() => (this.loginLoading = false));
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
.ci_fallback {
  .title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(46, 63, 79, 1);
  }

  .tip {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(46, 63, 79, 1);
    margin-bottom: 8px;
  }
}

.Cancel {
  border: 0 !important;
  color: #1f2122 !important;
}
</style>
