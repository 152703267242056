<template>
  <layout>
    <template #config>
      <el-form 
        ref="ruleForm" 
        label-width="0"
        size="small"
        :model="form" 
        :rules="rules"
        class="drawerForm">
        <el-form-item prop="customName">
          <div class="title">{{ $t('xiaoxie.Title') }}</div>
          <el-input
            v-model="form.customName"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('xiaoxie.Title')"
          />
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('Placeholder') }}</div>
          <el-input
            v-model="form.customTips"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('order.Please input content')"
          />
        </el-form-item>
        <el-form-item 
          v-for="(item, index) in form.customOptions" 
          :key="index"
          :prop="`customOptions.${index}.label`"
          :rules="rules.optionLabel">
          <div class="title">{{ $t('Option')+(index+1) }}</div>
          <i v-if="index > 0" class="iconfont icon-shanchu shanchuIcon" @click="deleteOption(index)"></i>
          <el-input
            v-model="item.label"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('order.Please input content')"
          />
        </el-form-item>
        <div class="addBtn" v-if="form.customOptions.length < 20" @click="addOption">
          <i class="el-icon-plus"></i>
          {{ $t('Continue adding options') }}
        </div>
      </el-form>
    </template>
    <template #component>
      <previewComponent v-model="form.customValue" :form="form" />
    </template>
  </layout>
</template>

<script>
import previewComponent from '../component/radioSelect.vue';
import layout from "./components/layout.vue";
export default {
  name: "radioSelect",
  components: {
    layout,
    previewComponent
  },
  data() {
    return {
      form: {
        customType: "1",
        customName: "",
        customTips: "",
        customValue: "",
        customOptions: [{
          label: this.$t('Option')+1,
          value: "0"
        }],
      },
      rules: {
        customName: [{ 
          required: true, message: this.$tc('Please input', this.$t('xiaoxie.Title')), trigger: 'blur' 
        }],
        optionLabel: [{ 
          required: true, message: this.$tc('Please input', this.$t('Option')), trigger: 'blur' 
        }]
      }
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("emitForm", val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addOption() {
      this.form.customOptions.push({
        label: this.$t('Option')+(this.form.customOptions.length+1),
        value: String(this.form.customOptions.length)
      })
    },
    deleteOption(index) {
      this.form.customOptions.splice(index, 1);
    }
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0px;
}
.addBtn {
  border: 1px dashed var(--B1, #004C97);
  width: 100%;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #004C97;
  cursor: pointer;
}
</style>