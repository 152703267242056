<template>
  <div 
    class="blockItem" 
    :class="ActiveId == item.eventId ? 'active' : ''"
    @click="seeEventDetail">
    <div class="fixedMore">
      <el-popover
        v-if="isShare && isCeoAcount"
        placement="right-start"
        trigger="click"
        ref="eventPopover"
        popper-class="orderPopover"
        append-to-body
        :popper-options="{
          boundariesElement: 'body',
          gpuAcceleration: true,
          positionFixed: true,
          preventOverflow: true
        }"
      >
        <ul class="user_popover">
          <li
            class="user_delete"
            @click="followEventChange"
          >
            <i class="iconfont icon-shoucang"></i>
            <span>{{ !item.collect ? $t("home.Follow") : $t("home.Unfollow") }}</span>
          </li>
          <li
            v-if="item.userId == businessUserId"
            class="user_delete"
            @click="openDeleteBox"
          >
            <i class="iconfont icon-shanchu"></i>
            <span>{{ $t("order.Delete") }}</span>
          </li>
          <li
            class="user_delete"
            @click="copyEvent"
          >
            <i class="el-icon-document-copy" style="font-size: 16px;"></i>
            <span>{{ $t("Files.Copy Link") }}</span>
          </li>
        </ul>
        <div 
          class="moreBox" 
          :class="ActiveId == item.eventId ? 'active' : ''"
          slot="reference"
          @click.stop="
            onVisibleMore(
              index == visible_moreIndex ? -1 : index
            )
          "
          v-if="!item.keyEvent">
          <i class="iconfont icon-Ellipsis"></i>
        </div>
      </el-popover>
    </div>
    <div class="content" v-if="!item.keyEvent">
      <div class="title line1">{{ item.subject }}</div>
      <div class="time">{{ $dayjs(item.createTime).format("YYYY/MM/DD HH:mm:ss") }}</div>
      <div class="contentFlex">
        <img v-if="eventRichImage" :src="eventRichImage" @click="$replayImgShow($event)" />
        <div class="contentText line2" v-html="item.content.replace(/<\/?img[^>]*>/gi,'')"></div>
      </div>
    </div>
    <template v-else>
      <div class="content">
        <div class="title line1">{{ systemName }}</div>
        <div class="time">{{ $dayjs(item.createTime).format("YYYY/MM/DD HH:mm:ss") }}</div>
        <div class="contentText line2">
          <span class="name" v-if="item.contentJson.type != 4">{{ item.createPerson.nickName }}</span>
          <span class="name" v-else>{{ $t('memberList.manage') }}</span>
          <span class="behavior">{{ behaviorComputed }}</span>
          <span class="title">{{ titleTypeComputed }}</span>
        </div>
        <div class="clockInContent" v-if="item.contentJson.type == 3">
          <div class="text" v-if="computedNodeList(item.contentJson.oldProgress)">
            {{ $t('order.orderStatusForm') }}
            {{ computedNodeList(item.contentJson.oldProgress).name }}
            {{ $t('order.change to') }}
            <span>{{ computedNodeList(item.contentJson.newProgress).name }}</span>
          </div>
        </div>
        <div class="changeContent" v-if="item.contentJson.type == 1">
          <div v-if="item.contentJson.status == 2">
            <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index">
              <span>{{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}</span>
              {{ $t('order.form') }}
              {{ fieldValueComputed(row) }}
              {{ $t('order.change to') }}
              <span>{{ fieldValue1Computed(row) }}</span>
            </div>
          </div>
          <div class="changeBox" v-if="item.contentJson.status == 1 || detailOpen">
            <div class="item" v-for="(row, index) in $store.state.user.orderAlias == 0 ? item.contentJson.data.filter((tt, ii) => ii < 6) : item.contentJson.data.filter(item => item.fieldName != 'CI号' && item.fieldName != 'CI number') " :key="index">
              <span>
                {{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}
                <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
              </span>
              <span>{{ fieldValue1Computed(row) }}</span>
            </div>
          </div>
        </div>
        <div class="shipContent" ref="shipContent" v-if="item.contentJson.type == 2">
          <div v-if="item.contentJson.status == 2">
            <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index+'two'">
              <span>{{ row.fieldName }}</span>
              {{ $t('order.form') }}
              {{ shipFieldValueComputed(row) }}
              {{ $t('order.change to') }}
              <span>{{ shipFieldValue1Computed(row) }}</span>
            </div>
          </div>
          <div class="shipBox" v-if="item.contentJson.status == 1 || detailOpen">
            <div class="item" v-for="(row, index) in item.contentJson.data.filter((tt, ii) => ii < 6)" :key="index">
              <span>
                {{ row.fieldName }}
                <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
              </span>
              <span>
                {{ shipFieldValue1Computed(row).split(" ")[0] }}
                <span v-if="shipFieldValue1Computed(row).split(' ')[1]">
                  {{ shipFieldValue1Computed(row).split(' ')[1] }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="bottomFixed">
      <div class="header" v-if="(item.companyTagList && item.companyTagList) || item.collect">
        <div class="tagList">
          <div 
            class="tagItem line1" 
            v-for="(row, idx) in item.companyTagList && item.companyTagList.filter((r, ridx) => ridx < 3)" 
            :key="idx"
            :style="{
              'background': row.color
            }">
            {{ row.content }}
          </div>
          <div 
            class="tagHideNumber" 
            v-if="item.companyTagList && item.companyTagList.length > 3"
          >+{{ item.companyTagList.length - 3 > 99 ? '99+' : item.companyTagList.length - 3 }}</div>
        </div>
        <div class="action">
          <i class="iconfont icon-yishoucang" v-if="item.collect"></i>
        </div>
      </div>
      <div class="footer">
        <member v-if="!item.keyEvent" :info="item.createPerson" :size="32" />
        <i v-else class="iconfont avatar" :class="iconComputed"></i>
        <div class="tips">
          <div class="comment" v-if="item.commentList.length">
            <i class="iconfont icon-lianxiguanliyuan"></i>
            {{ item.commentList.length }}
          </div>
          <i v-if="item.eventFileUrl" class="iconfont icon-jinwenjian"></i>
          <el-tooltip
            popper-class="ec-tooltip"
            effect="light"
            placement="top"
            v-if="item.createPerson.openId == businessUserId"
          >
            <div class="whoSeeBox" slot="content">
              <template v-if="item.eventLimit == 1">{{ $t('event.Only me') }}</template>
              <template v-else>
                <div class="memberBox">
                  <template v-for="(row, idx) in item.visibleOpenUserDTO">
                    <member
                      :info="row"
                      :key="idx"
                      :size="20"
                    />
                  </template>
                </div>
                <span class="memberText">{{ $t('event.Can see') }}</span>
              </template>
            </div>
            <i 
              v-if="item.eventLimit == 1 || item.eventLimit == 2"
              class="iconfont who" 
              :class="item.eventLimit == 1 ? 'icon-jinziji' : item.eventLimit == 2 ? 'icon-bufenren' : ''"
            ></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <seeEventDetail 
      ref="seeEventDetail"
      :item="item" 
      :orderInfo="orderInfo" 
      :isCeoAcount="isCeoAcount" 
      :shippingInfo="shippingInfo" 
      @copyEvent="copyEvent"
      @openDeleteBox="openDeleteBox"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  deleteEvent,
  followEvent
} from "@/api/event";
import seeEventDetail from "@/components/seeEventDetail"
export default {
  components: {
    seeEventDetail
  },
  props: {
    isShare: {
      type: Boolean,
      default: true
    },
    isCeoAcount: {
      type: Boolean | Object,
      default: true
    },
    item: {
      type: Object | null,
      default: null
    },
    index: {
      type: Number | String,
      default: 0
    },
    ActiveId: {
      type: String | Number | null,
      default: null
    },
    visible_moreIndex: {
      type: Number | String,
      default: 0
    },
    orderInfo: {
      type: Object | null,
      default: null
    },
    activityList: {
      type: Array,
      default: () => []
    },
    shippingInfo: {
      type: Object | null,
      default: null
    }
  },
  data() {
    return {
      detailOpen: false
    }
  },
  computed: {
    ...mapGetters(["businessUserId", "orderProgress", "country", "allNodeList"]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    eventRichImage() {
      let arr = [];
      this.item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
        arr.push(capture);
      });
      return arr[0];
    },
    systemName() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) return `${this.$isOrderTitle()}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 2) return `${this.$t("home.waybill")}：${this.shippingInfo.bl}`
        if(content.type == 3) return `${this.$t("home.clock in")}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 4) return this.$tc('order.Order node change', this.$isOrderTitle())
      }
    },
    iconComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return "icon-dingdan1";
        } else if(content.type == 2) {
          return "icon-yundan";
        } else if(content.type == 3) {
          return "icon-daka";
        } else {
          return "icon-shuaxin";
        }
      }
    },
    behaviorComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.$isOrderTitle());
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.$isOrderTitle());
        } else {
          return this.$tc("order.Order node", this.$isOrderTitle());
        }
      }
    },
    fieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue;
        }
      }
    },
    fieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue1 || row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue1 || row.fieldValue;
        }
      }
    },
    shipFieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue1 == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue1 || row.fieldValue || "";
        }
      }
    }
  },
  methods: {
    seeEventDetail() {
      this.$refs.seeEventDetail.open();
    },
    onVisibleMore(index) {
      document.body.click();
      this.$emit("update:ActiveId", this.item.eventId);
      this.$emit("update:visible_moreIndex", index);
    },
    openDeleteBox() {
      document.body.click();
      this.$confirm(
        this.$t("event.This event will be deleted"),
        this.$t("event.Notification"),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        var data_eventDelete = {
          eventId: this.item.eventId,
        };
        deleteEvent(data_eventDelete).then((response) => {
          if (!response) return;
          this.$emit("deleteEventData", this.activityList.indexOf(this.item), 1);
          this.$message.success(this.$t("event.Delete successfully"));
        }).finally(() => {
          this.onClose();
          document.body.click();
        });
      }).catch(() => {
        this.onClose();
        this.$message.info(this.$t("event.Delete cancel"));
        document.body.click();
      });
    },
    onClose() {
      this.$emit("update:visible_moreIndex", -1);
    },
    copyEvent() {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(`${location.href.split("?")[0]}?orderId=${this.orderInfo.orderId}&eventId=${this.item.eventId}`).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
        this.$emit("update:ActiveId", null);
        document.body.click();
      });
    },
    followEventChange() {
      followEvent(this.item.eventId).then(() => {
        this.$message.success(
          (!this.item.collect ? this.$t('home.Follow') : this.$t('home.Unfollow'))+this.$t('success')
        );
        this.item.collect = !this.item.collect;
        this.$emit("update:ActiveId", null);
        document.body.click();
      }).finally(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
.blockItem {
  border: 1px solid #E3E9F1;
  border-radius: 4px;
  width: 100%;
  height: 253px;
  margin-bottom: 22px;
  cursor: pointer;
  transition: all .3s;
  padding: 20px 13px;
  position: relative;
  &:hover {
    background: #F7F9FC;
    border-color: #F7F9FC;
  }
  &.active {
    background: #F7F9FC;
    border-color: #F7F9FC;
  }
  .fixedMore {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .bottomFixed {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 28px);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .tagList {
      display: flex;
      gap: 6px;
      .tagItem {
        border-radius: 20px;
        line-height: 24px;
        padding: 0 8px;
        font-size: 12px;
        color: #ffffff;
        max-width: 70px;
      }
      .tagHideNumber {
        border-radius: 20px;
        line-height: 22px;
        padding: 0 12px;
        font-size: 12px;
        color: #122545;
        border: 1px solid #E3E9F1;
      }
    }
    .action {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon-yishoucang {
        color: #DF8F31;
      }
    }
  }
  .content {
    height: 140px;
    overflow: hidden;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      color: #122545;
      margin-bottom: 6px;
      padding-right: 22px;
    }
    .time {
      font-size: 12px;
      line-height: 16.8px;
      color: #637381;
      margin-bottom: 10px;
    }
    .contentFlex {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      img {
        width: 49px;
        height: 37px;
        border-radius: 4px;
        object-fit: contain;
        background: #E3E9F1;
      }
      .contentText {
        margin-top: 2px;
      }
    }
    .contentText {
      font-size: 12px;
      line-height: 16.8px;
      color: #637381;
      /deep/ a {
        color: #076F49;
        text-decoration: underline;
      }
      /deep/ img {
        height: 85px;
      }
      .name {
        font-size: 14px;
        color: #122545;
        font-weight: 500;
        margin-right: 4px;
      }
      .behavior {
        font-size: 14px;
        color: #637381;
        margin-right: 4px;
      }
      .title {
        font-size: 14px;
        color: #076F49;
        font-weight: 500;
      }
    }
  }
  .clockInContent {
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 4px;
    .text {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      span {
        color: #076F49;
      }
    }
  }
  .changeContent {
    margin-left: 3px;
    .itemTwo {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .changeBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 6px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 12px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .shipContent {
    .itemTwo {
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .shipBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 6px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 12px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              font-size: 12px;
              color: #637381;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avatar {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #076F49;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    .tips {
      display: flex;
      align-items: center;
      gap: 14px;
      .comment {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #122545;
        i {
          color: #757D8A;
        }
      }
      .icon-jinwenjian {
        color: #757D8A;
      }
      .icon-jinziji {
        color: #757D8A;
      }
      .icon-bufenren {
        color: #076F49;
      }
    }
  }
}
</style>