<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <chat-select 
      v-if="!readonly"
      v-model="inputValue" 
      :placeholder="form.customTips || $t('order.Please input content')"
      size="small"
      :disabled="disabled"
      clearable>
      <el-option
        v-for="item in form.customOptions"
        :key="item.value"
        :label="item.label || `${$t('Option')}${Number(item.value)+1}`"
        :value="item.value">
      </el-option>
    </chat-select>
    <div class="customValueText" v-else>
      {{ $isFindData(form.customOptions, inputValue, 'value', 'label') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "radioSelect",
  props: {
    value: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: "",
        customOptions: [{
          label: "选项一",
          value: "0"
        }]
      })
    }
  },
  data() {
    return {
      inputValue: ""
    }
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    }
  },
  mounted() {
    this.inputValue = this.value;
  }
}
</script>

<style>

</style>