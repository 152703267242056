<template>
  <el-dialog
    :title="$t('xiaoxie.Order Amount Confirmation')"
    width="358px"
    :visible.sync="isMoneyAffirm"
    :before-close="onClose"
    v-move-outside
  >
    
    <div class="tipPi" v-if="moneyAffirmSource">
      <span>
        {{ $t("xiaoxie.Original PI order currency") }}：{{ moneyAffirmSource.currency }}
      </span>
      {{ $t("xiaoxie.Original PI order amount") }}：{{ moneyAffirmSource.amount }}
    </div>
    <div class="ciListBox">
      <div class="item" v-for="(item, index) in moneyAffirmList" :key="index">
        <div class="top">
          <div class="left">
            <div class="index">{{ index+1 }}</div>
            <div class="title">CI号：{{ item.ci }}</div>
          </div>
        </div>
        <div class="bottom">
          <span class="amount">{{ item.amount }}</span>
          <span class="unit">{{ item.currency }}</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="footerFlex">
      <div class="total">
        <span>{{ total }}</span>
        {{ $t('order.totalPrice') }}(USD)
      </div>
      <div>
        <el-button @click="onClose" size="small" class="cancel_button">{{
          $t("order.to previous step")
        }}</el-button>
        <el-button type="primary" size="small" @click="affirm" :loading="loginLoading">
          {{ $t("home.Confirm") }}
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { sum } from "lodash";
import { createCIOrder } from "@/api/order";
import { formatNum } from "@/utils/utils";
export default {
  name: "moneyAffirm",
  components: {},
  inject: ["reload"],
  data() {
    return {
      loginLoading: false,
    };
  },
  props: [
    "isMoneyAffirm", 
    "moneyAffirmList", 
    "orderId", 
    "moneyAffirmFileData",
    "moneyAffirmSource"
  ],
  emits: ["onClose"],
  watch: {},
  computed: {
    total() {
      let total = Number(
        sum(
          this.moneyAffirmList.map((v) => {
            return Number(v.amount);
          })
        )
      );
      return total ? formatNum(total.toFixed(3)) : 0;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    affirm() {
      this.loginLoading = true;
      createCIOrder(this.moneyAffirmFileData).then(() => {
        this.$emit("onClose", true);
        // this.$message.success(msg);
      }).finally(() => (this.loginLoading = false));
    },
  },
};
</script>
<style lang="less" scoped>
.tipPi {
  background: #E3E9F1;
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 16px;
  text-align: left;
  span {
    margin-right: 20px;
  }
}
.ciListBox {
  margin-top: 40px;
  .item {
    padding: 12px 16px;
    cursor: pointer;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .left {
        display: flex;
        align-items: center;
      }
      .index {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #2A3B58;
        color: #ffffff;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
      }
      .title {
        font-size: 14px;
        line-height: 20px;
        color: #637381;
      }
      .btn {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #757D8A;
        background: #ffffff;
        opacity: 0;
        transition: all .3s;
        i {
          font-size: 12px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      background: #F7F9FC;
      padding: 2px 15px;
      border-radius: 4px;
      .amount {
        font-size: 14px;
        line-height: 28px;
        color: #076F49;
        font-weight: 600;
      }
      .unit {
        font-size: 16px;
        line-height: 22px;
        color: #122545;
        font-weight: 500;
      }
    }
    &:hover {
      background: #F7F9FC;
      .bottom {
        background: #ffffff;
      }
    }
  }
}
.total {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  line-height: 17px;
  color: #757D8A;
  span {
    color: #076F49;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
