<template>
  <div>
    <el-dialog
      :title="$t('home.Edit')+$t('order.Event tag')"
      :visible.sync="dialogVisible"
      width="577px"
      :before-close="handleClose">
      <div class="tagBox">
        <div class="title">
          <p>
            {{ $t('Public label area') }}
            <span>{{ colorArray.length }}</span>
          </p>
          <p @click="seeOperation">
            <span>{{ operationList.length }}</span>
            {{ $t('Operation records') }}
          </p>
        </div>
        <div class="orderTags">
          <div 
            class="tag" 
            v-for="(item, index) in colorArray" 
            :key="index"
            :style="{
              background: item.color
            }">
            <div class="name">{{ item.content }}</div>
            <i class="iconfont icon-bianji3" @click.stop="editTag(item, index)"></i>
            <i class="el-icon-close" @click.stop="tagDelete(index)"></i>
          </div>
          <div class="addTagBtn" @click="addTag" v-if="colorArray.length < 30">
            <i class="iconfont icon-Plus"></i>
            {{ $t('home.CIAdd')+$t('order.Event tag') }}
          </div>
        </div>
        <div class="orderTagGroups">
          <div class="title">
            <div class="name">
              <i class="iconfont icon-bianqian"></i>
              {{ $t('order.My Tag Group') }}
            </div>
            <div class="addGroupBtn" @click="addGroup">
              <i class="iconfont icon-Plus"></i>
              {{ $t('order.Add Tag Group') }}
            </div>
          </div>
          <div 
            class="groupItem" 
            v-for="(item, index) in groupList" 
            :key="index">
            <div class="groupTitle">
              <div class="left">
                <div class="name" v-if="!item.edit">{{ item.name }}</div>
                <el-input 
                  v-model="item.name" 
                  v-else 
                  size="mini" 
                  style="width:80px;"
                  @keyup.enter.native="item.edit = false"
                />
                <i class="iconfont icon-Vector" @click="item.edit = !item.edit"></i>
                <i class="iconfont icon-shanchu" @click="deleteGroup(index)"></i>
              </div>
              <div class="right" v-if="item.tags.length">
                <p @click="groupTagCheckChange(item)">{{ $t('Use current label group') }}</p>
              </div>
            </div>
            <div class="orderTags">
              <div 
                class="tag" 
                v-for="(row, idx) in item.tags" 
                :key="idx"
                :class="row.check ? 'active' : ''"
                :style="{
                  background: row.color
                }"
                @click="groupTagClick(row)">
                <div class="name">{{ row.content }}</div>
                <i class="iconfont icon-bianji3" @click.stop="editGroupTag(index, row, idx)"></i>
                <i class="el-icon-close" @click.stop="groupTagDelete(index, idx)"></i>
              </div>
              <div class="addTagBtn" @click="addGroupTag(index)" v-if="item.tags.length < 30">
                <i class="iconfont icon-Plus"></i>
                {{ $t('home.CIAdd')+$t('order.Event tag') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btns">
          <el-button class="cancel_button" size="small" @click="handleClose">{{
            $t("home.Cancel")
          }}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="submit"
            :loading="btnLoading"
          >
            {{ $t("home.ConfirmAgree") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="dialogAddVisible"
      :title="modeType == 'add' ? $t('home.CIAdd')+$t('order.Event tag') : $t('home.Edit')+$t('order.Event tag')"
      :before-close="handleAddClose"
      width="358px">
      <el-input 
        v-model="addForm.content" 
        :maxlength="20" 
        show-word-limit 
        size="small"
        :placeholder="$t('order.Tag type name')" 
      />
      <div class="colorBox">
        <div class="label">{{ $t('color') }}</div>
        <div class="colors">
          <div 
            class="item" 
            v-for="(item, index) in colorOptions" 
            :key="index" 
            :class="item.check ? 'active' : ''"
            :style="{
              background: item.value
            }"
            @click="colorChange(item)"
          ></div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="handleAddClose"
        >{{$t("home.Cancel")}}</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitAdd"
        >{{ $t("home.ConfirmAgree") }}</el-button>
      </div>
    </el-dialog>
    <el-dialog 
      :visible.sync="dialogOperationVisible"
      :title="$t('Operation records')"
      :before-close="() => dialogOperationVisible = false"
      width="700px">
      <el-table
        :data="operationList"
        style="width: 100%"
        :show-header="false">
        <el-table-column
          prop="openUserDTO"
          width="120">
          <template slot-scope="{row}">
            <div class="customerName" v-if="row.openUserDTO">
              <member :info="row.openUserDTO" :size="28"></member>
              <span>{{ row.openUserDTO.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          width="500px">
          <template slot-scope="{row}">
            <div class="tagOperationBox" v-for="(item, index) in row.contentJson" :key="index">
              {{ row.type == 1 ? $t('Create') : row.type == 2 ? $t('home.Edit') : $t('home.Delete') }}
              <div class="orderTags">
                <div 
                  class="tag" 
                  :style="{
                    background: item.oldColor
                  }">
                  <div class="name">{{ item.oldContent }}</div>
                </div>
                <span v-if="row.type == 2">{{ $t('do') }}</span>
                <div 
                  v-if="row.type == 2"
                  class="tag" 
                  :style="{
                    background: item.newColor
                  }">
                  <div class="name">{{ item.newContent }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('Files.CreateTime')"
          width="180"
          fixed="right">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { addTags } from "@/api/order";
import { getConfigTagList, setUserConfigTags, seeTagOperationRecords } from "@/api/common";
export default {
  name: "configTagDialog",
  props: {
    tagDataList: {
      type: Array | null,
      default: () => []
    }
  },
  data() {
    return {
      isSubmit: false,
      colorArray: [],
      operationList: [],
      dialogVisible: false,
      dialogOperationVisible: false,
      btnLoading: false,
      modeType: "add",
      dialogAddVisible: false,
      colorOptions: [{
        value: "#225E7A",
        check: true
      }, {
        value: "#F3AA62",
        check: false
      }, {
        value: "#EF6464",
        check: false
      }, {
        value: "#478B97",
        check: false
      }, {
        value: "#B8621B",
        check: false
      }, {
        value: "#3075C6",
        check: false
      }, {
        value: "#4B508C",
        check: false
      }, {
        value: "#076F49",
        check: false
      }],
      editIndex: null,
      groupIndex: null,
      addForm: {
        content: "",
        color: "#225E7A"
      },
      groupList: []
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.colorArray = JSON.parse(JSON.stringify(this.tagDataList));
      this.getGroupList();
      this.getOperationList();
    },
    handleClose(done) {
      let isConfirm = this.colorArray.map(item => item.tagId).includes(undefined);
      let arr = [];
      this.groupList.forEach(item => {
        item.tags.forEach(row => {
          arr.push(row.new)
        })
      })
      let isConfirm2 = arr.includes(false);
      console.log(isConfirm2, arr);
      if((isConfirm || isConfirm2) && !this.isSubmit) {
        this.$confirm(
          this.$t('After closing, the label will not be saved'), 
          this.$t("You still have unsaved tags"),
          {
            confirmButtonText: this.$t('save'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          done();
          this.isSubmit = false;
          this.submit();
        }).catch(() => {
          this.dialogVisible = false;
          this.colorArray = [];
          this.groupList = [];
          this.isSubmit = false;
        });
      } else {
        this.dialogVisible = false;
        this.colorArray = [];
        this.groupList = [];
        this.isSubmit = false;
      }
    },
    handleAddClose() {
      this.dialogAddVisible = false;
      this.modeType = 'add';
      this.groupIndex = null;
      this.editIndex = null;
      this.addForm = {
        content: "",
        color: ""
      }
      this.colorOptions.forEach((item, index) => {
        if(!index) item.check = true;
        else item.check = false;
      });
    },
    seeOperation() {
      this.dialogOperationVisible = true;
    },
    getOperationList() {
      seeTagOperationRecords({
        orderId: this.$route.query.orderId,
        currentPage: 1,
        pageSize: 5000
      }).then((data) => {
        this.operationList = data.list;
      })
    },
    getGroupList() {
      getConfigTagList().then(data => {
        this.groupList = data.map(item => {
          return {
            name: item.name || this.$t('home.Unnamed'),
            check: false,
            edit: false,
            tags: item.tags.map(row => ({
              ...row,
              new: true,
              check: false
            }))
          }
        });
      })
    },
    groupTagClick(row) {
      if(this.colorArray.map(item => item.content).includes(row.content)) {
        this.$message.warning(this.$t("The order label has a duplicate tag with the current label group and cannot be used"));
        return;
      }
      this.colorArray.push({
        color: row.color,
        content: row.content
      })
    },
    groupTagCheckChange(row) {
      if(row.tags.length + this.colorArray.length < 30) {
        let arr = []
        this.colorArray.forEach(item => {
          row.tags.forEach(tag => {
            arr.push(item.content == tag.content)
          })
        })
        if(arr.includes(true)) {
          this.$message.warning(this.$t("The order label has a duplicate tag with the current label group and cannot be used"))
          return;
        }
        row.tags.forEach(item => {
          item.check = row.check;
        })
        this.colorArray.push(...row.tags.map(item => ({
          color: item.color,
          content: item.content
        })))
      } else {
        this.$message.warning(this.$t("Order label is full and cannot be used"))
      }
    },
    addGroup() {
      if(this.groupList.length == 5) {
        this.$message.warning(this.$t("order.Only 5 label groups can be created"))
        return;
      }
      this.groupList.unshift({
        name: this.$t('home.Unnamed'),
        check: false,
        edit: false,
        tags: []
      })
    },
    colorChange(row) {
      this.colorOptions.forEach(item => {
        item.check = false;
      });
      row.check = !row.check;
    },
    editTag(row, index) {
      this.modeType = 'edit';
      this.addForm = JSON.parse(JSON.stringify(row));
      this.editIndex = index;
      this.colorOptions.forEach(item => {
        if(item.value == row.color) {
          item.check = true;
        } else {
          item.check = false;
        }
      })
      this.dialogAddVisible = true;
    },
    tagDelete(index) {
      this.$confirm(this.$t('Tags of the same type that have been used after deletion will also be deleted'), this.$t('Are you sure to delete the label'), {
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.colorArray.splice(index, 1);
      }).catch(() => {});
    },
    addGroupTag(index) {
      this.dialogAddVisible = true;
      this.groupIndex = index;
    },
    editGroupTag(index, row, idx) {
      this.modeType = 'edit';
      this.addForm = JSON.parse(JSON.stringify(row));
      this.groupIndex = index;
      this.editIndex = idx;
      this.colorOptions.forEach(item => {
        if(item.value == row.color) {
          item.check = true;
        } else {
          item.check = false;
        }
      })
      this.dialogAddVisible = true;
    },
    groupTagDelete(index, idx) {
      this.groupList[index].tags.splice(idx, 1);
    },
    addTag() {
      this.dialogAddVisible = true;
    },
    submitAdd() {
      if(!this.addForm.content) {
        this.$message.warning(this.$t('home.Please enter')+this.$t('order.Tag type name'));
        return;
      }
      if(this.groupIndex == null) {
        if(this.colorArray.map(item => item.content+item.color).includes(this.addForm.content+this.colorOptions.filter(item => item.check)[0].value)) {
          this.$message.warning(this.$t('The current label is duplicated and cannot be added'))
          return;
        }
        if(this.modeType == 'add') {
          let item = this.colorOptions.filter(item => item.check)[0];
          this.colorArray.push({
            ...this.addForm,
            color: item.value
          })
          this.handleAddClose();
          return;
        }
        let item = this.colorOptions.filter(item => item.check)[0];
        this.colorArray.splice(this.editIndex, 1, {
          ...this.addForm,
          color: item.value
        })
        this.handleAddClose();
      } else {
        if(this.groupList[this.groupIndex].tags.map(item => item.content+item.color).includes(this.addForm.content+this.colorOptions.filter(item => item.check)[0].value)) {
          this.$message.warning(this.$t('The current label is duplicated and cannot be added'))
          return;
        }
        if(this.modeType == 'add') {
          let item = this.colorOptions.filter(item => item.check)[0];
          this.groupList[this.groupIndex].tags.push({
            ...this.addForm,
            new: false,
            color: item.value
          })
          this.handleAddClose();
          return;
        }
        let item = this.colorOptions.filter(item => item.check)[0];
        this.groupList[this.groupIndex].tags.splice(this.editIndex, 1, {
          ...this.addForm,
          color: item.value
        })
        this.handleAddClose();
      }
    },
    submit() {
      let groupValue = this.groupList.map(item => item.name);
      if(groupValue.includes("")) {
        this.$message.warning(this.$t('home.Please enter')+this.$t('order.Label Group Name'));
        return;
      }
      this.isSubmit = true;
      let arr = JSON.parse(JSON.stringify(this.colorArray));
      addTags({
        orderId: this.$route.query.orderId,
        tagList: arr
      }).then(() => {
        this.$emit("update:tagDataList", arr);
        this.$emit("updateEvent");
      })
      setUserConfigTags(this.groupList.map(item => ({
        name: item.name,
        tags: item.tags.map(row => ({
          content: row.content,
          color: row.color
        }))
      })))
      this.handleClose();
    },
    deleteGroup(index) {
      this.$confirm(
        this.$t('After deletion, the order related labels will also be deleted'), 
        this.$t("Are you sure to delete the label group"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        this.groupList.splice(index, 1)
        setUserConfigTags(this.groupList.map(item => ({
          name: item.name,
          tags: item.tags.map(row => ({
            content: row.content,
            color: row.color
          }))
        })));
      }).catch(() => {

      });
    }
  }
}
</script>

<style lang="less" scoped>
.tagBox {
  width: 100%;
  height: 450px;
  overflow: overlay;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    p {
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      gap: 6px;
      span {
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 1;
        color: #122545;
        font-weight: 600;
      }
      &:first-child {
        color: #122545;
        span {
          background: #D9D9D9;
        }
      }
      &:last-child {
        color: #076F49;
        cursor: pointer;
        span {
          background: #DC3545;
          color: #ffffff;
        }
      }
    }
  }
  
  .orderTagGroups {
    margin-top: 24px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 28px;
        color: #122545;
        i {
          font-size: 18px;
          color: #757D8A;
        }
      }
      .addGroupBtn {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 28px;
        color: #076F49;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          opacity: .8;
        }
        i {
          font-size: 12px;
        }
      }
    }
    .groupItem {
      width: 100%;
      background: #F7F9FC;
      border-radius: 4px;
      padding: 16px;
      margin-top: 13px;
      .groupTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .left {
          display: flex;
          align-items: center;
          gap: 8px;
          .name {
            line-height: 28px;
            color: #122545;
            font-size: 14px;
          }
          i {
            font-size: 12px;
            color: #A3B0C6;
            cursor: pointer;
          }
        }
        .right {
          font-size: 13px;
          color: #637381;
          line-height: normal;
          p {
            color: #076F49;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.colorBox {
  display: flex;
  align-items: center;
  margin-top: 18px;
  .label {
    color: #637381;
    font-size: 12px;
    line-height: 22px;
    flex: 1;
  }
  .colors {
    display: flex;
    align-items: center;
    gap: 13px;
    .item {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        position: relative;
        &::before {
          content: "\e6da";
          font-family: "element-icons";
          font-size: 16px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          width: 14px;
          height: 14px;
          background: #48AC42;
          color: #ffffff;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
.tagOperationBox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}
.orderTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  .tag {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 14px;
    border-radius: 50px;
    cursor: pointer;
    .name {
      font-size: 14px;
      line-height: 22px;
      color: #fff;
    }
    i {
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
    &.active {
      position: relative;
      &::before {
        content: "\e6da";
        font-family: "element-icons";
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 14px;
        height: 14px;
        background: #48AC42;
        color: #ffffff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .addTagBtn {
    border: 1px dashed #E0E4EA;
    padding: 4px 18px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    color: #637381;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
    i {
      font-size: 12px;
      margin-right: 6px;
    }
  }
}
</style>