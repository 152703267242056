<template>
  <layout>
    <template #config>
      <el-form 
        ref="ruleForm" 
        label-width="0"
        size="small"
        :model="form" 
        :rules="rules"
        class="drawerForm">
        <el-form-item prop="customName">
          <div class="title">{{ $t('xiaoxie.Title') }}</div>
          <el-input
            v-model="form.customName"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('xiaoxie.Title')"
          />
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('Placeholder') }}</div>
          <el-input
            v-model="form.customTips"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('order.Please input content')"
          />
        </el-form-item>
      </el-form>
    </template>
    <template #component>
      <previewComponent v-model="form.customValue" :form="form" />
    </template>
  </layout>
</template>

<script>
import previewComponent from '../component/inputText.vue';
import layout from "./components/layout.vue";
export default {
  name: "inputText",
  components: {
    layout,
    previewComponent
  },
  data() {
    return {
      form: {
        customType: "0",
        customName: "",
        customTips: "",
        customValue: ""
      },
      rules: {
        customName: [{ 
          required: true, message: this.$tc('Please input', this.$t('xiaoxie.Title')), trigger: 'blur' 
        }]
      }
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("emitForm", val);
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0px;
}
</style>