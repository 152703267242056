<template>
  <div class="search-wrapper" @click.stop.prevent>
    <div class="input-holder">
      <input
        ref="searchInput"
        type="text"
        v-model="searchText"
        class="search-input"
        :placeholder="$t('memberList.Search')"
        @keyup.enter="search"
      />
      <div v-show="isShow" class="total">{{ num === 0 ? '0/0' : indexNum + 1 + '/' + num }}</div>
      <button v-show="!isShow" class="search-icon" @click="onSearchBtn">
        <span></span>
      </button>
      <button v-show="isShow" class="prev-icon" @click="prev">
        <span></span>
      </button>
      <button v-show="isShow" class="next-icon" @click="next">
        <span></span>
      </button>
    </div>
    <span class="close" @click="searchToggle"></span>
  </div>
</template>

<script>
export default {
  name: "globalSearchBox",
  data() {
    return {
      isShow: false,
      searchText: "",
      scroll: "",
      index: 1,
      query: "",
      preQuery: "",
      indexNum: 0,
      num: 0,
      main: null,
      showArr: [],
      showIndex: 0,
    };
  },
  props: {
    className: {
      type: String,
      default: "",
    },
  },
  watch: {
    searchText() {
      this.search();
    },
  },
  mounted() {
    this.getConfigDom();
  },
  methods: {
    getConfigDom() {
      this.$nextTick(() => {
        // scroll代表滚动条距离页面顶部距离
        window.addEventListener("scroll", this.dataScroll);
        let DomTitle = document.querySelectorAll(".event_name_box_h6");
        let DomContent = document.querySelectorAll(".lmi-wrapper_htmls");
        let DomFileName = document.querySelectorAll(".file_block_name");
        let DomClockInContent = document.querySelectorAll(".clockInContent");
        let DomSystemEventTitle = document.querySelectorAll(".systemEventTitle");
        this.main = [...DomTitle, ...DomContent, ...DomFileName, ...DomClockInContent, ...DomSystemEventTitle];
        document.addEventListener('click', this.bodyClose)
      });
    },
    bodyClose() {
      let node = document.querySelector('.search-wrapper');
      if(node) {
        if(node.classList.contains('active')){
          this.searchToggle();
        }
      }
    },
    onSearchBtn() {
      if (this.isShow) {
        this.search();
      } else {
        this.searchToggle();
      }
    },
    searchToggle() {
      if (!this.isShow) {
        document.querySelector(".search-wrapper").classList.add("active");
        this.isShow = true;
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 1000)
      } else {
        document.querySelector(".search-wrapper").classList.remove("active");
        this.isShow = false;
        this.searchText = ''
      }
    },
    search() {
      this.$emit("updateEvent", this.searchText);
      // this.num = 0;
      // this.indexNum = 0;
      // this.query = this.searchText;
      // for (let i in this.main) {
      //   var innerHTML = this.main[i].innerHTML;
      //   if (innerHTML) {
      //     // 如果要兼容IE就使用下面的正则 (谷歌 IE 都可以使用)
      //     //innerHTML = innerHTML.replace(new RegExp('<[^>]+>', 'g'), function(str) {
      //     //return str.replace(new RegExp('(<\/?em.*?>)|(<\/?strong.*?>)', 'g'), '')
      //     //})
      //     //------ 这行代码不兼容IE
      //     const emReg1 = new RegExp(
      //       '<em style="background-color: yellow">',
      //       "g"
      //     );
      //     innerHTML = innerHTML.replace(emReg1, "");
      //     const emReg2 = new RegExp("</em>", "g");
      //     innerHTML = innerHTML.replace(emReg2, "");
      //     const emReg3 = new RegExp(
      //       '<strong style="background-color: #ff9632">',
      //       "g"
      //     );
      //     innerHTML = innerHTML.replace(emReg3, "");
      //     const emReg4 = new RegExp("</strong>", "g");
      //     innerHTML = innerHTML.replace(emReg4, "");
      //     // let innerHtmlCopy = innerHTML.replace(/<acronym\b[^<]*(?:(?!<\/acronym>)<[^<]*)*<\/acronym>/gi,"");
      //     // ------
      //     this.main[i].innerHTML = innerHTML;
      //     // 设置本次搜索背景色
      //     if (this.searchText !== "" && this.searchText !== " ") {
      //       const reg = new RegExp(this.searchText, "g");
      //       const searchText = this.searchText;
      //       var mainInnerHTML = innerHTML.replace(
      //         new RegExp("(?=\>).+?(?=\<)", "g"),
      //         function (str) {
      //           const reg1 = new RegExp("(?=>).+", "g");
      //           return str.replace(new RegExp("(>).+", "g"), function (str2) {
      //             return str2.replace(reg1, function (str1) {
      //               return str1.replace(
      //                 reg,
      //                 '<em style="background-color: yellow">' +
      //                   searchText +
      //                   "</em>"
      //               );
      //             });
      //           });
      //         }
      //       );
      //       this.main[i].innerHTML = mainInnerHTML;
      //     }
      //   }
      // }
      // // 替换配置
      // this.getSearchList();
    },
    getSearchList() {
      // em标签都是被替换的 所以em标签的数量就是搜索到关键字的数量
      const num = document.getElementsByTagName("em").length;
      this.num = num;
      if (num !== 0) {
        document.getElementsByTagName("em")[0].innerHTML =
          '<strong style="background-color: #ff9632">' +
          this.searchText +
          "</strong>";
        // 滚动到第一个关键字位置
        document.getElementsByTagName("em")[0].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
    dataScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 下一个
    next() {
      if (this.num !== 0) {
        for (let i = 0; i < document.getElementsByTagName("em").length; i++) {
          document.getElementsByTagName("em")[i].innerHTML = this.searchText;
        }
        if (this.indexNum === this.num - 1) {
          this.indexNum = 0;
        } else {
          this.indexNum = this.indexNum + 1;
        }
        document.getElementsByTagName("em")[this.indexNum].innerHTML =
          '<strong style="background-color: #ff9632">' +
          this.searchText +
          "</strong>";
        document.getElementsByTagName("em")[this.indexNum].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
    // 上一个
    prev() {
      if (this.num !== 0) {
        for (let i = 0; i < document.getElementsByTagName("em").length; i++) {
          document.getElementsByTagName("em")[i].innerHTML = this.searchText;
        }
        if (this.indexNum === 0) {
          this.indexNum = this.num - 1;
        } else {
          this.indexNum = this.indexNum - 1;
        }
        document.getElementsByTagName("em")[this.indexNum].innerHTML =
          '<strong style="background-color: #ff9632">' +
          this.searchText +
          "</strong>";
        document.getElementsByTagName("em")[this.indexNum].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-wrapper {
  position: fixed;
  bottom: 110px;
  right: 40px;
  z-index: 99;
  transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
  .input-holder {
    box-shadow: 0 0 10px #e0e0e0;
    overflow: hidden;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.3s ease-in-out;
    .total {
      font-size: 15px;
      color: #2f3f50;
      margin-top: 5px;
      border-right: 1px solid #e0e0e0;
      padding-right: 12px;
      margin-right: 5px;
    }
    .search-input {
      width: 100%;
      height: 100%;
      padding: 0px 160px 0 20px;
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-family: "Open Sans", Arial, Verdana;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #333;
      transform: translate(0, 60px);
      transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
      transition-delay: 0.3s;
    }
    .icon_box {
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 6px;
      background: #fff;
      padding: 0px;
      outline: none;
      position: relative;
      z-index: 2;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      span {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        transition: all 0.4s cubic-bezier(0.65, -0.6, 0.24, 1.65);
      }
    }
    .search-icon {
      .icon_box;
      span {
        transform: rotate(45deg);
        &::before {
          position: absolute;
          content: "";
          width: 2px;
          height: 7px;
          left: 10px;
          top: 15px;
          border-radius: 2px;
          background: #2f3f50;
        }
        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          left: 3px;
          top: 2px;
          border-radius: 16px;
          border: 3px solid #2f3f50;
        }
      }
    }
    .prev-icon {
      .icon_box;
      span {
        &::before {
          position: absolute;
          content: "";
          width: 3px;
          height: 16px;
          left: 4px;
          top: 4px;
          border-radius: 2px;
          transform: rotate(40deg);
          background: #2f3f50;
        }
        &::after {
          position: absolute;
          content: "";
          width: 3px;
          height: 16px;
          left: 13px;
          top: 4px;
          transform: rotate(-40deg);
          border-radius: 2px;
          background: #2f3f50;
        }
      }
    }
    .next-icon {
      .icon_box;
      span {
        &::before {
          position: absolute;
          content: "";
          width: 3px;
          height: 16px;
          left: 4px;
          top: 4px;
          border-radius: 2px;
          transform: rotate(135deg);
          background: #2f3f50;
        }
        &::after {
          position: absolute;
          content: "";
          width: 3px;
          height: 16px;
          left: 13px;
          top: 4px;
          transform: rotate(-135deg);
          border-radius: 2px;
          background: #2f3f50;
        }
      }
    }
  }
  &.active {
    right: 95px;
    .input-holder {
      border-radius: 50px;
      width: 400px;
      height: 50px;
      background: #ffffff;
      transition: all 0.5s cubic-bezier(0, 0.105, 0.035, 1.57);
      .search-input {
        opacity: 1;
        transform: translate(0, 0);
      }
      .search-icon {
        width: 40px;
        height: 40px;
        span {
          transform: rotate(-45deg);
        }
        &:last-child {
          margin-right: 10px;
        }
      }
      .prev-icon {
        width: 40px;
        height: 40px;
        span {
          // transform: rotate(-45deg);
        }
        &:last-child {
          margin-right: 10px;
        }
      }
      .next-icon {
        width: 40px;
        height: 40px;
        span {
          // transform: rotate(-45deg);
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
    .close {
      right: -50px;
      transform: rotate(45deg);
      transition: all 0.6s cubic-bezier(0, 0.105, 0.035, 1.57);
      transition-delay: 0.5s;
    }
  }
  .close {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 13px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all 0.3s cubic-bezier(0.285, -0.45, 0.935, 0.11);
    transition-delay: 0.2s;
    &::after {
      width: 25px;
      height: 5px;
      left: 0px;
      top: 10px;
    }
    &::before {
      width: 5px;
      height: 25px;
      left: 10px;
      top: 0px;
    }
    &::before, &::after{
      position: absolute;
      content: "";
      background: #2f3f50;
      border-radius: 2px;
    }
  }
}

@media screen and (max-width: 560px) {
  .search-wrapper.active .input-holder {
    width: 200px;
  }
}
</style>
