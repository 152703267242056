<template>
  <el-drawer
    :title="$t('createShipping.Edit')"
    :visible.sync="addShippingShow"
    width="35%"
    size="360px"
    :before-close="handleClose"
    :show-close="true"
    :orderId="orderId"
    :shippingInfo="shippingInfo"
    @open="open()"
    v-move-outside
  >
    <el-form 
      :model="form" 
      :rules="rules" 
      ref="editForm" 
      label-width="0"
      size="small"
      class="drawerForm">
      <el-form-item prop="bl">
        <div class="title">{{ $t('createShipping.No') }}</div>
        <el-input class="input_No" v-model="form.bl" clearable :placeholder="$t('createShipping.No')" />
      </el-form-item>
      <el-form-item prop="weight">
        <div class="title">{{ $t('createShipping.weight') }}</div>
        <div class="manyForm">
          <el-input
            class="weight_input"
            v-model="form.weight"
            :placeholder="$t('createShipping.weight')"
            clearable
          ></el-input>
          <el-select v-model="form.weightUnit">
            <el-option
              v-for="item in weightOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="qty">
        <div class="title">{{ $t('createShipping.Quantity') }}</div>
        <div class="manyForm">
          <el-input
            v-model="form.qty"
            :placeholder="$t('createShipping.Quantity')"
            clearable
          ></el-input>
          <el-select v-model="form.qtyUnit">
            <el-option
              v-for="item in qtyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="dimension">
        <div class="title">{{ $t('createShipping.Dimension') }}</div>
        <div class="manyForm">
          <el-input
            v-model="form.dimension"
            :placeholder="$t('createShipping.Dimension')"
            clearable
          ></el-input>
          <el-select v-model="form.dimensionUnit">
            <el-option
              v-for="item in dimensionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="pol">
        <div class="title">{{ $t('createShipping.Place of Loading') }}</div>
        <el-input
          v-model="form.pol"
          :placeholder="$t('createShipping.Place of Loading')"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="pod">
        <div class="title">{{ $t('createShipping.Place of Discharge') }}</div>
        <el-input
          v-model="form.pod"
          :placeholder="$t('createShipping.Place of Discharge')"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="destination">
        <div class="title">{{ $t('createShipping.Final Destination') }}</div>
        <el-input
          v-model="form.destination"
          :placeholder="$t('createShipping.Final Destination')"
          clearable
        ></el-input>
      </el-form-item>
      <div class="dividerTitleForm">
        {{ $t('createShipping.Freight Type') }}
      </div>
      <el-form-item>
        <el-radio-group v-model="form.shippingType">
          <el-radio label="0">{{ $t('createShipping.Ocean Shipping') }}</el-radio>
          <el-radio label="1">{{ $t('createShipping.Air Transport') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="form.shippingType === '0'">
        <el-form-item>
          <div class="title">{{ $t('createShipping.Vessel') }}</div>
          <el-autocomplete
            v-model="form.vessel"
            :fetch-suggestions="querySearchVoyage"
            @select="handleSelectVoyage"
            :trigger-on-focus="false"
            :placeholder="$t('createShipping.Vessel')"
            clearable
            style="width: 100%;"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
            ></i>
            <template slot-scope="{ item }">
              <div class="shipName">
                <div class="name_top">
                  <div class="name_left">
                    {{ item.shipName }}
                  </div>
                  <div class="name_right">
                    {{ $isFindData(shipType, String(item.shipType), 'value', 'key') }}
                  </div>
                </div>
                <div class="name_bottom">
                  <div>MMSI:{{ item.mmsi }}</div>
                </div>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('createShipping.Voyage') }}</div>
          <el-input
            v-model="form.voyage"
            clearable
            :placeholder="$t('createShipping.Voyage')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('createShipping.Container No') }}</div>
          <el-input
            v-model="form.containerNo"
            :placeholder="$t('createShipping.Container No')"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <template v-if="form.shippingType === '1'">
        <el-form-item>
          <div class="title">{{ $t('order.FlightNo') }}</div>
          <el-input
            v-model="form.flight"
            clearable
            :placeholder="$t('order.FlightNo')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('order.Flight Date') }}</div>
          <el-date-picker
            v-model="form.flightDate"
            type="date"
            :placeholder="$t('createShipping.select date')"
            style="width: 100%;"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </template>
    </el-form>
    <div class="drawerFooter">
      <el-button class="cancel_button" size="small" @click="handleClose">{{ $t('home.Cancel') }}</el-button>
      <el-button
        type="primary"
        size="small"
        @click="addShipping"
      >{{ !shippingInfo ? $t('createShipping.Add to shippment') : $t('order.Update shipping info') }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { getCompanyList, ship } from '@/api/customer';
import { createBusinessShipping } from '@/api/shipping';
import { mapGetters } from 'vuex';
export default {
  name: 'createShipping',
  data() {
    return {
      form: {
        bl: '',
        containerNo: '',
        destination: '',
        dimension: '',
        dimensionUnit: '0',
        flight: '',
        flightDate: '',
        orderId: '',
        pod: '',
        pol: '',
        qty: '',
        qtyUnit: '0',
        shippingType: '0',
        vessel: '',
        voyage: '',
        weight: '',
        weightUnit: '0',
        mmsi: '',
      },
      rules: {
        bl: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.No'),
          },
        ],
        weight: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.weight'),
          },
        ],
        qty: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.Quantity'),
          },
        ],
        dimension: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.Dimension'),
          },
        ],
        pol: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.Place of Loading'),
          },
        ],
        pod: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.Place of Discharge'),
          },
        ],
        destination: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('createShipping.Final Destination'),
          },
        ],
      },
      weightOptions: [
        { label: 'KGS', value: 'KGS' },
        { label: 'LBS', value: 'LBS' },
      ],
      qtyOptions: [
        { label: 'CARTONS', value: 'CARTONS' },
        { label: 'PACKAGES', value: 'PACKAGES' },
        { label: 'PALLETS', value: 'PALLETS' },
        { label: 'PIECES', value: 'PIECES' },
      ],
      dimensionOptions: [{ label: 'CBM', value: 'CBM' }],
      createList: null,
    };
  },
  props: ['addShippingShow', 'orderId', 'shippingInfo'],
  computed: {
    ...mapGetters(['country', 'companyId', 'shipType']),
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open() {
      if(this.shippingInfo) {
        this.form = {
          ...this.shippingInfo,
          shippingType: this.shippingInfo.shippingType || '0'
        };
      }
      this.form.orderId = this.orderId;
    },
    handleClose() {
      this.$refs.editForm.clearValidate();
      this.$emit('handleClose');
    },
    querySearchAsync(queryString, cb) {
      var data_name = {
        companyName: this.form.company,
      };
      getCompanyList(data_name)
        .then((response) => {
          if (!response.data) return;
          cb(response.data.data.operationDataList);
        })
        .catch((error) => {
          this.$message.error('querySearchAsync error');
        });
    },
    querySearchVoyage(queryString, cb) {
      // var data_name = {};
      ship({ name: queryString.replace(/\s*/g, ''), limit: 100 })
      .then((response) => {
        if (!response) return;
        console.log(response)
        cb(response);
      })
      .catch((error) => {
        this.$message.error('querySearchAsync error');
      });
    },
    handleSelectVoyage(item) {
      this.form.vessel = item.shipName;
      this.form.mmsi = item.mmsi;
    },
    addShipping() {
      this.$refs.editForm.validate((valid) => {
        if(!valid) return;
        createBusinessShipping({
          ...this.form,
          orderId: this.orderId
        })
          .then((response) => {
            if (!response.data) return;
            if (response.data.code != 200) {
              this.$message.error(response.data.msg);
              return;
            }
            this.$message.success(
              this.$t('order.createBusinessShipping succesfully')
            );
            this.$emit('afterShipping');
          })
          .finally((error) => {
            this.handleClose();
          });
      })
    }
  },
};
</script>

<style lang="less" scoped>
.shipName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  .name_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name_left {
      font-size: 14px;
      font-weight: 500;
      color: #122545;
    }
    .name_right {
      font-size: 14px;
      color: #637381;
    }
  }
  .name_bottom {
    display: flex;
    margin-top: 6px;
    font-size: 12px;
    color: #637381;
  }
}
</style>
