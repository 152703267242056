<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <div class="flexTime" v-if="!readonly">
      <el-date-picker
        v-if="formType == '0'"
        v-model="inputValue[0]"
        size="small"
        type="date"
        :disabled="disabled"
        :placeholder="$t('el.datepicker.startDate')" 
        format="yyyy/MM/dd"
        value-format="yyyy/MM/dd">
      </el-date-picker>
      <el-date-picker
        v-else
        v-model="inputValue"
        size="small"
        type="daterange"
        :disabled="disabled"
        :start-placeholder="$t('el.datepicker.startDate')"
        :end-placeholder="$t('el.datepicker.endDate')"
        format="yyyy/MM/dd"
        value-format="yyyy/MM/dd">
      </el-date-picker>
    </div>
    <div class="customValueText" v-else>
      {{ inputValue[0] }} {{ inputValue[1] ? '-' : '' }} {{ inputValue[1] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "timeSelect",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: "",
        customOptions: {
          type: "0"
        }
      })
    }
  },
  data() {
    return {
      inputValue: [],
      formType: ""
    }
  },
  watch: {
    inputValue(val) {
      this.$emit("input", val);
    },
    'form.customOptions': {
      handler(val) {
        if(!val) { 
          this.formType = '0'
        } else {
          this.formType = val.type;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.inputValue = this.value;
    if(!this.form.customOptions) { 
      this.formType = '0'
    } else {
      this.formType = this.form.customOptions.type;
    }
  }
}
</script>

<style lang="less" scoped>
.flexTime {
  display: flex;
  align-items: center;
  gap: 8px;
  /deep/ .el-date-editor {
    flex: 1;
    min-width: 0;
    width: auto;
  }
  .line {
    width: 8px;
    height: 1px;
    background: #A3B0C6;
  }
}
</style>