<template>
  <el-drawer
    :visible.sync="show"
    :title="$t('event.Event detail')"
    size="364px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
  >
    <div class="detailBox" v-if="!item.keyEvent">
      <div class="title line2">{{ item.subject }}</div>
      <div class="timeBox">
        <div class="left">
          <member v-if="!item.keyEvent" :info="item.createPerson" :size="24" />
          {{ $dayjs(item.createTime).format("YYYY/MM/DD HH:mm:ss") }}
        </div>
        <div class="right">
          <i class="iconfont" :class="item.collect ? 'icon-yishoucang' : 'icon-shoucang'" @click="followEventChange"></i>
          <i class="iconfont icon-a-fanyi1" @click="translateText"></i>
        </div>
      </div>
      <div class="contentText" v-html="item.content" @click="$replayImgShow($event)"></div>
      <el-divider v-if="textTranslation" />
      <div class="contentText" v-if="textTranslation" v-html="textTranslation" @click="$replayImgShow($event)"></div>
      <fileBlocks
        v-if="item.eventFileUrl"
        :embedUrl="item.eventFileUrl"
        :embedId="item.eventFileId"
        :ci="orderInfo && orderInfo.ci"
        background=""
      />
      <div class="commentBtn" @click="addComment">
        <i class="iconfont icon-lianxiguanliyuan"></i>
        {{ $t('order.Reply') }}
      </div>
      <div class="commentDetailBox" v-if="item.commentList.length">
        <div
          v-for="(comment, i) in item.commentList"
          :key="i"
          class="item"
        >
          <member v-if="comment.createPerson" :info="comment && comment.createPerson" :size="24"></member>
          <div class="commentBox">
            <div class="commentTextContent">
              <div class="left">
                <div class="commentName">
                  {{ comment.createPerson.nickName }}
                </div>
                <div class="commentTime">
                  {{ $dayjs(comment.createTime).format("MM-DD HH:mm") }}
                </div>
              </div>
              <i class="iconfont icon-lianxiguanliyuan" @click="addAiteComment(comment)"></i>
            </div>
            <div class="html_reply_box">
              <div class="html_reply">
                <div v-if="!comment.edit">
                  <conversation-bubble
                    :key="comment.commentId"
                    background="#ffffff"
                    :item="{
                      msg: comment.content,
                      strId: comment.commentId,
                    }"
                  >
                    <template #call>
                      <span
                        v-if="comment.parentCommentId"
                        style="
                          color: rgba(55, 120, 173, 1);
                          font-size: 14px;
                        "
                        >@{{
                          onSearch(
                            item.commentList,
                            comment.parentCommentId,
                            item.createPerson
                          )
                        }}
                      </span>
                    </template>
                  </conversation-bubble>
                </div>
                <div class="commentInput" v-else>
                  <quill-editor
                    v-model="comment.content"
                    :options="editorOption"
                    class="replyContent"
                  />
                  <div class="btn">
                    <el-button 
                      class="cancel_button" 
                      size="small" 
                      @click="commentCancel(i)"
                    >{{ $t("Cancel") }}</el-button>
                    <el-button 
                      type="primary" 
                      size="small" 
                      :disabled="!comment.content"
                      @click="create_Comment(comment)"
                    >{{ $t("InformationCenter.Send") }}</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tagBox">
        <div class="title">{{ $t('order.Event tag') }}</div>
        <div class="tagList">
          <el-popover 
            v-for="(row, index) in item.companyTagList" 
            :key="index"
            placement="top-start"
            trigger="hover"
            :open-delay="700"
            popper-class="eventTagUserPopover"
            :disabled="!row.openUserDTO">
            <div class="userTagBox" v-if="row.openUserDTO">
              <el-image :src="row.openUserDTO.avatarFileUrl || row.openUserDTO.avatarUrl" />
              <div class="content">
                <div class="title">
                  {{ $t('order.form') }}
                  <span>{{ row.openUserDTO.nickName }}</span>
                  {{ $t('home.Create') }}
                </div>
                <div class="time">{{ row.createTime }}</div>
              </div>
            </div>
            <div 
              slot="reference"
              class="tagItem" 
              :style="{
                'background': row.color
              }">
              {{ row.content }}
              <i 
                v-if="(row.openUserDTO && row.openUserDTO.openId) == businessUserId" 
                class="el-icon-close"
                @click.stop="eventTagChange(row)"
              ></i>
            </div>
          </el-popover>
          
          <el-popover
            ref="popover"
            placement="right-start"
            trigger="click"
            :append-to-body="false"
            popper-class="orderPopover"
            v-if="orderInfo.companyTagList.length">
            <ul class="user_popover">
              <li 
                class="tagItem" 
                v-for="(row, index) in orderInfo.companyTagList" 
                :key="index"
                @click="eventTagChange(row)">
                <el-tag size="small" :color="row.color" class="eventTags">{{ row.content }}</el-tag>
                <span class="gouhao" v-if="item.companyTagList && item.companyTagList.map(tag => tag.content).includes(row.content)">✅</span>
              </li>
            </ul>
            <div 
              slot="reference"
              class="tagHideNumber" 
            ><i class="el-icon-plus"></i></div>
          </el-popover>
        </div>
      </div>
      <div class="tagBox">
        <div class="title">{{ $t('todo.Notes') }}</div>
        <el-input 
          v-model="item.remark" 
          type="textarea" 
          :placeholder="$t('Please enter the note content')"
          @blur="remarkChange"
        ></el-input>
      </div>
    </div>
    <template v-else>
      <div class="detailBox">
        <div class="title line2">{{ systemName }}</div>
        <div class="timeBox">
          <div class="left">
            <i class="iconfont avatar" :class="iconComputed"></i>
            {{ $dayjs(item.createTime).format("YYYY/MM/DD HH:mm:ss") }}
          </div>
        </div>
        <div class="contentText">
          <span class="name" v-if="item.contentJson.type != 4">{{ item.createPerson.nickName }}</span>
          <span class="name" v-else>{{ $t('memberList.manage') }}</span>
          <span class="behavior">{{ behaviorComputed }}</span>
          <span class="title">{{ titleTypeComputed }}</span>
        </div>
        <div class="clockInContent" v-if="item.contentJson.type == 3">
          <div class="text" v-if="computedNodeList(item.contentJson.oldProgress)">
            {{ $t('order.orderStatusForm') }}
            {{ computedNodeList(item.contentJson.oldProgress).name }}
            {{ $t('order.change to') }}
            <span>{{ computedNodeList(item.contentJson.oldProgress).name }}</span>
          </div>
          <fileBlocks
            v-for="(row, index) in item.contentJson.urlList"
            :key="index"
            :embedUrl="row.savePath"
            :embedId="row.fileId"
            :ci="orderInfo && orderInfo.ci"
          >
          </fileBlocks>
        </div>
        <div class="changeContent" v-if="item.contentJson.type == 1">
          <div v-if="item.contentJson.status == 2">
            <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index">
              <span>{{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}</span>
              {{ $t('order.form') }}
              {{ fieldValueComputed(row) }}
              {{ $t('order.change to') }}
              <span>{{ fieldValue1Computed(row) }}</span>
            </div>
          </div>
          <div class="changeBox" v-if="item.contentJson.status == 1 || detailOpen">
            <div class="item" v-for="(row, index) in $store.state.user.orderAlias == 0 ? item.contentJson.data.filter((tt, ii) => ii < 6) : item.contentJson.data.filter(item => item.fieldName != 'CI号' && item.fieldName != 'CI number') " :key="index">
              <span>
                {{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}
                <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
              </span>
              <span>{{ fieldValue1Computed(row) }}</span>
            </div>
          </div>
          <fileBlocks 
            v-if="item.eventFileUrl"
            style="margin: 10px 0 5px 0;"
            :embedUrl="item.eventFileUrl"
            :embedId="item.eventFileId"
            :ci="orderInfo && orderInfo.ci"
          />
        </div>
        <div class="shipContent" ref="shipContent" v-if="item.contentJson.type == 2">
          <div v-if="item.contentJson.status == 2">
            <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index+'two'">
              <span>{{ row.fieldName }}</span>
              {{ $t('order.form') }}
              {{ shipFieldValueComputed(row) }}
              {{ $t('order.change to') }}
              <span>{{ shipFieldValue1Computed(row) }}</span>
            </div>
          </div>
          <div class="shipBox" v-if="item.contentJson.status == 1">
            <div class="item" v-for="(row, index) in item.contentJson.data" :key="index">
              <span>
                {{ row.fieldName }}
                <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
              </span>
              <span>
                {{ shipFieldValue1Computed(row).split(" ")[0] }}
                <span v-if="shipFieldValue1Computed(row).split(' ')[1]">
                  {{ shipFieldValue1Computed(row).split(' ')[1] }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="drawerFooter" v-if="!item.keyEvent">
      <el-button 
        class="cancel_button" 
        size="small" 
        icon="iconfont icon-shanchu"
        v-if="businessUserId == item.createPerson.openId"
        @click="deleteEvent"
      ></el-button>
      <el-button 
        type="primary" 
        size="small" 
        @click="copyEvent">{{
        $t("Files.Copy Link")
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { createComment } from "@/api/comment";
import { translateMessage } from "@/api/message";
import {
  deleteEventTag,
  addEventTag,
  notesEvent,
  followEvent
} from "@/api/event";
export default {
  props: {
    item: {
      type: Object | null,
      default: null
    },
    orderInfo: {
      type: Object | null,
      default: null
    },
    shippingInfo: {
      type: Object | null,
      default: null
    },
    isCeoAcount: {
      type: Boolean | Object,
      default: true
    },
  },
  data() {
    return {
      show: false,
      textTranslation: "",
      editorOption: {
        placeholder: this.$t("order.Write away"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
            ],
            [
              {
                color: [],
              },
            ],
            [
              {
                font: ["SimSun", "FangSong"],
              },
            ],
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(["businessUserId", "orderProgress", "country", "userInfo", "allNodeList"]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    systemName() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) return `${this.$isOrderTitle()}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 2) return `${this.$t("home.waybill")}：${this.shippingInfo.bl}`
        if(content.type == 3) return `${this.$t("home.clock in")}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 4) return this.$tc('order.Order node change', this.$isOrderTitle())
      }
    },
    iconComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return "icon-dingdan1";
        } else if(content.type == 2) {
          return "icon-yundan";
        } else if(content.type == 3) {
          return "icon-daka";
        } else {
          return "icon-shuaxin";
        }
      }
    },
    behaviorComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.$isOrderTitle());
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.$isOrderTitle());
        } else {
          return this.$tc("order.Order node", this.$isOrderTitle());
        }
      }
    },
    fieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue;
        }
      }
    },
    fieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue1 || row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue1 || row.fieldValue;
        }
      }
    },
    shipFieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue1 == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue1 || row.fieldValue || "";
        }
      }
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    handleClose() {
      this.show = false;
      this.item.commentList = this.item.commentList.filter(item => item.commentId);
    },
    async translateText() {
      if (this.textTranslation) {
        this.textTranslation = "";
        return;
      }
      const data = await translateMessage({
        text: this.item.content
      });
      this.textTranslation = data;
    },
    remarkChange() {
      if(!this.item.remark) return;
      notesEvent({
        eventId: this.item.eventId,
        content: this.item.remark
      });
    },
    followEventChange() {
      followEvent(this.item.eventId).then(() => {
        this.$message.success(
          (!this.item.collect ? this.$t('home.Follow') : this.$t('home.Unfollow'))+this.$t('success')
        );
        this.item.collect = !this.item.collect;
      }).finally(() => {})
    },
    eventTagChange(row) {
      if(this.item.companyTagList && this.item.companyTagList.map(tag => tag.content).includes(row.content)) {
        let indexId = this.item.companyTagList.map(tag => tag.content).indexOf(row.content);
        let rowItem = this.item.companyTagList.filter((tag, index) => index == indexId)[0];
        if(this.businessUserId == rowItem.openUserDTO.openId) {
          this.$confirm(
            this.$t("order.Do you want to cancel this label"),
            this.$t("Tips"),
            {
              confirmButtonText: this.$t("order.Confirms"),
              cancelButtonText: this.$t("order.Cancel"),
              type: "warning",
            }
          ).then(() => {
            deleteEventTag({
              eventId: this.item.eventId,
              tagId: row.tagId
            }).then(() => {
              rowItem.createPerson = null;
              this.item.companyTagList.splice(indexId, 1);
            })
          }).catch(() => {});
        }
      } else {
        addEventTag({
          eventId: this.item.eventId,
          tagId: row.tagId
        }).then(() => {
          if(this.item.companyTagList == null) {
            this.item.companyTagList = [];
          }
          let timeZone = Number(this.userInfo.timeZone.split('GMT')[1]);
          this.item.companyTagList.push({
            ...row,
            openUserDTO: {
              ...this.userInfo
            },
            createTime: this.$dayjs(this.getTimeZooe(timeZone)).format("YYYY-MM-DD HH:mm:ss"),
            createPerson: this.businessUserId
          });
        })
      }
    },
    getTimeZooe(data){
      let time = new Date(); //获取时间
      // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
      let dataOffset = new Date().getTimezoneOffset()*60000
      // 获取格林威治时间 
      let utc = time.getTime()+dataOffset;  // 两个时间戳
      // 拿格林威治时间去反推指定地区时间    
      let newTime = utc+3600000*data;  // 
      let times = new Date(newTime);
      return times
    },
    addComment() {
      let isNo = this.item.commentList.find((item) => item.commentId == "");
      if(isNo) return;
      this.item.commentList.push({
        content: "",
        eventId: this.item.eventId,
        parentCommentId: null,
        commentId: "",
        edit: true,
        createPerson: this.userInfo,
        createTime: this.$dayjs().format("MM-DD HH:mm")
      })
    },
    addAiteComment(comment) {
      let isNo = this.item.commentList.find((item) => item.commentId == "");
      if(isNo) return;
      this.item.commentList.push({
        content: "",
        eventId: this.item.eventId,
        parentCommentId: comment.commentId,
        commentId: "",
        edit: true,
        createPerson: this.userInfo,
        createTime: this.$dayjs().format("MM-DD HH:mm")
      })
    },
    commentCancel(index) {
      this.item.commentList.splice(index, 1);
    },
    create_Comment(comment) {
      var data_comment = {
        content: comment.content,
        eventId: comment.eventId,
        parentCommentId: comment?.parentCommentId,
      };
      createComment(data_comment).then((response) => {
        if (!response) return;
        comment = response;
      });
    },
    deleteEvent() {
      this.$emit("openDeleteBox");
    },
    copyEvent() {
      this.$emit("copyEvent");
    },
    onSearch(obj, parentCommentId, itemVo) {
      let item = obj.find((res) => {
        return res.commentId == parentCommentId;
      });
      return item && item.createPerson.nickName
        ? item && item.createPerson.nickName
        : itemVo.nickName;
    },
  }
}
</script>

<style lang="less" scoped>
.detailBox {
  padding: 20px;
  height: calc(100% - 64px);
  overflow: overlay;
  /deep/ .el-divider {
    margin: 10px 0;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #122545;
    margin-bottom: 12px;
  }
  .timeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #637381;
      font-size: 12px;
      .avatar {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #076F49;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    i {
      cursor: pointer;
      color: #637381;
    }
    .icon-yishoucang {
      color: #DF8F31;
    }
  }
  .contentText {
    font-size: 14px;
    color: #637381;
    /deep/ a {
      color: #076F49;
      text-decoration: underline;
    }
    /deep/ img {
      width: 100%;
      margin-top: 5px;
      border-radius: 4px;
    }
    .name {
      font-size: 14px;
      color: #122545;
      font-weight: 500;
      margin-right: 4px;
    }
    .behavior {
      font-size: 14px;
      color: #637381;
      margin-right: 4px;
    }
    .title {
      font-size: 14px;
      color: #076F49;
      font-weight: 500;
    }
  }
  .commentBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    margin-top: 8px;
    font-size: 12px;
    line-height: 24px;
    color: #122545;
    cursor: pointer;
    i {
      font-size: 13px;
      color: #637381;
    }
  }
  .commentDetailBox {
    width: 100%;
    padding: 20px;
    background: #F7F9FC;
    border-radius: 4px;
    margin-top: 8px;
    .item {
      display: flex;
      margin-bottom: 9px;
      position: relative;
      scroll-margin: 200px;
      &:last-child {
        margin-bottom: 0;
      }
      .commentBox {
        margin-top: 4px;
        flex: 1;
        .commentTextContent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 0px;
          padding-left: 5px;
          width: 100%;
          .left {
            display: flex;
            align-items: center;
            .commentName {
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              color: #122545;
              margin-right: 8px;
            }
            .commentTime {
              font-size: 12px;
              line-height: 17px;
              color: #757D8A;
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          .icon-lianxiguanliyuan {
            font-size: 12px;
            cursor: pointer;
            color: #637381;
          }
        }
      }

      .name_reply {
        // margin: 8px 0 0 25px;
        // padding: 3px 3px;
        width: 27px;
        height: 27px;
        font-size: 12px;
        font-weight: 700;
        background: rgb(246, 248, 252);
        background: #999;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 27px;
          height: 27px;
        }
      }

      .html_reply_box {
        display: flex;
        margin-top: 4px;
        .html_reply {
          margin: 0px 0px 0px 6px;
          font-size: 12px;
          border-radius: 0 4px 4px 4px;
        }
        .commentInput {
          border: 1px solid #076F49;
          background: #ffffff;
          border-radius: 4px;
          width: 252px;
          .btn {
            display: flex;
            justify-content: flex-end;
            padding: 0 8px 8px 8px;
            .el-button--primary {
              background: #076F49;
              border-color: #076F49;
            }
          }
        }
      }
      .moreBox {
        opacity: 0;
        width: 20px;
        height: 20px;
        &.active {
          opacity: 1;
        }
      }
      /deep/.commentChidlrenBox {
        transition: all .3s;
        .icon-a-fanyi1 {
          opacity: 0;
        }
      }
      &:hover {
        .moreBox {
          opacity: 1;
        }
        /deep/.commentChidlrenBox {
          .icon-a-fanyi1 {
            opacity: 1;
          }
        }
      }
    }
  }
  .tagBox {
    margin-top: 40px;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #122545;
      line-height: 19.6px;
    }
    .tagList {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .tagItem {
        border-radius: 20px;
        line-height: 22px;
        padding: 4px 14px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
      .tagHideNumber {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;
        color: #076F49;
        border: 1px dashed #67A691;
        cursor: pointer;
      }
    }
  }
  .clockInContent {
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 4px;
    .text {
      font-size: 14px;
      line-height: 16px;
      color: #637381;
      span {
        color: #076F49;
      }
    }
  }
  .changeContent {
    margin-left: 3px;
    .itemTwo {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .changeBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 14px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .shipContent {
    .itemTwo {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .shipBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 14px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              font-size: 12px;
              color: #637381;
            }
          }
        }
      }
    }
  }
}
.user_popover {
  li {
    padding: 1px 7px;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    color: #122545;
    display: flex;
    align-items: center;
    &.active {
      background: #122545;
      color: #ffffff;
    }

    i {
      margin-right: 6px;
    }

    .icon-a-Polygon3 {
      font-size: 12px;
      transform: scale(.4) rotate(90deg);
      position: relative;
      right: -15px;
    }

    &:hover {
      background: #122545;
      color: #ffffff;
    }

    &.tagItem {
      padding: 4px 7px;
      border-radius: 0 !important;
      display: flex;
      align-items: center;
      gap: 5px;
      &:hover {
        background: #F7F9FC;
      }
    }
  }

  .line {
    height: 1px;
    width: 88%;
    margin: 5px auto;
    background-color: #ccc;
  }
}
.userTagBox {
  display: flex;
  align-items: center;
  gap: 10px;
  .el-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      color: #637381;
      span {
        color: #076F49;
      }
    }
    .time {
      font-size: 12px;
      color: #757D8A;
    }
  }
}
</style>