<template>
  <el-drawer
    :visible.sync="show"
    :title="$tc('order.Custom Project Information', this.$isOrderTitle())"
    size="640px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
  >
    <el-form
      :model="customform"
      :rules="rules"
      ref="createCustomForm"
      label-width="0"
      size="small"
      class="drawerForm"
      :class="customTemplateForm.length && mode != 'see' ? 'separate' : ''"
    >
      <draggable
        v-model="customform.customFormInfo"
        animation="200"
        ghostClass="ghost"
        group="people"
        handle=".icon-yidong"
      >
        <transition-group class="Statistical_cart_box">
          <div v-for="(item, index) in customform.customFormInfo" :key="index">
            <keep-alive>
              <el-form-item>
                <i class="iconfont icon-shanchu shanchuIcon" v-if="mode == 'edit'" @click="deleteComponents(index)"></i>
                <i class="iconfont icon-yidong" v-if="mode == 'edit'"></i>
                <component
                  v-model="item.customValue" 
                  :readonly="mode == 'see'" 
                  :disabled="mode == 'preview'"
                  :is="componentsDict[item.customType]" 
                  :form="item" 
                />
              </el-form-item>
            </keep-alive>
          </div>
        </transition-group>
      </draggable>
      
      <el-button 
        style="width: 100%" 
        class="addCustomBtn"
        icon="el-icon-plus"
        v-if="customform.customFormInfo.length < 20 && mode == 'edit'"
        @click="addCustomForm"
      >{{ $t('order.Click to add components') }}</el-button>
    </el-form>
    <el-form v-if="customTemplateForm.length && mode != 'see'" class="drawerForm huise">
      <el-form-item>
        <div class="title">{{ $t('order.Select Template') }}</div>
        <chat-select 
          v-model="templateValue" 
          :placeholder="$t('order.Select Template')"
          @change="tabTemplate">
          <chat-option
            v-for="item in customTemplateForm"
            :key="item.customTemplateId"
            :label="item.customName"
            :value="item.customTemplateId">
            <div class="templateNameBox">
              {{ item.customName }}
              <div class="previewLink" @click.stop="previewTemplate(item.customTemplateId)">
                <i class="iconfont icon-xianshi"></i>
                {{ $t('Preview') }}
              </div>
            </div>
          </chat-option>
        </chat-select>
      </el-form-item>
    </el-form>
    <div class="drawerFooter">
      <div class="left">
        <el-button
          class="cancel_button" 
          size="small" 
          icon="el-icon-time"
          @click="seeRecords"
        >{{ $t('Operation records') }}</el-button>
      </div>
      <div class="right">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="handleClose"
        >{{ $t("Cancel") }}</el-button>
        <el-button 
          size="small" 
          @click="setTemplate"
          v-if="mode == 'edit'"
        >{{ $t("order.Set as Template") }}</el-button>
        <el-button 
          type="primary" 
          size="small" 
          :loading="buttonLoading"
          @click="createCustomForm">{{
          mode == 'edit' ? $t("save") : $t('home.Edit')
        }}</el-button>
      </div>
    </div>
    <addCustomForm ref="addCustomForm" @add="receiveCustomData" />
    <el-dialog 
      :visible.sync="dialogOperationVisible"
      :title="$t('Operation records')"
      :before-close="() => dialogOperationVisible = false"
      width="700px"
      append-to-body
      v-move-outside>
      <el-table
        :data="operationList"
        style="width: 100%"
        :show-header="false">
        <el-table-column
          prop="createUser"
          width="120">
          <template slot-scope="{row}">
            <div class="customerName" v-if="row.createUser">
              <member :info="row.createUser" :size="28"></member>
              <span>{{ row.createUser.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          width="500px">
          <template slot-scope="{row}">
            <div class="operationContent">
              {{ row.type == 0 ? $t('Create') : (row.type == 1 ? $t('home.Delete') : $t('home.Edit'))  }}
              <div class="operationDetail">
                <p class="line1" v-showTootip>{{ row.type == 0 ? row.newValue : row.oldValue }}</p>
                <span v-if="row.type == 2">{{ $t('do') }}</span>
                <p class="line1" v-if="row.type == 2" v-showTootip>{{ row.newValue }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('Files.CreateTime')"
          width="180"
          fixed="right">
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { 
  getCustomTemplateFormPage, 
  updateCustomFormInfo,
  setCustomFormToTemplate,
  getCustomTemplateDetail,
  getCustomFormInfoOperation,
  isTemplateNameRepeat
} from "@/api/order.js";

import inputText from '../customFormConfig/component/inputText.vue';
import radioSelect from '../customFormConfig/component/radioSelect.vue';
import checkSelect from '../customFormConfig/component/checkSelect.vue';
import timeSelect from "../customFormConfig/component/timeSelect.vue";
import dateSelect from "../customFormConfig/component/dateSelect.vue";
import inputNumber from "../customFormConfig/component/inputNumber.vue";
import fileUpload from "../customFormConfig/component/fileUpload.vue";
import imageUpload from '../customFormConfig/component/imageUpload.vue';
import textareaText from "../customFormConfig/component/textareaText.vue";
import switchSelect from "../customFormConfig/component/switchSelect.vue";
import rateSelect from "../customFormConfig/component/rateSelect.vue";

import addCustomForm from '@/components/addCustomForm';
import { log } from 'console';

export default {
  name: "createEvent",
  components: {
    inputText,
    radioSelect,
    checkSelect,
    timeSelect,
    dateSelect,
    inputNumber,
    fileUpload,
    imageUpload,
    textareaText,
    switchSelect,
    rateSelect,
    addCustomForm
  },
  data() {
    return {
      mode: "edit",
      show: false,
      dialogOperationVisible: false,
      buttonLoading: false,
      customTemplateForm: [],
      operationList: [],
      templateValue: "",
      customform: {
        customFormInfo: [],
      },
      componentsDict: {
        '0': 'inputText',
        '1': 'radioSelect',
        '2': 'checkSelect',
        '3': 'timeSelect',
        '4': 'dateSelect',
        '5': 'inputNumber',
        '6': 'fileUpload',
        '7': 'imageUpload',
        '8': 'textareaText',
        '9': 'switchSelect',
        '10': 'rateSelect'
      },
      rules: {},
    };
  },
  methods: {
    open(customFormInfo, mode = 'edit') {
      this.mode = mode;
      this.customform.customFormInfo = JSON.parse(JSON.stringify(customFormInfo));
      this.show = true;
      this.getCustomTemplateFormPage();
    },
    getCustomTemplateFormPage() {
      getCustomTemplateFormPage({
        page: 1,
        size: 10000
      }).then((data) => {
        this.customTemplateForm = data.list;
      });
    },
    handleClose() {
      this.customform.customFormInfo = [];
      this.templateValue = "";
      this.show = false;
    },
    previewTemplate(id) {
      this.mode = "preview"
      getCustomTemplateDetail(this.customTemplateForm.find(item => item.customTemplateId == id).customTemplateId).then((data) => {
        this.customform.customFormInfo = data.customList.map((item) => ({
          ...item,
          customOptions: item.customOptions ? JSON.parse(item.customOptions) : null,
          customValue: item.customType == '2' || item.customType == '3' || item.customType == '4' ? item.customValue.split(",") : (item.customType == '10' ? Number(item.customValue) : (item.customType == '9' ? JSON.parse(item.customValue) : item.customValue))
        }));
      });
    },
    tabTemplate(value) {
      if(this.customform.customFormInfo.length) {
        this.$confirm(
          this.$t("order.The data will be replaced after use"),
          this.$t('order.Are you sure to switch to the new template'),
          {
            confirmButtonText: this.$t("Confirm"),
            cancelButtonText: this.$t("Cancel"),
            type: "warning",
          }
        ).then(() => {
          this.mode = "edit";
          getCustomTemplateDetail(this.customTemplateForm.find(item => item.customTemplateId == value).customTemplateId).then((data) => {
            this.customform.customFormInfo = data.customList.map((item) => ({
              ...item,
              customOptions: item.customOptions ? JSON.parse(item.customOptions) : "",
              customValue: item.customType == '2' || item.customType == '3' || item.customType == '4' ? item.customValue.split(",") : (item.customType == '10' ? Number(item.customValue) : (item.customType == '9' ? JSON.parse(item.customValue) : item.customValue))
            }));
          });
        }).catch(() => {
          this.templateValue = "";
        });
        return;
      }
      getCustomTemplateDetail(this.customTemplateForm.find(item => item.customTemplateId == value).customTemplateId).then((data) => {
        this.customform.customFormInfo = data.customList.map((item) => ({
          ...item,
          customOptions: item.customOptions ? JSON.parse(item.customOptions) : "",
          customValue: item.customType == '2' || item.customType == '3' || item.customType == '4' ? item.customValue.split(",") : (item.customType == '10' ? Number(item.customValue) : (item.customType == '9' ? JSON.parse(item.customValue) : item.customValue))
        }));
      });
    },
    addCustomForm() {
      this.$refs.addCustomForm.open(this.customform.customFormInfo);
    },
    receiveCustomData(form) {
      this.customform.customFormInfo.push(form);
    },
    deleteComponents(index) {
      this.$confirm(
        this.$t("order.Unrecoverable after deletion"),
        this.$t('order.Are you sure to delete this component'),
        {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.customform.customFormInfo.splice(index, 1);
      }).catch(() => {});
    },
    seeRecords() {
      this.dialogOperationVisible = true;
      getCustomFormInfoOperation({
        page: 1,
        size: 10000,
        orderId: this.$route.query.orderId
      }).then((data) => {
        this.operationList = data.list;
      })
    },
    setTemplate() {
      if(!this.customform.customFormInfo.length) {
        this.$message.warning(this.$t('Please add components first'));
        return;
      }
      this.$prompt(this.$t('order.Set Template Name'), this.$t('Tips'), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancel"),
        inputPattern: /\S/,
        inputValidator: (value) => {
          if(value.length > 50) {
            return this.$t('Limit input to 50 words');
          } else {
            return true;
          }
        },
        inputErrorMessage: this.$t('order.Please input title'),
        inputPlaceholder: this.$t('order.Please input title')
      }).then(({ value }) => {
        isTemplateNameRepeat(value).then((data) => {
          if(data) {
            this.$confirm(this.$t('After covering, the original template will be replaced'), this.$t('Do you want to overwrite the previous template'), {
              confirmButtonText: this.$t('Confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning'
            }).then(() => {
              setCustomFormToTemplate({
                customName: value,
                customSort: 1,
                customList: this.customform.customFormInfo.map((item, index) => ({
                  ...item,
                  customSort: index+1,
                  customOptions: item?.customOptions ? JSON.stringify(item?.customOptions) : "",
                  customValue: Array.isArray(item.customValue) ? item.customValue.join(",") : item.customValue
                }))
              }).then(() => {
                this.$message.success(this.$t('Successfully saved'));
                this.getCustomTemplateFormPage();
              })
            }).catch(() => {});
          } else {
            setCustomFormToTemplate({
              customName: value,
              customSort: 1,
              customList: this.customform.customFormInfo.map((item, index) => ({
                ...item,
                customSort: index+1,
                customOptions: item?.customOptions ? JSON.stringify(item?.customOptions) : "",
                customValue: Array.isArray(item.customValue) ? item.customValue.join(",") : item.customValue
              }))
            }).then(() => {
              this.$message.success(this.$t('Successfully saved'));
              this.getCustomTemplateFormPage();
            })
          }
        });
      }).catch(() => {});
    },
    createCustomForm() {
      if(this.mode == 'see' || this.mode == 'preview') {
        this.mode = 'edit';
        return;
      }
      this.$refs.createCustomForm.validate((vaild) => {
        if (!vaild) return;
        this.buttonLoading = true;
        updateCustomFormInfo({
          orderId: this.$route.query.orderId,
          customList: this.customform.customFormInfo.map((item, index) => ({
            ...item,
            customOptions: item?.customOptions ? JSON.stringify(item?.customOptions) : "",
            customSort: index+1,
            customValue: Array.isArray(item.customValue) ? item.customValue.join(",") : item.customValue
          }))
        }).then(() => {
          this.$message.success(this.$t('Successfully saved'));
          this.$emit("update");
          this.handleClose();
        }).finally(() => this.buttonLoading = false)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawerForm {
  /deep/ .el-form-item__content {
    .title {
      padding-right: 20px;
    }
  }
  &.separate {
    height: calc(100% - 200px);
  }
  &.huise {
    height: 140px;
    background: #F7F9FC;
  }
}
.drawerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operationContent {
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 14px;
  color: #637381;
  .operationDetail {
    display: flex;
    align-items: center;
    gap: 15px;
    p {
      width: 110px;
      color: #076F49;
      font-weight: 600;
    }
  }
}
.addCustomBtn {
  border: 1px dashed #A3B0C6;
  background: #F7F9FC;
  &:hover {
    border: 1px dashed #076F49;
    color: #076F49;
  }
}
.templateNameBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #122545;
  .previewLink {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #004C97;
    font-size: 12px;
    i {
      font-size: 12px;
    }
  }
}
</style>
