<template>
  <layout>
    <template #config>
      <el-form 
        ref="ruleForm" 
        label-width="0"
        size="small"
        :model="form" 
        :rules="rules"
        class="drawerForm">
        <el-form-item prop="customName">
          <div class="title">{{ $t('xiaoxie.Title') }}</div>
          <el-input
            v-model="form.customName"
            clearable
            maxlength="50"
            show-word-limit
            :placeholder="$t('xiaoxie.Title')"
          />
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('Time type') }}</div>
          <div class="btnGroupBox">
            <div 
              class="item" 
              :class="form.customOptions.type == item.value ? 'active' :''"
              v-for="(item, index) in typeOptions" 
              :key="index"
              @click="form.customOptions.type = item.value">
              {{ item.label }}
              <img v-if="form.customOptions.type == item.value" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/yibancheck.png" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </template>
    <template #component>
      <previewComponent v-model="form.customValue" :form="form" />
    </template>
  </layout>
</template>

<script>
import previewComponent from '../component/dateSelect.vue';
import layout from "./components/layout.vue";
export default {
  name: "timeSelect",
  components: {
    layout,
    previewComponent
  },
  data() {
    return {
      form: {
        customType: "4",
        customName: "",
        customTips: "",
        customValue: [],
        customOptions: {
          type: "0"
        },
      },
      rules: {
        customName: [{ 
          required: true, message: this.$tc('Please input', this.$t('xiaoxie.Title')), trigger: 'blur' 
        }]
      },
      typeOptions: [{
        label: this.$t('Point of time'),
        value: 0
      }, {
        label: this.$t('Time slot'),
        value: 1
      }]
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("emitForm", val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0px;
}
.btnGroupBox {
  display: flex;
  gap: 8px;
  .item {
    flex: 1;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 2px;
    font-size: 13px;
    color: #637381;
    border: 1px solid #E0E4EA;
    cursor: pointer;
    position: relative;
    i {
      font-size: 18px;
      line-height: 1;
    }
    img {
      position: absolute;
      width: 24px;
      right: 0;
      bottom: 0;
    }
    &.active {
      border: 1px solid #004C97;
      color: #004C97;
    }
  }
}
</style>