var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.form.customName || _vm.$t('xiaoxie.Title')))]),(!_vm.readonly)?_c('div',{staticClass:"flexTime"},[_c('el-time-select',{attrs:{"size":"small","disabled":_vm.disabled,"picker-options":{
        start: '00:00',
        step: _vm.form.customOptions && _vm.form.customOptions.interval,
        end: '23:59'
      },"placeholder":_vm.$t('el.datepicker.startTime')},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),(_vm.form.customOptions && _vm.form.customOptions.type == '1')?_c('div',{staticClass:"line"}):_vm._e(),(_vm.form.customOptions && _vm.form.customOptions.type == '1')?_c('el-time-select',{attrs:{"size":"small","disabled":_vm.disabled,"picker-options":{
        start: '00:00',
        step: _vm.form.customOptions && _vm.form.customOptions.interval,
        end: '23:59',
        minTime: _vm.startTime
      },"placeholder":_vm.$t('el.datepicker.endTime')},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}):_vm._e()],1):_c('div',{staticClass:"customValueText"},[_vm._v(" "+_vm._s(_vm.startTime)+" "+_vm._s(_vm.endTime ? '-' : '')+" "+_vm._s(_vm.endTime)+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }