<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <el-switch
      v-if="!readonly"
      v-model="inputValue"
      :disabled="disabled"
      active-color="#48AC42"
      inactive-color="#A3B0C6"
      :active-text="(form.customOptions && form.customOptions.open) || this.$t('Open')"
      :inactive-text="(form.customOptions && form.customOptions.close) || this.$t('Close')">
    </el-switch>
    <div class="customValueText" v-else>
      <el-tag size="small" :type="inputValue ? 'success' : 'danger'">
        {{ inputValue ? (form.customOptions && form.customOptions.open || $t('Open')) : (form.customOptions && form.customOptions.close || $t('Close')) }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: "timeSelect",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: "",
        customOptions: {
          open: "",
          close: ""
        }
      })
    }
  },
  data() {
    return {
      inputValue: false
    }
  },
  watch: {
    inputValue(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.inputValue = this.value;
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-switch__label  {
  color: #A3B0C6;
  font-weight: 400;
}
</style>