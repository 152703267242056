<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <el-input-number v-if="!readonly" v-model="inputValue" :disabled="disabled" :min="0" size="small" />
    <div class="customValueText" v-else>
      {{ inputValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: "inputText",
  props: {
    value: {
      type: String | Number,
      default: "0"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: ""
      })
    }
  },
  data() {
    return {
      inputValue: 0
    }
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    }
  },
  mounted() {
    this.inputValue = Number(this.value);
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input-number {
  width: 100%;
}
</style>