<template>
  <div>
    <div class="order_detail" v-if="orderInfo" id="exportPdf" @click="onClose">
      <testWrapper>
        <template slot="left">
          <el-card class="orderCard">
            <div class="od_top">
              <div class="od_top_r">
                <div class="navBack" @click="$router.go(-1)">
                  <i class="el-icon-arrow-left"></i>
                  {{ $t('el.pageHeader.title') }}
                </div>
                <mapTrack
                  :orders="[{
                    orderId: orderInfo.orderId,
                    ci: orderInfo.ci,
                    index: 1,
                    progress: orderInfo.progress
                  }]"
                  style="--h: 440px"
                />
                <div
                  class="to_Tracking_box"
                  @click="
                    onTracking(
                      orderInfo.ci,
                      orderInfo.orderId
                    )
                  "
                >
                  <span class="to_Tracking_span">{{ $tc("order.Tracking", $isOrderTitle()) }}</span>
                  <i class="el-icon-location-outline"></i>
                </div>
              </div>
            </div>
            <div class="eventBox">
              <div class="borderLine" v-if="activityList.length && eventLayout"></div>
              <div class="eventAction">
                <div class="top">
                  <div class="left">
                    <el-button
                      icon="el-icon-postcard"
                      size="small"
                      class="todoButton"
                      @click="checkTodo()"
                      >{{ $t("todo.to-do") }}
                    </el-button>
                    <el-button
                      size="small"
                      :type="data_event.relatedMe ? 'primary' : ''"
                      @click="relatedMeChange"
                      >{{ $t("order.Related to me") }}
                    </el-button>
                    <chat-select
                      v-model="data_event.companyTagList"
                      multiple
                      size="small"
                      collapse-tags
                      style="width: 158px;"
                      :placeholder="$t('order.Event tag')"
                      @change="reloadEvent">
                      <chat-option
                        v-for="(item, index) in orderInfo.companyTagList"
                        :key="index"
                        :label="item.content"
                        :value="item.tagId">
                        <el-tag size="small" :color="item.color" class="eventTags">{{ item.content }}</el-tag>
                      </chat-option>
                    </chat-select>
                  </div>
                  <div class="center" v-if="isCeoAcount">
                    <el-button
                      type="primary"
                      icon="el-icon-circle-plus"
                      @click="createEvent()"
                      >{{ $t('event.Create event') }}
                    </el-button>
                  </div>
                  <div class="right">
                    <!-- <toolTipIcon  -->
                      <!-- icon="iconfont icon-a-fanyi1"  -->
                      <!-- :tooltipText="$t('One click translation')" -->
                      <!-- :isActive="isOneClickTranslate" -->
                      <!-- @click="oneClickTranslate" -->
                    <!-- /> -->
                    <toolTipIcon 
                      icon="iconfont icon-zaixianbiaoge" 
                      :tooltipText="$t('Online form')"
                      @click="seeEventTable"
                    />
                    <toolTipIcon 
                      :icon="`iconfont ${!data_event.collect ? 'icon-shoucang' : 'icon-yishoucang'}`" 
                      :tooltipText="!data_event.collect ? $t('home.Follow') : $t('home.Unfollow')"
                      @click="followChange"
                    />
                    <toolTipIcon 
                      icon="iconfont icon-fenxiang" 
                      :tooltipText="$t('order.share')"
                      @click="goShareOrder"
                    />
                    <toolTipIcon 
                      icon="iconfont icon-download" 
                      :tooltipText="$t('ExportFile')" 
                      isPopover>
                      <template slot="popperMenuItem">
                        <div class="popperMenuItem" @click="savePdf('png')">
                          <i class="iconfont icon-daochuwenjian" />
                          <span>{{ $t('Files.ExportPng') }}</span>
                        </div>
                        <div class="popperMenuItem" @click="savePdf('excel')">
                          <i class="iconfont icon-daochuexcel" />
                          <span>{{ $t('Files.ExportExcel') }}</span>
                        </div>
                      </template>
                    </toolTipIcon>
                    <el-button
                      icon="iconfont icon-guolv"
                      size="small"
                      class="searchButton"
                      :class="isFilterSearch ? 'active' : ''"
                      @click="isFilterSearch = !isFilterSearch"
                      >{{ $t("filter") }}
                    </el-button>
                    <el-tooltip
                      popper-class="ec-tooltip"
                      effect="light"
                      :content="$t('order.Views switching')"
                      placement="top"
                      :open-delay="400"
                    >
                      <el-button
                        style="width: 40px"
                        :icon="`${eventLayout ? 'el-icon-menu' : 'iconfont icon-liushi'}`"
                        size="small"
                        class="searchButton"
                        @click="eventLayout = !eventLayout"
                      />
                    </el-tooltip>
                  </div>
                </div>
                <div class="bottom" v-if="isFilterSearch">
                  <el-select
                    v-model="data_event.uploadPerson"
                    filterable
                    clearable
                    appendToBody
                    size="small"
                    :placeholder="$t('memberList.Member')"
                    @change="reloadEvent">
                    <el-option
                      v-for="(item, index) in orderInfo.memberList"
                      :key="index"
                      :label="item.nickName"
                      :value="item.openId"
                      class="userBox">
                      <div class="userNameBox">
                        <el-avatar :src="item.avatarFileUrl"></el-avatar>
                        <div class="content">
                          <div class="name">{{ item.nickName }}</div>
                          <div class="position">{{ item.position }}</div>
                        </div>
                      </div>
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="data_event.keyword"
                    size="small"
                    :placeholder="$t('event.Event Title/Content')"
                    @keyup.enter.native="reloadEvent">
                    <i
                      slot="suffix"
                      @click="reloadEvent"
                      class="el-input__icon el-icon-search"
                      style="cursor: pointer;"
                    ></i>
                  </el-input>
                  <el-date-picker
                    v-model="eventTime"
                    size="small"
                    type="daterange"
                    :start-placeholder="$t('order.Start from—End to').split('—')[0]"
                    :end-placeholder="$t('order.Start from—End to').split('—')[1]"
                    @change="reloadEvent"
                  />
                  <div class="translateButton">
                    <el-tabs
                      class="special_tabs"
                      v-model="data_event.type"
                      size="small"
                      @tab-click="reloadEvent()">
                      <el-tab-pane :label="$t(`Dashboard.whole`)" :name="' '" />
                      <el-tab-pane :label="$t('order.Files Only')" :name="'0'" />
                      <el-tab-pane :label="$t('order.eventNode')" :name="'1'" />
                    </el-tabs>
                  </div>
                </div>
              </div>
              <div v-if="eventLayout" v-loading="loading_event" class="eventContent">
                <div class="newEventTips" v-if="newEventData.length">
                  <div class="TipsBox" @click="distributeEventdata">
                    <div class="avatar">
                      <template v-for="(item, index) in newEventDataComputed">
                        <member 
                          :key="index" 
                          v-if="!item.keyEvent" 
                          :info="item.createPerson" 
                          :size="20" 
                        />
                        <div :key="index" class="iconBox" v-else>
                          <i :class="`iconfont ${iconComputed(item)}`"></i>
                        </div>
                      </template>
                    </div>
                    <div class="text">{{ $tc('event.number new events', newEventData.length > 99 ? '99+' : newEventData.length) }}</div>
                  </div>
                </div>
                <el-empty
                  :description="$t('order.No message')"
                  :image="$emptyImage"
                  :image-size="90"
                  v-if="eventList && !eventList.length"
                ></el-empty>
                <div v-for="(item, index) in eventList" :key="index">
                  <div class="data_file" v-if="isShowDateFun(item, index)">
                    <h4 class="item_date">
                      {{ item.createTime && item.createTime.split(" ")[0] }}
                    </h4>
                  </div>
                  <div v-if="item.data_display == 'left'" class="eventLeft">
                    <eventItem 
                      v-if="!item.isSystem"
                      :ref="'eventComponent'+item.eventId"
                      type="left"
                      :item="item" 
                      :index="index" 
                      :isCeoAcount="isCeoAcount"
                      :ActiveId.sync="ActiveId"
                      :visible_moreIndex.sync="visible_moreIndex"
                      :orderInfo="orderInfo"
                      :businessUserId="businessUserId"
                      :activityList.sync="eventList"
                      :tagDataList="orderInfo.companyTagList"
                      @deleteEventData="(idx, number) => {
                        eventList.splice(idx, number)
                        activityList.splice(idx, number)
                      }"
                    />
                    <systemEventItem
                      v-else
                      type="left"
                      :item="item" 
                      :orderInfo="orderInfo"
                      :shippingInfo="shippingInfo"
                    />
                  </div>
                  <div v-if="item.data_display == 'right'" class="eventRight">
                    <eventItem 
                      v-if="!item.isSystem"
                      :ref="'eventComponent'+item.eventId"
                      type="right"
                      :item="item" 
                      :index="index" 
                      :isCeoAcount="isCeoAcount"
                      :ActiveId.sync="ActiveId"
                      :visible_moreIndex.sync="visible_moreIndex"
                      :orderInfo="orderInfo"
                      :businessUserId="businessUserId"
                      :activityList.sync="eventList"
                      :tagDataList="orderInfo.companyTagList"
                      @deleteEventData="(idx, number) => {
                        eventList.splice(idx, number)
                        activityList.splice(idx, number)
                      }"
                    />
                    <systemEventItem
                      v-else
                      type="right"
                      :item="item" 
                      :orderInfo="orderInfo"
                      :shippingInfo="shippingInfo"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="blockEventLayout">
                <el-empty
                  :description="$t('order.No message')"
                  :image="$emptyImage"
                  :image-size="90"
                  v-if="eventList && !eventList.length"
                ></el-empty>
                <el-row :gutter="22">
                  <el-col
                    v-for="(item, index) in eventList" 
                    :key="index"
                    :md="12"
                    :lg="8"
                    :xl="6">
                    <blockEventItem 
                      :item="item" 
                      :isCeoAcount="isCeoAcount" 
                      :index="index" 
                      :orderInfo="orderInfo"
                      :ActiveId.sync="ActiveId"
                      :activityList.sync="eventList"
                      :visible_moreIndex.sync="visible_moreIndex"
                      :shippingInfo="shippingInfo"
                      @deleteEventData="(idx, number) => {
                        eventList.splice(idx, number)
                        activityList.splice(idx, number)
                      }" 
                    />
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </template>
        <template slot="right">
          <div class="od_title content_box">
            <el-card class="right_topCard">
              <div class="rightBoxTitle">
                <div class="title">
                  {{ $tc('order.orderDetail', $isOrderTitle()) }}
                  <i 
                    class="iconfont icon-Vector" 
                    v-if="orderInfo.createPerson == businessUserId && orderInfo.progress != '6'"
                    @click="$refs.createOrderDialog.open();"
                  ></i>
                </div>
                <div class="action">
                  <div class="link" @click="skuOrderId = orderId">{{ $t("order.sku File preview") }}</div>
                </div>
              </div>
              <div class="logoDetail">
                <el-avatar :src="customerInfo.url" fit="cover" />
                <div class="content">
                  <div class="label">{{ $tc("order.OrderNo", $isOrderTitle()) }}</div>
                  <div class="text">{{ orderInfo.ci ? orderInfo.ci : orderInfo.pi }}</div>
                </div>
                <i class="el-icon-copy-document" @click="copyOrderNo"></i>
              </div>
              <div class="infoRowDetail">
                <div class="rowItem">
                  <p>{{ $t("order.CreateTiem") }}</p>
                  <p>{{ orderInfo.createTime }}</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Value") }}</p>
                  <p>
                    {{ orderInfo.currency }}
                    {{ orderInfo.amount }}
                  </p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Status") }}</p>
                  <p>{{ computedNodeList(orderInfo.progress).name }}</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Supplier") }}</p>
                  <p class="aLink" @click="goCompanyDetail(orderInfo.vendor.companyId)">
                    {{
                      orderInfo.vendor.aliasName ||
                      orderInfo.vendor.name
                    }}
                  </p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Buyer") }}</p>
                  <p class="aLink" @click="goCompanyDetail(orderInfo.purchase.companyId)">
                    {{
                      orderInfo.purchase.aliasName ||
                      orderInfo.purchase.name
                    }}
                  </p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Forwarder") }}</p>
                  <div class="listContent">
                    <span
                      v-for="(item, index) in orderInfo.forwarderList"
                      :key="index"
                      class="ForwarderList" 
                      @click="goCompanyDetail(item.companyId)"
                    >
                      <img :src="item.companyLogoUrl" />
                      <span class="name aLink">
                        {{ item && item.name
                        }}{{
                          index == orderInfo.forwarderList &&
                          orderInfo.forwarderList.length - 1
                            ? ""
                            : index === orderInfo.forwarderList.length - 1 ? "" : "，"
                        }}
                      </span>
                      <el-popover
                        placement="top"
                        width="82"
                        trigger="click"
                        popper-class="orderPopover"
                      >
                        <ul class="user_popover">
                          <li
                            class="user_delete"
                            @click="deleteForwarder(item, index)"
                          >
                            <i class="iconfont icon-shanchu"></i>
                            <span>{{ $t("order.Delete") }}</span>
                          </li>
                        </ul>
                        <i slot="reference" @click.stop class="iconfont icon-Ellipsis"></i>
                      </el-popover>
                    </span>
                    <div
                      class="addForwarderText TextHover"
                      v-if="isCeoAcount"
                      @click="openForwarderDialog()"
                    >
                      {{ $t("order.Add Forwarder") }}
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card class="right_topCard">
              <div class="rightBoxTitle">
                <div class="title">{{ $t('order.shipDetail') }}</div>
                <div class="action" v-if="isCeoAcount">
                  <el-button 
                    size="small" 
                    icon="iconfont icon-Vector" 
                    @click="createShipment()"
                  >{{ $t("createShipping.Edit") }}</el-button>
                </div>
              </div>
              <div class="infoRowDetail" v-if="shippingInfo">
                <div class="rowItem" v-if="shippingInfo.shippingType == '0'">
                  <p>{{ $t("order.Ship info") }}</p>
                  <p
                    v-if="
                      shippingInfo.vessel != '' || shippingInfo.voyage != ''
                    "
                  >
                    {{ shippingInfo.vessel }}/{{ shippingInfo.voyage }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem" v-if="shippingInfo.shippingType == '1'">
                  <p>{{ $t("order.Air info") }}</p>
                  <p
                    v-if="
                      (shippingInfo && shippingInfo.flight != '') ||
                      (shippingInfo && shippingInfo.flightDate != '')
                    "
                  >
                    {{ shippingInfo && shippingInfo.flight }}/{{
                      dayjs(shippingInfo && shippingInfo.flightDate).format(
                        "YYYY-MM-DD"
                      )
                    }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Weight/Dimension") }}</p>
                  <p
                    v-if="
                      shippingInfo.weight != '' || shippingInfo.dimension != ''
                    "
                  >
                    {{ shippingInfo.weight  }}{{ shippingInfo.weightUnit }}/
                    {{ shippingInfo.dimension }}{{ shippingInfo.dimensionUnit }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Quantity") }}</p>
                  <p v-if="shippingInfo.qty != ''">
                    {{ shippingInfo.qty }}{{ shippingInfo.qtyUnit }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Port of Loading") }}</p>
                  <p v-if="shippingInfo.pol != ''">
                    {{ shippingInfo.pol }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Port of discharge") }}</p>
                  <p v-if="shippingInfo.pod != ''">
                    {{ shippingInfo.pod }}
                  </p>
                  <p v-else>____</p>
                </div>
                <div class="rowItem">
                  <p>{{ $t("order.Destination") }}</p>
                  <p v-if="shippingInfo.destination != ''">
                    {{ shippingInfo.destination }}
                  </p>
                  <p v-else>____</p>
                </div>
              </div>
            </el-card>
            <el-card class="right_topCard">
              <div class="rightBoxTitle">
                <div class="title">{{ $tc('order.Custom Project Information', this.$isOrderTitle()) }}</div>
                <div class="action" v-if="isCeoAcount">
                  <el-button 
                    size="small" 
                    icon="iconfont icon-Vector" 
                    @click="openAddCustomForm('edit')"
                  >{{ $t("createShipping.Edit") }}</el-button>
                </div>
              </div>
              <div class="infoRowDetail" v-if="customFormInfo.length">
                <div
                  class="rowItem"
                  v-for="(item, index) in customFormInfo.filter((dd, index) => index < 4)"
                  :key="index">
                  <p class="line1 blurLine">{{ item.customName }}</p>
                  <template v-if="item.customValue">
                    <template v-if="item.customType == '1'">
                      <p>{{ $isFindData(item.customOptions, item.customValue, 'value', 'label') }}</p>
                    </template>
                    <template v-else-if="item.customType == '2'">
                      <p>{{ item.customValue.map(row => $isFindData(item.customOptions, row, 'value', 'label')).join(",") }}</p>
                    </template>
                    <template v-else-if="item.customType == '3'">
                      <p v-if="item.customValue.filter(row => row != '').length">{{ item.customValue[0] }} {{ item.customValue[1] ? '-' : '' }} {{ item.customValue[1] }}</p>
                      <p v-else>_ _</p>
                    </template>
                    <template v-else-if="item.customType == '4'">
                      <p v-if="item.customValue.filter(row => row != '').length">{{ item.customValue[0] }} {{ item.customValue[1] ? '-' : '' }} {{ item.customValue[1] }}</p>
                      <p v-else>_ _</p>
                    </template>
                    <template v-else-if="item.customType == '6'">
                      <p>
                        <fileBlocks
                          v-if="item.customValue"
                          :embedUrl="item.customValue"
                          background=""
                        />
                      </p>
                    </template>
                    <template v-else-if="item.customType == '7'">
                      <img class="imageBox" :src="item.customValue" />
                    </template>
                    <template v-else-if="item.customType == '10'">
                      <el-rate
                        v-model="item.customValue"
                        disabled
                        :colors="colors">
                      </el-rate>
                    </template>
                    <template v-else-if="item.customType == '9'">
                      <el-tag size="small" :type="item.customValue ? 'success' : 'danger'">
                        {{ item.customValue ? (item.customOptions && item.customOptions.open || $t('Open')) : (item.customOptions && item.customOptions.close || $t('Close')) }}
                      </el-tag>
                    </template>
                    <template v-else>
                      <p>{{ item.customValue }}</p>
                    </template>
                  </template>
                  <p v-else>_ _</p>
                </div>
                <div class="seeMore" @click="openAddCustomForm('see')">
                  {{ $t('home.seeMore') }}
                </div>
              </div>
              <el-empty 
                v-else 
                :description="$t('overallSituation.noData')" 
                :image="$emptyImage"
                :image-size="50"
              />
            </el-card>
            <el-card class="right_topCard" v-if="orderInfo">
              <div class="companyContainerItem">
                <p class="companyType">
                  {{ $t("customerInfo.Member") }}
                </p>
                <div class="companyMoreWrapper">
                  <template v-for="(item, index) in memberListComputed">
                    <member 
                      :key="index"
                      :info="item" 
                      :size="38" 
                      :placement="'bottom'"
                      v-if="index < 4"
                    ></member>
                  </template>
                  <div class="moreImage" @click="onMoreList">
                    <el-image :src="orderInfo.memberList[orderInfo.memberList.length - 1] && orderInfo.memberList[orderInfo.memberList.length - 1].avatarFileUrl" />
                    <div class="mask">+{{ orderInfo.memberList.length - memberListComputed.length }}</div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card class="right_topCard">
              <div class="rightBoxTitle">
                <div class="title">{{ $t('order.Tag Type') }}</div>
                <div class="action" v-if="isCeoAcount">
                  <el-button 
                    size="small" 
                    icon="iconfont icon-Vector" 
                    @click="openOrderTagConfig"
                  >{{ $t("createShipping.Edit") }}</el-button>
                </div>
              </div>
              <div class="orderTagBox" v-if="orderInfo.companyTagList.length">
                <div 
                  class="tag" 
                  v-for="(item, index) in orderInfo.companyTagList" 
                  :key="index"
                  :style="{
                    'background': item.color
                  }">
                  {{ item.content }}
                </div>
              </div>
              <el-empty 
                v-else 
                :description="$t('overallSituation.noData')" 
                :image="$emptyImage"
                :image-size="50"
              />
            </el-card>
            <el-card class="right_topCard" style="position: sticky;top: 10px;">
              <div class="rightBoxTitle">
                <div class="title">{{ $tc("order.orderHistory", $isOrderTitle()) }}</div>
                <div class="action" v-if="isCeoAcount">
                  <el-button
                    size="small"
                    @click="previous()"
                    :disabled="orderInfo.progress == orderInfo.progressList[0].progress"
                  >
                    {{ $t("order.Previous") }}</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="next()"
                  >
                    {{ $t("order.Next") }}</el-button
                  >
                </div>
              </div>
              <div class="step">
                <orderHistory
                  :history="this.orderInfo.progressList"
                  :progress="this.orderInfo.progress"
                  :size="40"
                  :type="2"
                  style="--h: 32px; --p: 8px"
                >
                </orderHistory>
              </div>
            </el-card>
          </div>
        </template>
      </testWrapper>

      <MemberList
        :visible="visible"
        @close="visible = !visible"
        @updateFun="updataFun"
      ></MemberList>
      <!--添加货代-->
      <addForwarder 
        type="detail"
        :show.sync="forwarderDialog" 
        :orderId="orderInfo.orderId"
        @updateConfirm="onForwarderDialog"
      />
      <todoDrawer
        :drawer.sync="drawer"
        :orderInfo="orderInfo"
        :businessUserId="businessUserId"
        @handleClose="handleDrawerClose"
      />
      <createEvent
        :addEventShow.sync="addEventShow1"
        @handleClose="handleEventClose"
        :orderInfo="orderInfo"
      />
      <createShipping
        :addShippingShow.sync="addShippingShow"
        @handleClose="handleshippingClose"
        :orderId="orderInfo.orderId"
        :shippingInfo="shippingInfo"
        @afterShipping="afterShipping"
      />
      <createCIorder
        :isCIdialogVisible="isCIdialogVisible"
        :PI="orderInfo.pi"
        :orderId="this.$route.query.orderId"
        @onClose="isCIdialogVisible = false"
      />
      <CIFallback
        :isCIFallback="isCIFallback"
        :PI="orderInfo.pi"
        :orderId="orderInfo.orderId"
        @onClose="isCIFallback = false"
      />
      <createOrder
        ref="createOrderDialog"
        :editContent="{
          ...orderInfo,
          customerId: orderInfo.purchase.companyId
        }"
        :customerInfo="null"
        action="edit"
        @afterCreateOrder="afterCreateOrder"
      />
      <configTagDialog 
        ref="configTagDialog" 
        :tagDataList.sync="orderInfo.companyTagList"
        @updateEvent="ongetOrderDetails();"
      />
      <skuView :orderId="skuOrderId" @close="skuOrderId = ''"></skuView>
      <el-backtop target=".router-view" :bottom="170"></el-backtop>
      <orderShare ref="orderShare" :type="0" :orderId="$route.query.orderId" />
      <seeEventTable ref="seeEventTable" :orderInfo="orderInfo" :shippingInfo="shippingInfo" />
      <seeCustomFormInfo ref="seeCustomFormInfo" @update="getCustomFormInfomation" />
    </div>
    <div class="errorMesaage" v-if="errorMessage">
      <img :src="$emptyImage" />
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import dayjs from "dayjs";

import { mapGetters } from "vuex";
import router from "@/router";

import { 
  getOrderDetails, 
  orderStatusNext,
  orderStatusPrev,
  deleteForwarderApi,
  getCustomFormInfomation
} from "@/api/order";
import {
  getEventPageList,
} from "@/api/event";
import { selectBusinessShipping } from "@/api/shipping";
import todoDrawer from "@/components/todoDrawer/todoDrawer";
import createEvent from "@/components/createEvent/createEvent";
import createShipping from "@/components/createShipping/createShipping";
import createCIorder from "@/components/createCIorder/createCIorder";
import globalSearchBox from "@/components/globalSearchBox/index.vue";
import OrderProgress from "@/components/orderProgress/orderProgress.vue";
import CIFallback from "@/components/CIFallback/CIFallback.vue";
import eventItem from "../components/eventItem";
import systemEventItem from "../components/systemEventItem.vue";
import addForwarder from "@/components/addForwarder/addForwarder.vue";
import createOrder from "@/components/createOrder/createOrder";
import configTagDialog from "@/components/configTagDialog";
import orderShare from "@/components/orderShare";
import blockEventItem from '../components/blockEventItem.vue';
import seeEventTable from '../components/seeEventTable.vue';
import seeCustomFormInfo from "@/components/seeCustomFormInfo";

export default {
  name: "OrderDetails",
  components: {
    createEvent,
    todoDrawer,
    createShipping,
    OrderProgress,
    createCIorder,
    globalSearchBox,
    CIFallback,
    eventItem,
    addForwarder,
    systemEventItem,
    createOrder,
    configTagDialog,
    orderShare,
    blockEventItem,
    seeEventTable,
    seeCustomFormInfo
  },
  inject: ["reload"],
  data() {
    return {
      isOneClickTranslate: false,
      addForwardLoding: false,
      skuOrderId: "",
      textTranslation: "",
      textTranslationTwo: "",
      translationStatus: false,
      isFilterSearch: false,
      translationStatusTwo: false,
      isCIdialogVisible: false,
      isCIFallback: false,
      replaceLeft: false,
      tableLoading: true,
      eventLayout: true,
      commentInput: "",
      visible_moreIndex: null,
      embeddUrl: null,
      visible_one: false,
      loading: false,
      dayjs: dayjs,
      forwarderDialog: false,
      visible: false,
      isOpenExport: false,
      firstPaint: true,
      isPicture: false,
      orderuserInfo: [],
      newEventData: [],
      embedShow: false,
      full: false,
      statusEnum: [
        "PRODUCING",
        "DELIVERY_LOCAL",
        "SEND_PORT",
        "DELIVERY_TRANS",
        "RECEIVE_PORT",
        "DELIVERY_DEST",
        "DESTINATION",
      ],
      tabsChecked: 0,
      addEventShow1: false,
      addShippingShow: false,
      page: "order",
      value: false,
      activityList: [],
      orderPeopleGroup: [],
      customerInfo: {},
      orderInfo: null,
      display_layout: ["left", "right"],
      loading_event: true,
      companyName: "",
      drawer: false, //todo-Drawer
      shippingInfo: {},
      enableEvent: true,
      orderId: "",
      eventTime: [],
      data_event: {
        type: " ",
        uploadPerson: "",
        keyword: "",
        collect: 0,
        relatedMe: 0,
        companyTagList: [],
        currentPage: 1,
        pageSize: 1000000,
      },
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      eventList: [],
      ActiveId: null,
      errorMessage: "",
      customFormInfo: []
    };
  },
  provide() {
    return {
      parent: this,
      isOneClickTranslate: () => {
        return this.isOneClickTranslate
      }
    };
  },
  computed: {
    ...mapGetters([
      "businessUserId",
      "companyCurrentType",
      "PIorder",
      "orderProgress",
      "eventUpdate",
      "shipUpdate",
      "commentUpdate",
      "companyId",
      "allNodeList"
    ]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    memberListComputed() {
      return this.orderInfo.memberList.filter((item, index) => index < this.orderInfo.memberList.length - 1)
    },
    isCeoAcount() {
      return this.orderInfo.memberList.find((item, index) => item.openId == this.businessUserId);
    },
    newEventDataComputed() {
      let data = [];
      this.newEventData.forEach(item => {
        if (data.length == 0) {
          data.push(item);
        } else {
          let isDiff = true;//是否不同
          for (let i = 0; i < data.length; i++) {
            let dataItem = data[i];
            if (dataItem.createPerson.openId == item.createPerson.openId) {
              /*集合中已经存在相同数据*/
              isDiff = false;
              break;
            }
          }
          if (isDiff) {
            data.push(item);
          }
        }
      });
      return data.filter((item, index) => index < 5);
    },
    iconComputed() {
      return (item) => {
        let content = item.contentJson;
        if(content) {
          if(content.type == 1) {
            return "icon-dingdan1";
          } else if(content.type == 2) {
            return "icon-yundan";
          } else if(content.type == 3) {
            return "icon-daka";
          } else {
            return "icon-shuaxin";
          }
        }
      }
    },
  },
  watch: {
    '$route': {
      handler() {
        this.orderId = this.$route.query.orderId;
        this.ongetOrderDetails();
        this.getEventList();
        this.getCustomFormInfomation();
        document.querySelector(".router-view").addEventListener("scroll", this.scrollEvent, true);
      },
      deep: true
    },
    PIorder(val) {
      if (val?.PI && this.orderInfo.pi == val?.PI) {
        let text =
          val?.messageType == "PI_RETURN"
            ? this.$t(
                "xiaoxie.The current CI order has been rolled back, return to the order list page"
              )
            : this.$t(
                "xiaoxie.The current PI order has been split, return to the order list page"
              );
        this.$confirm(text, this.$t("xiaoxie.Notification"), {
          confirmButtonText: this.$t("xiaoxie.confirm"),
          type: "warning",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          showCancelButton: false,
        }).then(() => {
          this.$router.push({
            path: "/order",
            query: { PI: val?.PI },
          });
        });
      }
    },
    eventUpdate(val) {
      if(!val) return;
      if(val.orderId != this.orderId) return;
      let obj = {
        ...val,
        isSystem: val.keyEvent == 1,
        data_display: this.display_layout[this.activityList.length % 2],
        commentInput: "",
        userId: val.createPerson.openId
      };
      if(!this.activityList.map(item => item.eventId).includes(val.eventId)) {
        if(val.createPerson.openId == this.businessUserId) {
          this.activityList.unshift(obj);
        } else {
          this.newEventData.unshift({
            ...obj,
            data_display: this.display_layout[this.newEventData.concat(this.activityList).length % 2],
          });
        }
      } else {
        let index = this.activityList.findIndex(item => item.eventId == val.eventId);
        let find = this.activityList.find(item => item.eventId == val.eventId);
        this.activityList.splice(index, 1, {
          ...val,
          isSystem: val.keyEvent == 1,
          data_display: find.data_display,
          commentInput: "",
          userId: val.createPerson.openId
        });
      }
    },
    activityList: {
      handler() {
        this.eventList = this.activityList.slice(0, (this.data_event.currentPage * 20));
      },
      deep: true
    },
    'data_event.currentPage'() {
      this.eventList = this.activityList.slice(0, (this.data_event.currentPage * 20));
    },
    commentUpdate(val) {
      this.activityList = this.activityList.map((item, index) => {
        if(item.eventId == val.eventId) {
          return {
            ...item,
            commentList: val.commentList
          }
        } else {
          return {
            ...item
          };
        }
      })
    },
    shipUpdate() {
      this.reloadEvent();
    }
  },
  mounted() {
    router.beforeEach((to, from, next) => {
      if (from.path === "/order/orderDetails") {
        this.visible = false;
      }
      next();
    });
    this.orderId = this.$route.query.orderId;
    this.ongetOrderDetails();
    this.getEventList();
    this.getCustomFormInfomation();
    document.querySelector(".router-view").addEventListener("scroll", this.scrollEvent, true);
  },
  deactivated() {
    document.querySelector(".router-view").removeEventListener("scroll", this.scrollEvent);
  },
  methods: {
    getCustomFormInfomation() {
      getCustomFormInfomation(this.orderId).then((data) => {
        this.customFormInfo = data.map((item) => ({
          ...item,
          customOptions: item.customOptions ? JSON.parse(item.customOptions) : null,
          customValue: item.customType == '2' || item.customType == '3' || item.customType == '4' ? (item.customValue ? item.customValue.split(",") : item.customValue.split("")) : (item.customType == '10' ? Number(item.customValue) : (item.customType == '9' ? JSON.parse(item.customValue) : item.customValue))
        }));
      })
    },
    distributeEventdata() {
      this.activityList = this.newEventData.concat(this.activityList);
      this.newEventData = [];
    },
    seeEventTable() {
      this.$refs.seeEventTable.open();
    },
    openAddCustomForm(mode) {
      this.$refs.seeCustomFormInfo.open(this.customFormInfo, mode);
    },
    oneClickTranslate() {
      this.isOneClickTranslate = !this.isOneClickTranslate;
    },
    goShareOrder() {
      this.$refs.orderShare.open();
    },
    scrollEvent(e) {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      console.log('123123123');
      if (clientHeight + scrollTop >= (scrollHeight - 80)) {
        this.data_event.currentPage += 1;
      }
    },
    openOrderTagConfig() {
      this.$refs.configTagDialog.open();
    },
    goCompanyDetail(id) {
      if(this.companyId == id) {
        this.$router.push(`/companyProfile?companyName=yourownpage&companyId=${id}&type=edit`);
        return;
      }
      this.$router.push(`/customer?companyId=${id}`);
    },
    copyOrderNo() {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(location.href).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
      });
    },
    isShowDateFun(vo, index) {
      if(index == 0) {
        return true;
      }
      return dayjs(vo?.createTime).format("YYYY-MM-DD") != dayjs(this.activityList[index - 1]?.createTime).format("YYYY-MM-DD");
    },
    updataFun(data) {
      this.orderInfo.memberList = data;
    },
    afterCreateOrder() {
      this.ongetOrderDetails();
    },
    ongetOrderDetails() {
      localStorage.setItem("orderDetailId", this.orderId);
      getOrderDetails(this.orderId).then((response) => {
        if (!response) return;
        this.orderInfo = response;
        if (this.companyCurrentType == "1") {
          this.customerInfo.url = this.orderInfo.purchase.companyLogoUrl;
        } else if (this.companyCurrentType == "0") {
          this.customerInfo.url = this.orderInfo.vendor.companyLogoUrl;
        } else {
          this.customerInfo.url = this.orderInfo.purchase.companyLogoUrl;
        }
        this.getSelectBusinessShipping();
      }).catch((error) => {
        this.errorMessage = error.message;
      });
    },
    relatedMeChange() {
      this.data_event.relatedMe = this.data_event.relatedMe == 0 ? 1 : 0;
      this.getEventList();
    },
    followChange() {
      this.data_event.collect = this.data_event.collect == 0 ? 1 : 0;
      this.getEventList();
    },
    getEventList() {
      //初始化获取事件列表
      let startTime = "";
      let endTime = "";
      if(this.eventTime && this.eventTime.length) {
        startTime = dayjs(this.eventTime[0]).format("YYYY-MM-DD HH:mm:ss");
        endTime = dayjs(this.eventTime[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      }
      getEventPageList({
        ...this.data_event,
        currentPage: 1,
        startTime,
        endTime,
        orderId: this.orderId
      })
        .then((response) => {
          if (!response) return;
          let listLength =  response.list.length;
          this.activityList = response.list.map((item, index) => {
            listLength --;
            item.content = item.contentJson || item.content;
            return ({
              ...item,
              isSystem: typeof item.content == 'object',
              data_display: this.display_layout[listLength % 2],
              commentInput: "",
              check: false,
              userId: item.createPerson.openId
            })
          });
          //放在初始化订单之前视觉效果好
          this.loading_event = false;
          this.isOpenExport = true;
          this.$nextTick(() => {
            this.setScrollBottom();
          });
        })
    },
    setScrollBottom() {
      if(this.$route.query.eventId) {
        let eventDom = document.querySelector(`#event${this.$route.query.eventId}`);
        if(eventDom) {
          eventDom.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
          eventDom.classList.add("active");
          setTimeout(() => {
            eventDom.classList.remove("active");
          }, 2000)
        } else {
          let index = this.activityList.findIndex(row => row.eventId == this.$route.query.eventId);
          if(index != -1) {
            this.eventList = this.activityList.slice(0, (this.activityList.length - 1) == index ? index : index + 5);
            this.$nextTick(() => {
              setTimeout(() => {
                let eventDom = document.querySelector(`#event${this.$route.query.eventId}`);
                eventDom.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                });
                eventDom.classList.add("active");
                setTimeout(() => {
                  eventDom.classList.remove("active");
                }, 2000)
              }, 400);
            });
          }
        }
      }
      if(this.$route.query.commentId) {
        let commentDom = document.querySelector(`#comment${this.$route.query.commentId}`);
        if(commentDom) {
          this.$refs[`eventComponent${this.getParentNodeId(this.activityList, this.$route.query.commentId)}`][0].commentShowNumber = 100000;
          this.$nextTick(() => {
            commentDom.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
            commentDom.parentElement.parentElement.parentElement.classList.add("active");
            setTimeout(() => {
              commentDom.parentElement.parentElement.parentElement.classList.remove("active");
            }, 2000)
          });
        } else {
          let index = this.activityList.findIndex(row => {
            if(row.commentList && row.commentList.length) {
              return row.commentList.map(item => item.commentId).includes(this.$route.query.commentId);
            }
            return false;
          });
          if(index != -1) {
            this.eventList = this.activityList.slice(0, (this.activityList.length - 1) == index ? index : index + 5);
            this.$nextTick(() => {
              setTimeout(() => {
                this.$refs[`eventComponent${this.getParentNodeId(this.activityList, this.$route.query.commentId)}`][0].commentShowNumber = 100000;
                this.$nextTick(() => {
                  let commentDom = document.querySelector(`#comment${this.$route.query.commentId}`);
                  if(commentDom) {
                    commentDom.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start'
                    });
                    commentDom.parentElement.parentElement.parentElement.classList.add("active");
                    setTimeout(() => {
                      commentDom.parentElement.parentElement.parentElement.classList.remove("active");
                    }, 2000)
                  }
                });
              }, 400);
            });
          }
        }
      }
    },
    getParentNodeId(tree, childId) {
      // 遍历树节点
      for (let node of tree) {
        // 如果当前节点就是目标节点的父节点，直接返回当前节点id
        if (node.commentList && node.commentList.some(child => child.commentId === childId)) {
          return node.eventId;
        }
        // 否则继续遍历当前节点的子节点
        if (node.commentList) {
          const parentId = this.getParentNodeId(node.commentList, childId);
          if (parentId !== null) {
            return parentId;
          }
        }
      }
      // 如果没有找到父节点，则返回null
      return null;
    },
    getSelectBusinessShipping() {
      //初始化获取运单信息
      selectBusinessShipping(this.orderId)
      .then((response) => {
        if (!response) return;
        this.shippingInfo = response;
      })
    },
    onClose(e) {
      this.visible_moreIndex = -1;
      this.ActiveId = null;
    },
    onMoreList() {
      this.visible = true;
    },
    newPostUpdate() {
      this.reloadEvent();
      //更新运单信息
      this.getSelectBusinessShipping();
    },
    updateSearchEvent(value) {
      this.data_event.keyword = value;
      this.reloadEvent();
    },
    reloadEvent() {
      this.loading_event = true;
      //中断使能开关
      this.enableEvent = false;
      //初始化获取事件列表
      this.getEventList();
    },
    handleEventClose() {
      this.addEventShow1 = false;
    },
    handleDrawerClose() {
      this.drawer = false;
      this.reload(["Schedule"]);
    },
    openForwarderDialog() {
      this.forwarderDialog = true;
    },
    handleshippingClose(done) {
      //重新初始化获取运单信息，以防深度拷贝出的问题
      this.getSelectBusinessShipping();
      this.addShippingShow = false;
    },
    //新建event事件
    createEvent() {
      this.addEventShow1 = true;
    },
    //更新或创建运输信息后更新前端数数据
    afterShipping() {
      this.reloadEvent();
    },
    onTracking(ci, businessOrderIdList) {
      this.$router.push({
        path: `/tracking`,
        query: {
          orderId: businessOrderIdList,
          ci: ci,
        },
      });
      window.localStorage.setItem("currentPath", "/tracking");
    },
    //下一步
    next() {
      if (this.orderInfo.orderNode == this.orderInfo.progress) {
        let index = this.orderInfo.memberList.findIndex((vo) => {
          return (
            vo.company.companyType == "1" &&
            vo.openId == this.businessUserId
          );
        });
        if (index != -1 && (this.orderInfo.createPerson == this.businessUserId)) {
          this.isCIdialogVisible = true;
        } else {
          this.$message.warning(
            this.$t(
              "order.The current user has no operation permission for this order"
            )
          );
        }
      } else {
        if (this.orderInfo.progress == this.orderInfo.progressList[this.orderInfo.progressList.length - 1].progress) {
          this.$message.info(this.$t("order.It s the last step"));
        } else {
          this.$confirm(
            this.$t("order.Go to next checkpoint"),
            this.$t("order.Action"),
            {
              confirmButtonText: this.$t("order.Confirms"),
              cancelButtonText: this.$t("order.Cancel"),
              type: "warning",
            }
          ).then(() => {
            orderStatusNext(this.orderInfo.orderId).then(() => {
              this.$message.success(this.$t("order.createEvent succesfully"));
              //物流打卡成功后更新首页物流节点tag
              this.reload(["Index"]);
              this.ongetOrderDetails();
            })
          });
        }
      }
    },
    previous() {
      let currentIndex = this.orderInfo.progressList.findIndex(item => item.progress == this.orderInfo.progress);
      let previousValue = this.orderInfo.progressList[currentIndex - 1];
      if (this.orderInfo.progress == this.orderInfo.progressList[0].progress) {
        this.$message.info("已经是第一步了");
      } else if(previousValue.progress == this.orderInfo.orderNode) {
        let index = this.orderInfo.memberList.findIndex((vo) => {
          return (
            vo.company.companyType == "1" &&
            vo.openId == this.businessUserId
          );
        });
        if (index != -1 && (this.orderInfo.createPerson == this.businessUserId)) {
          this.isCIFallback = true;
        } else {
          this.$message.warning(
            this.$t(
              "order.The current user has no operation permission for this order"
            )
          );
        }
      } else {
        this.$confirm(
          this.orderInfo.progress == this.orderInfo.shipStartNode ? this.$t("order.Cross border transportation return verification") : this.$t("order.Back to previous checkpoint"),
          this.$t("order.Action"),
          {
            confirmButtonText: this.$t("order.Confirms"),
            cancelButtonText: this.$t("order.Cancel"),
            type: "warning",
          }
        ).then(() => {
          orderStatusPrev(this.orderInfo.orderId).then(() => {
            this.reload(["Index"]);
            this.ongetOrderDetails();
          })
        });
      }
    },
    checkTodo() {
      this.drawer = true;
    },
    //导出(pdf)
    savePdf(type) {
      document.querySelector(".router-view").scrollTo(0, 0);
      setTimeout(() => {
        if(type == 'png') {
          html2canvas(document.querySelector("#exportPdf"), {
            useCORS: true,
            allowTaint: true,
            windowWidth: document.body.scrollWidth,
            windowHeight: document.body.scrollHeight,
            x: 0,
            y: 0,
            background: '#F7F9FC',
          }).then((canvas) => {
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            canvas.toDataURL("image/png", 1);
            // 下载图片
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = "我的文件";
            a.click();
          });
          return;
        }
        let header = [
          this.$t('eventExport.eventType'),
          this.$t('eventExport.eventName'),
          this.$t('eventExport.eventContent'),
          this.$t('eventExport.eventCreatePerson'),
          this.$t('eventExport.eventCreateTime'),
          this.$t('eventExport.eventTags'),
          this.$t('eventExport.eventFile'),
        ];
        var filterVal = [
          "eventType",
          "subject",
          "content",
          "createPersonName",
          "createTime",
          "companyTagList",
          "eventFileUrl"
        ];
        var filename = this.$t('home.Event');
        console.log(this.activityList);
        var data = this.$formatJson(filterVal, this.activityList.map(item => ({
          ...item,
          eventType: !item.keyEvent ? this.$t('eventExport.Ordinary event') : this.$t('eventExport.System event'),
          subject: this.isSystemFuntion(item),
          companyTagList: item.companyTagList && item.companyTagList.map(row => row.content),
          content: typeof item.content == 'string' ? item.content.replace(/<[^>]+>/gi, '') : `${row.contentJson.type != 4 ? (row.createPerson && row.createPerson.nickName) : this.$t('memberList.manage')}${this.behaviorComputed(item)}${this.titleTypeComputed(item)}`,
          createPersonName: item.createPerson.nickName
        })));
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      }, 100);
    },
    behaviorComputed(row) {
      let content = row.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed(row) {
      let content = row.content;
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.$isOrderTitle());
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.$isOrderTitle());
        } else {
          return this.$tc("order.Order node", this.$isOrderTitle());
        }
      }
    },
    isSystemFuntion(row) {
      let content = row.contentJson;
      if(content) {
        if(content.type == 1) return `${this.$isOrderTitle()}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 2) return `${this.$t("home.waybill")}：${this.shippingInfo.bl}`
        if(content.type == 3) return `${this.$t("home.clock in")}：${this.orderInfo.ci || this.orderInfo.pi}`
      } else {
        return row.subject;
      }
    },
    //创建shipment
    createShipment() {
      this.addShippingShow = true;
    },
    deleteForwarder(row, index) {
      this.$confirm(
        this.$t("order.Delete the forwarder"),
        this.$t("event.Notification"),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteForwarderApi({
          orderId: this.orderInfo.orderId,
          forwarderCompany: row.companyId
        }).then(() => {
          this.orderInfo.forwarderList.splice(index, 1);
          this.$message.success(this.$t("todo.Delete Successful"))
        })
      }).catch(() => {});
    },
    onForwarderDialog() {
      this.ongetOrderDetails();
      this.reloadEvent();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.user_popover {
  li {
    padding: 1px 7px;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    color: #122545;
    display: flex;
    align-items: center;

    i {
      margin-right: 6px;
    }

    &:hover {
      background: #122545;
      color: #ffffff;
    }
  }

  .line {
    height: 1px;
    width: 88%;
    margin: 5px auto;
    background-color: #ccc;
  }
}
</style>
<style lang="less">
.orderPopover {
  padding: 6px 0;
  min-width: auto;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}
.export_pdf {
  border: 1px solid rgb(50, 139, 223);
}

.export_pdf:hover {
  border: 1px solid rgb(26, 115, 198);
}

.table_header {
  margin-top: 12px;
}

.ql-editor.ql-blank::before {
  font-style: inherit;
  font-size: 14px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-size: 14px;
  color: rgba(23, 24, 28, 1);
}

.el-button--primary.is-disabled {
  border-color: #2e3f4f;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  border-color: #2e3f4f;
}
.sku-view-button {
  position: absolute;
  top: 28px;
  right: 16px;
}
</style>
