<template>
  <el-drawer
    :visible.sync="addEventShow"
    :title="$t('home.CIAdd') + $t('home.Event')"
    size="364px"
    :before-close="handleCopyClose"
    :show-close="true"
    :append-to-body="true"
    @open="open"
    v-move-outside
  >
    <el-form
      :model="eventform"
      :rules="eventRules"
      ref="createEventForm"
      label-width="0"
      size="small"
      class="drawerForm"
    >
      <el-form-item prop="subject">
        <div class="title">{{ $t("event.Title") }}</div>
        <el-input
          v-model="eventform.subject"
          :placeholder="$t('event.Title')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="content">
        <div class="title">{{ $t("event.Content") }}</div>
        <EcEditor 
          v-model="eventform.content" 
          :isToolBar="false" 
          fileUploadCode="file_event_content_img" 
        />
      </el-form-item>
      <el-form-item>
        <div class="title">{{ $t("event.Upload your file") }}</div>
        <el-select
          v-model="eventform.fileType"
          append-to-body
          filterable
          :placeholder="$t('event.Select File Type')"
          style="width: 100%; margin-bottom: 12px"
        >
          <el-option
            v-for="(item, index) in fileEventType"
            :key="index"
            :label="item.configName"
            :value="item.configCode"
          />
        </el-select>
        <el-upload
          v-loading="uploadLoding"
          :disabled="!Boolean(eventform.fileType)"
          :auto-upload="false"
          action="#"
          drag
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-change="handleChange"
          :class="!eventform.file.file ? 'ec-upload' : 'ec-uploader'"
          :accept="eventform.fileType && fileEventType.filter(item => item.configCode == eventform.fileType)[0].supportFileTypes"
        >
          <template v-if="!eventform.file.file">
            <i class="iconfont icon-shangchuan"></i>
            <div class="el-upload__text">
              {{ $t("event.Drag & Drop files here OR Browse Files") }}
            </div>
          </template>
          <template v-else>
            <div class="ec-upload__left">
              <i
                class="iconfont_file"
                :class="isFileTypeIcon(eventform.file.file.name.split('.')[1])"
              ></i>
              <div class="name">
                <p>{{ eventform.file.file.name }}</p>
              </div>
            </div>
            <i class="iconfont icon-shanchu" @click.stop="handleRemove"></i>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="title">{{ $t("event.Who can see") }}</div>
        <el-radio-group v-model="eventform.eventLimit" style="width: 100%">
          <el-radio label="0">{{
            $t("event.Everyone in order can see")
          }}</el-radio>
          <el-radio label="1">{{ $t("event.Only me") }}</el-radio>
          <el-radio label="2">{{
            $t("event.Only selected people can see")
          }}</el-radio>
        </el-radio-group>
        <el-select
          v-model="eventform.visibleUsers"
          v-if="eventform.eventLimit === '2'"
          multiple
          collapse-tags
          :maxCollapseTags="2"
          append-to-body
          :placeholder="$t('event.select people to show')"
          class="userSelect"
          @change="(val) => userChange(val, 0)"
          style="margin-top: 5px;"
        >
          <el-option
            v-for="item in orderInfo.memberList.filter(item => item.openId != businessUserId)"
            :key="item.openId"
            :label="item.nickName"
            :value="item.openId"
            class="userSelectBox"
          >
            <member :info="item" :size="24" :placement="'bottom'" />
            <div class="selectName">
              <span>{{ item.nickName }}</span>
              <span class="company">{{ item.company.aliasName }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="title">{{ $t("order.Event tag") }}</div>
        <el-select
          v-model="eventform.companyTagList"
          multiple
          size="small"
          collapse-tags
          append-to-body
          :placeholder="$t('order.Event tag')"
          style="margin-bottom: 280px">
          <el-option
            v-for="(item, index) in orderInfo.companyTagList"
            :key="index"
            :label="item.content"
            :value="item.tagId">
            <el-tag size="small" :color="item.color" class="eventTags">{{ item.content }}</el-tag>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="drawerFooter">
      <el-button class="cancel_button" size="small" @click="handleClose">{{
        $t("Forwarder.Cancel")
      }}</el-button>
      <el-button 
        type="primary" 
        size="small" 
        :loading="buttonLoading"
        :disabled="uploadLoding" 
        @click="createOrderEvent">{{
        $t("event.Send out")
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { createEventIn } from "@/api/event";
import { uploadFileUrl, uploadFile } from "@/api/files";
import { isFileTypeIcon } from "@/utils/utils.js";

export default {
  name: "createEvent",
  data() {
    return {
      buttonLoading: false,
      isFileTypeIcon: isFileTypeIcon,
      activeName: "first",
      eventform: {
        eventLimit: "0",
        subject: "",
        content: "",
        orderId: "",
        companyTagList: [],
        visibleUsers: [],
        file: {
          eventFileId: "",
          file: null,
        },
      },
      eventRules: {
        subject: [
          {
            required: true,
            message: `${this.$t("home.Please enter")}${this.$t("event.Title")}`,
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: `${this.$t("home.Please enter")}${this.$t(
              "event.Content"
            )}`,
            trigger: "blur",
          },
        ],
      },
      uploadLoding: false,
      editorOption: {
        placeholder: this.$t("event.Write away"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
            ], //列表
            [
              {
                color: [],
              },
            ],
            [
              {
                font: ["SimSun", "FangSong"],
              },
            ],
          ],
        },
      },
    };
  },
  props: ["addEventShow", "orderInfo"],
  inject: ["reload"],
  computed: {
    ...mapGetters(["fileEventType", "businessUserId"]),
    i18n() {
      return window.vm.$i18n.locale;
    },
  },
  watch: {
    in18() {
      this.editorOption.placeholder = this.$t("event.Write away");
    },
  },
  methods: {
    open() {
      
    },
    handleCopyClose() {
      this.$emit("handleClose");
    },
    handleClose() {
      this.eventform = {
        eventLimit: "0",
        subject: "",
        content: "",
        fileType: "",
        orderId: "",
        visibleUsers: [],
        file: {
          eventFileId: "",
          file: null,
        },
      };
      this.$emit("handleClose");
    },
    userChange(val, index) {
      val.forEach((item, idx) => {
        let find = this.orderInfo.orderGroupUserList.find(
          (row) => row.userVO.businessUserId === item
        );
        let dom = document
          .querySelectorAll(".userSelect")
          [index].querySelector(".el-select__tags");
        this.$nextTick(() => {
          let parent = dom.querySelectorAll(".userTag")[idx];
          if (parent) {
            if (!parent.querySelector("img")) {
              let imageDom = document.createElement("img");
              imageDom.src =
                (find.userVO.profile && find.userVO.profile.savePath) || "";
              parent.insertBefore(imageDom, parent.children[0]);
            }
          }
        });
      });
    },
    createOrderEvent() {
      this.$refs.createEventForm.validate((vaild) => {
        if (!vaild) return;
        var data = {
          subject: this.eventform.subject,
          content: this.eventform.content.replace(/<a[^>]+>/gi, function(match) {
            return match.replace(/target="[^"]*"/gi, '');
          }),
          eventLimit: this.eventform.eventLimit,
          eventFileId: this.eventform.file.eventFileId,
          orderId: this.$route.query.orderId,
          companyTagList: this.eventform.companyTagList,
          visibleUsers: this.eventform.visibleUsers.join(","),
        };
        if (!data.subject) {
          this.$message.warning(this.$t("order.Please input title"));
        } else if (!data.content) {
          this.$message.warning(this.$t("order.Please input content"));
        } else {
          if(this.eventform.file.file) {
            this.$myEvent.emit("uploadFile", {
              apiFn: createEventIn,
              params: data,
              file: this.eventform.file.file,
              configCode: this.eventform.fileType
            });
            this.$message.info(this.$t("Creating a large file event requires waiting for the upload to complete before creating the event"));
            this.eventform = {
              eventLimit: "0",
              subject: "",
              content: "",
              fileType: "",
              orderId: "",
              visibleUsers: [],
              file: {
                eventFileId: "",
                file: null,
              },
            };
            this.file = null;
            this.fileList = [];
            this.$emit("handleClose");
            this.$emit("afterAddEvent");
          } else {
            this.buttonLoading = true;
            createEventIn(data).then((response) => {
              if (!response) return;
              this.$message.success(this.$t("order.createEvent succesfully"));
              this.eventform = {
                eventLimit: "0",
                subject: "",
                content: "",
                orderId: "",
                fileType: "",
                visibleUsers: [],
                file: {
                  eventFileId: "",
                  file: null,
                },
              };
              this.file = null;
              this.fileList = [];
              this.$emit("handleClose");
              this.$emit("afterAddEvent");
            }).finally(() => this.buttonLoading = false)
          }
        }
      });
    },
    beforeUpload(file) {
      let fileEventTypeRow = this.fileEventType.filter(item => item.configCode == this.eventform.fileType)[0];
      let type = '.'+file.name.split(".").pop();
      if(!fileEventTypeRow.supportFileTypes.split(",").includes(type.toLowerCase())) {
        this.$message.warning(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < Number(fileEventTypeRow.fileMaxSize);
      if (!isLt10M) {
        this.$message.warning(this.$tc("order.File is too large to upload", Number(fileEventTypeRow.fileMaxSize)));
      }
      if(file.size / 1024 / 1024 == 0) {
        this.$message.warning(this.$t("Do not upload empty files"));
        return false;
      }
      return isLt10M;
    },
    handleChange(file) {
      if(!this.eventform.fileType) {
        this.$message.warning(this.$t('event.Please select the file type'));
        return;
      }
      //文件数量改变
      if (!this.beforeUpload(file)) return;
      this.eventform.file.file = file.raw;
      // this.confirmUpload();
    },
    handleRemove() {
      this.eventform.file = {
        eventFileId: "",
        file: null,
      };
    },
    confirmUpload() {
      this.uploadLoding = true;
      var data_up = new FormData();
      console.log(this.eventform.file.file);
      data_up.append("files", this.eventform.file.file);
      data_up.append("configCode", this.eventform.fileType);
      uploadFile(data_up).then((response) => {
        if (!response) return;
        this.reload(["FileDetails"]);
        this.eventform.file.eventFileId = response[0];
        this.$message({
          message: this.$t("event.Upload succeeded"),
          duration: 1000,
        });
      }).finally((error) => {
        this.uploadLoding = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio {
  margin-right: 15px;
}
</style>
