<template>
  <el-drawer
    :title="$t('todo.addto-do')"
    :visible.sync="show"
    :before-close="handleDrawerClose"
    direction="rtl"
    append-to-body
    size="364px"
    v-move-outside>
    <el-form 
      :model="todoform" 
      :rules="todoRules" 
      ref="addTodoForm" 
      label-width="0"
      size="small"
      class="drawerForm">
      <el-form-item prop="content">
        <div class="title">{{ $t('todo.Title') }}</div>
        <el-input v-model="todoform.content" :placeholder="$t('order.Describe this')"></el-input>
      </el-form-item>
      <el-form-item prop="assignUsers">
        <div class="title">{{ $t('todo.Assign to') }}</div>
        <el-select 
          v-model="todoform.assignUsers" 
          multiple 
          collapse-tags
          :maxCollapseTags="2"
          :placeholder="$t('order.Type names to assign')"
          class="userSelect"
          @change="(val) => userChange(val, 0)">
          <el-option
            v-for="item in orderInfo.memberList"
            :key="item.openId"
            :label="item.nickName"
            :value="item.openId"
            class="userSelectBox"
          >
            <member
              :info="item"
              :size="24"
              :placement="'bottom'"
            />
            <div class="selectName">
              <span>{{ item.nickName }}</span>
              <span class="company">{{ item.company.aliasName }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="notifyUsers">
        <div class="title">{{ $t('todo.When done, notify') }}</div>
        <el-select 
          v-model="todoform.notifyUsers" 
          multiple 
          collapse-tags
          :maxCollapseTags="2"
          :placeholder="$t('todo.When done, notify')"
          class="userSelect"
          @change="(val) => userChange(val, 1)">
          <el-option
            v-for="item in orderInfo.memberList"
            :key="item.openId"
            :label="item.nickName"
            :value="item.openId"
            class="userSelectBox"
          >
            <member
              :info="item"
              :size="24"
              :placement="'bottom'"
            />
            <div class="selectName">
              <span>{{ item.nickName }}</span>
              <span class="company">{{ item.company.aliasName }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="ddlDate">
        <div class="title">{{ $t('todo.Due on') }}</div>
        <el-date-picker
          type="datetime"
          :placeholder="$t('order.Select a date')"
          v-model="todoform.ddlDate"
          style="width: 100%;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <div class="title">{{ $t('todo.Notes') }}</div>
        <el-input
          v-model="todoform.notes"
          :placeholder="$t('order.Add extra details')"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="drawerFooter">
      <el-button class="cancel_button" size="small" @click="handleDrawerClose">{{ $t('Forwarder.Cancel') }}</el-button>
      <el-button 
        type="primary" 
        size="small"
        @click="onSubmit"
      >{{ $t('todo.Add this to-do') }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {
  createTodoItem
} from '@/api/todo';

export default {
  name: 'addTodoDrawer',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    businessToDoListId: {
      type: Number | String,
      default: 0
    },
    orderInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      todoform: {},
      todoRules: {
        content: [
          { required: true, message: `${this.$t('home.Please enter')}${this.$t('todo.Title')}`, trigger: 'blur' },
        ],
        assignUsers: [
          { required: true, message: `${this.$t('home.Please select')}${this.$t('todo.Assign to')}`, trigger: 'blur' },
        ],
        notifyUsers: [
          { required: true, message: `${this.$t('home.Please select')}${this.$t('todo.When done, notify')}`, trigger: 'blur' },
        ],
        ddlDate: [
          { required: true, message: `${this.$t('home.Please select')}${this.$t('todo.Due on')}`, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    handleDrawerClose() {
      this.$emit("update:show", false);
    },
    userChange(val, index) {
      val.forEach((item, idx) => {
        let find = this.orderInfo.memberList.find(row => row.openId === item);
        let dom = document.querySelectorAll(".userSelect")[index].querySelector(".el-select__tags");
        this.$nextTick(() => {
          let parent = dom.querySelectorAll(".userTag")[idx];
          if(parent) {
            if(!parent.querySelector('img')) {
              let imageDom = document.createElement("img");
              imageDom.src = (find.avatarFileUrl) || '';
              parent.insertBefore(imageDom, parent.children[0]);
            }
          }
        })
      })
    },
    onSubmit() {
      this.$refs.addTodoForm.validate((valid) => {
        if(!valid) return;
        var data_todoItem = {
          assignUsers: this.todoform.assignUsers.join(","),
          content: this.todoform.content,
          ddlDate: this.todoform.ddlDate.getTime(),
          notes: this.todoform.notes,
          notifyUsers: this.todoform.notifyUsers.join(","),
          todoListId: this.businessToDoListId,
        };
        createTodoItem(data_todoItem)
          .then((response) => {
            if (!response) return;
            this.todoform = {};
            this.$emit("update:show", false);
            this.$emit("updateData");
          })
          .catch((error) => {});
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>