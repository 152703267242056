<template>
  <el-drawer
    :visible.sync="show"
    :title="$t('Online form')"
    size="1208px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
    v-move-draw
  >
    <div class="onlinePage">
      <pageTable 
        ref="multipleTable"
        :data="tableData"
        v-loading="loading_event"
        :searchParams.sync="data_event"
        @searchEvent="getList(true)"
        @clearEvent="clearFilter"
        @updateTable="getList">
        <template #searchForm>
          <chat-select
            v-model="data_event.companyTagList"
            multiple
            size="small"
            collapse-tags
            style="width: 158px;"
            :placeholder="$t('order.Event tag')"
            @change="getList(true)">
            <chat-option
              v-for="(item, index) in orderInfo.companyTagList"
              :key="index"
              :label="item.content"
              :value="item.tagId">
              <el-tag size="small" :color="item.color" class="eventTags">{{ item.content }}</el-tag>
            </chat-option>
          </chat-select>
          <el-select
            v-model="data_event.uploadPerson"
            filterable
            clearable
            appendToBody
            style="width: 200px;"
            size="small"
            :placeholder="$t('memberList.Member')"
            @change="getList(true)">
            <el-option
              v-for="(item, index) in orderInfo.memberList"
              :key="index"
              :label="item.nickName"
              :value="item.openId"
              class="userBox">
              <div class="userNameBox">
                <el-avatar :src="item.avatarFileUrl"></el-avatar>
                <div class="content">
                  <div class="name">{{ item.nickName }}</div>
                  <div class="position">{{ item.position }}</div>
                </div>
              </div>
            </el-option>
          </el-select>
          <el-input
            v-model="data_event.keyword"
            size="small"
            :placeholder="$t('event.Event Title/Content')"
            style="width: 200px;"
            @keyup.enter.native="getList(true)">
            <i
              slot="suffix"
              @click="getList(true)"
              class="el-input__icon el-icon-search"
              style="cursor: pointer;"
            ></i>
          </el-input>
          <el-date-picker
            v-model="eventTime"
            type="daterange"
            size="small"
            :start-placeholder="$t('order.Start from—End to').split('—')[0]"
            :end-placeholder="$t('order.Start from—End to').split('—')[1]"
            style="width: 250px;"
          />
        </template>
        <template #searchAction>
          <toolTipIcon 
            icon="iconfont icon-download" 
            :tooltipText="$t('ExportFile')"
            @click="saveExcel"
          />
        </template>
        <template #tableColumn>
          <el-table-column label="No." type="index" width="50" />
          <el-table-column
            ref="popover_order"
            type="index"
            width="70"
          >
            <!-- :filter-method="filterFollow" -->
            <template #header>
              <div class="star">
                <i
                  class="iconfont"
                  :class="
                    data_event.collect ? 'icon-yishoucang' : 'icon-shoucang'
                  "
                  @click.stop="followChange"
                ></i>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="star">
                <i
                  class="iconfont"
                  :class="
                    scope.row.collect ? 'icon-yishoucang' : 'icon-shoucang'
                  "
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="subject"
            :label="$t('eventExport.eventName')"
            min-width="190"
          >
            <template slot-scope="{row}">
              <p class="line2">{{ isSystemFuntion(row) }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            :label="$t('eventExport.eventContent')"
            min-width="190"
          >
            <template slot-scope="{row}">
              <div class="contentText">
                <p class="line2" v-if="!row.keyEvent" v-html="row.content" @click="$replayImgShow($event)"></p>
                <div v-else>
                  {{ `${row.contentJson.type != 4 ? (row.createPerson && row.createPerson.nickName) : $t('memberList.manage')}${behaviorComputed(row)}${titleTypeComputed(row)}` }}<br/>
                  <template v-if="row.contentJson.type == 3">
                    <div class="text" v-if="computedNodeList(row.contentJson.oldProgress)">
                      {{ $t('order.orderStatusForm') }}
                      {{ computedNodeList(row.contentJson.oldProgress).name }}
                      {{ $t('order.change to') }}
                      <span>{{ computedNodeList(row.contentJson.newProgress).name }}</span>
                    </div>
                  </template>
                  <template v-if="row.contentJson.type == 1">
                    <div class="itemTwo" v-for="(row, index) in row.contentJson.data.filter(item => item.change)" :key="index">
                      <span>{{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}</span>
                      {{ $t('order.form') }}
                      {{ fieldValueComputed(row) }}
                      {{ $t('order.change to') }}
                      <span>{{ fieldValue1Computed(row) }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('InformationCenter.Createby')" min-width="160">
            <template slot-scope="scope">
              <div class="customerName">
                <member :info="scope.row.createPerson" :size="28"></member>
                <span>{{ scope.row.createPerson && scope.row.createPerson.nickName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('eventExport.eventTags')" min-width="240">
            <template slot-scope="{row}">
              <div class="tagEventBox">
                <el-popover 
                  v-for="(item, index) in row.companyTagList" 
                  :key="index"
                  placement="top-start"
                  trigger="hover"
                  :open-delay="700"
                  popper-class="eventTagUserPopover"
                  :disabled="!item.openUserDTO">
                  <div class="userTagBox" v-if="item.openUserDTO">
                    <el-image :src="item.openUserDTO.avatarFileUrl || item.openUserDTO.avatarUrl" />
                    <div class="content">
                      <div class="title">
                        {{ $t('order.form') }}
                        <span>{{ item.openUserDTO.nickName }}</span>
                        {{ $t('home.Create') }}
                      </div>
                      <div class="time">{{ item.createTime }}</div>
                    </div>
                  </div>
                  <el-tag 
                    slot="reference"
                    size="small" 
                    :color="item.color" 
                    class="eventTags"
                  >{{ item.content }}</el-tag>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('order.Create Time')"
            min-width="190"
          >
          </el-table-column>
          <el-table-column
            prop="eventFileUrl"
            :label="$t('eventExport.eventFile')"
            min-width="190"
          >
            <template slot-scope="{row}">
              <fileBlocks
                v-if="row.eventFileUrl"
                type="message"
                :embedUrl="row.eventFileUrl"
                :embedId="row.eventFileId"
                :ci="orderInfo && orderInfo.ci"
                background="#ffffff"
              />
            </template>
          </el-table-column>
        </template>
      </pageTable>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getEventPageList,
} from "@/api/event";
export default {
  props: {
    orderInfo: {
      type: Object,
      default: null
    },
    shippingInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      loading_event: false,
      filteredFollow: [],
      data_event: {
        uploadPerson: "",
        keyword: "",
        collect: 0,
        companyTagList: [],
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      },
      tableData: [],
      eventTime: []
    }
  },
  watch: {
    $route: {
      handler() {
        if(this.$route.path != '/order/orderDetails') {
          this.show = false;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['allNodeList', 'country']),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    fieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue1 || row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue1 || row.fieldValue;
        }
      }
    },
    fieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue;
        }
      }
    },
  },
  methods: {
    open() {
      this.show = true;
      this.getList();
    },
    handleClose() {
      this.show = false;
    },
    saveExcel() {
      setTimeout(() => {
        let header = [
          this.$t('eventExport.eventType'),
          this.$t('eventExport.eventName'),
          this.$t('eventExport.eventContent'),
          this.$t('eventExport.eventCreatePerson'),
          this.$t('eventExport.eventCreateTime'),
          this.$t('eventExport.eventTags'),
          this.$t('eventExport.eventFile'),
        ];
        var filterVal = [
          "eventType",
          "subject",
          "content",
          "createPersonName",
          "createTime",
          "companyTagList",
          "eventFileUrl"
        ];
        var filename = this.$t('home.Event');
        console.log(this.tableData);
        var data = this.$formatJson(filterVal, this.tableData.map(item => ({
          ...item,
          eventType: !item.keyEvent ? this.$t('eventExport.Ordinary event') : this.$t('eventExport.System event'),
          subject: this.isSystemFuntion(item),
          companyTagList: item.companyTagList && item.companyTagList.map(row => row.content),
          content: !item.keyEvent ? item.content.replace(/<[^>]+>/gi, '') : `${item.createPerson?.nickName}${this.behaviorComputed(item)}${this.titleTypeComputed(item)}`,
          createPersonName: item.createPerson.nickName
        })));
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      }, 100)
    },
    clearFilter() {
      this.data_event = {
        uploadPerson: "",
        keyword: "",
        collect: 0,
        companyTagList: [],
        currentPage: 1,
        pageSize: 10
      }
      this.getList(true);
    },
    followChange() {
      this.data_event.collect = this.data_event.collect == 0 ? 1 : 0;
      this.getList(true);
    },
    getList(clear = false) {
      if(clear) {
        this.data_event.currentPage = 1;
      }
      //初始化获取事件列表
      let startTime = "";
      let endTime = "";
      if(this.eventTime && this.eventTime.length) {
        startTime = this.$dayjs(this.eventTime[0]).format("YYYY-MM-DD HH:mm:ss");
        endTime = this.$dayjs(this.eventTime[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      }
      this.loading_event = true;
      let obj = JSON.parse(JSON.stringify(this.data_event));
      delete obj.totalElement;
      getEventPageList({
        ...obj,
        startTime,
        endTime,
        orderId: this.$route.query.orderId
      }).then((response) => {
        if(clear) this.tableData = [];
        this.tableData = response.list;
        this.data_event.totalElement = response.total;
      }).finally(() => this.loading_event = false);
    },
    behaviorComputed(row) {
      let content = row.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed(row) {
      let content = row.contentJson;
      console.log(row);
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.$isOrderTitle());
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.$isOrderTitle());
        } else {
          return this.$tc("order.Order node", this.$isOrderTitle());
        }
      }
    },
    isSystemFuntion(row) {
      let content = row.contentJson;
      if(content) {
        if(content.type == 1) return `${this.$isOrderTitle()}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 2) return `${this.$t("home.waybill")}：${this.shippingInfo.bl}`
        if(content.type == 3) return `${this.$t("home.clock in")}：${this.orderInfo.ci || this.orderInfo.pi}`
      } else {
        return row.subject;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.onlinePage {
  padding: 20px;
  height: 100%;
}
.contentText {
  /deep/ img {
    width: 80px;
  }
}
.tagEventBox {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.userTagBox {
  display: flex;
  align-items: center;
  gap: 10px;
  .el-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      color: #637381;
      span {
        color: #076F49;
      }
    }
    .time {
      font-size: 12px;
      color: #757D8A;
    }
  }
}
</style>