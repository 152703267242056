<template>
  <div v-if="form">
    <div class="title">{{ form.customName || $t('xiaoxie.Title') }}</div>
    <el-rate
      v-model="inputValue"
      :disabled="disabled || readonly"
      :colors="colors"
      allow-half>
    </el-rate>
  </div>
</template>

<script>
export default {
  name: "inputText",
  props: {
    value: {
      type: String | Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({
        customName: "",
        customTips: ""
      })
    }
  },
  data() {
    return {
      inputValue: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900']
    }
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    }
  },
  mounted() {
    this.inputValue = Number(this.value);
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-rate__icon {
  font-size: 25px;
}
</style>